/* Common CSS Start here */

.aboutuswrap {
    padding: 50px 0px;
}

.App {
    background-color: #f0f3f6 !important;
    height: auto;
    overflow-x: hidden;
}

.explorewrap>img {
    position: absolute;
    right: 5px;
    top: 0px;
    width: 368px;
    height: 368px;
    z-index: 0;
}

.aboutuswrap {
    background: #FFF !important;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    margin: 20px 60px;
}

.aboutsimg-div .imageswrap .leftimgtr {
    max-width: auto;
}

.aboutsimg-div .imageswrap .imgprod2 {
    float: none;
}

.aboutsimg-div .imageswrap .rightimgtr {
    max-width: auto;
    padding-left: 0;
}

.aboutsimg-div .imageswrap .leftrightwrap {
    margin-left: 40px;
}

.aboutsimg-div .imageswrap .leftimgtr {
    max-width: 250px;
}


/* Common CSS End here */


/* Changes CSS */

.explorewrap .left-Aboutimg>img {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
    width: 150px;
}

.aboutuswrap .container {
    padding: 0px 80px !important;
}

.aboutuswrap .about-content {
    padding: 20px 0px 0px 0px !important;
}

.explorecont.about-content p {
    font-size: 17px;
    color: #000;
    margin-bottom: 0;
    line-height: 28px;
    text-align: justify;
}

.explorecont.about-content p a {
    font-size: 19px;
    font-weight: 500;
    color: #1e3e7b;
    text-decoration: underline!important;
}


/* Chnages CSS*/


/* Assets Link, About, PP and T&C  Screen CSS Start Here */

.explorewrap.aboutuswrap .explorecont {
    padding: 40px 0 100px 0;
}

.explorewrap.assetslinkwrap ul {
    padding-top: 10px;
}

.explorewrap.assetslinkwrap ul li {
    list-style-type: none;
    padding-bottom: 50px;
    margin: 50px 0px 50px 0px;
    border: 0;
    height: auto;
    border-bottom: 1px solid #00000014;
}

.explorewrap.assetslinkwrap ul li:last-child {
    border-bottom: 0;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap {
    box-shadow: none;
    position: relative;
    padding-left: 400px;
    background-color: transparent;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap .productimgcard {
    position: absolute;
    left: 0;
    top: 0;
    width: 332px;
    height: 291px;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    display: grid;
    place-items: center;
    background-color: #fff;
    overflow: hidden;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap .productimgcard img {
    object-fit: cover;
}

.explorewrap.assetslinkwrap ul li .assetscardwrap h2,
.explorewrap.assetslinkwrap ul li .assetscardwrap p,
.explorewrap.assetslinkwrap ul li .assetscardwrap span {
    text-align: left;
}

.explorewrap.assetslinkwrap ul li .assetscardwrap .common_btn button {
    width: 225px;
    height: 56px;
}

.explorewrap.termscondition {
    padding-bottom: 50px;
}

.explorewrap.termscondition .explorecont {
    padding: 15px 0 0px 0;
}

.explorewrap.termscondition .explorecont p {
    padding-bottom: 20px;
}

.explorewrap.termscondition .explorecont p img {
    position: relative;
    margin: 0 2px;
}

.explorewrap.termscondition .more_btn a span svg {
    width: 72px;
    height: 46px;
    margin-top: 20px;
}


/* Assets Link, About, PP and T&C  Screen CSS End Here */

@media (max-width:1440px) {
    .explorecont.about-content p a {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1199px) {
    .explorecont.about-content p a {
        font-size: 16px;
    }
    .aboutsimg-div img {
        width: 400px !important;
    }
    .explorewrap .left-Aboutimg>img {
        width: 120px;
    }
}

@media only screen and (max-width: 991px) {
    .explorecont.about-content p a {
        font-size: 15px;
    }
    .explorewrap .left-Aboutimg>img {
        width: 110px;
    }
    .explorewrap.aboutuswrap .explorecont {
        padding: 40px 0 50px 0;
    }
    .imageswrap .imgprod2 {
        width: auto;
        height: auto;
        float: none;
    }
    .imageswrap .leftrightwrap {
        float: none;
    }
    .imageswrap .leftimgtr {
        max-width: initial;
    }
    .imageswrap .rightimgtr {
        max-width: 100%;
        padding-left: 15px;
    }
    .imageswrap .imgprod3 {
        float: none;
        margin: 0 auto;
        height: auto;
    }
    .imageswrap .imgprod1 {
        margin: 0 auto;
        float: none;
    }
    .more_btn a span svg {
        width: 30px;
        height: 30px;
    }
    .more_btn a span {
        top: 8px;
    }
    .more_btn a {
        font-size: 16px;
        line-height: 4px;
    }
    .explorewrap.assetslinkswpap {
        margin-top: 40px;
        padding-top: 30px;
    }
    .imageswrap {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .aboutsimg-div {
        margin-bottom: 50px;
    }
    .aboutuswrap .container {
        padding: 0px 30px !important;
    }
}

@media only screen and (max-width: 767px) {
    .aboutuswrap .container {
        padding: 0px 15px !important;
    }
    .explorewrap.aboutuswrap .explorecont {
        padding: 0px 0 10px 0;
    }
    .explorecont h2 {
        font-size: 28px;
        line-height: 48px;
    }
    .explorecont p {
        font-size: 14px;
        line-height: 26px;
    }
    .explorewrap.assetslinkwrap ul li .assetscardwrap .common_btn button {
        width: 100%;
        height: 50px;
    }
    .aboutuswrap {
        margin: 90px 15px;
        padding: 20px 0px;
    }
    .explorewrap .left-Aboutimg>img {
        width: 100px;
    }
    .explorecont.about-content p {
        line-height: 25px;
    }
    .explorecont.about-content p a {
        font-size: 14px;
    }
}