/* Header CSS Start Here */

.headerwap {
    position: relative;
}

.headerwap .navbar-expand-lg {
    background-color: #fff !important;
    z-index: 999;
    border-bottom: 1px solid gainsboro;
    padding: 0 60px;
    height: 72px;
    max-width: 1920px;
    margin: 0 auto;
}

.headerwap .navbar-expand-lg .navbar-brand {
    color: #fff;
    font-size: 20px;
}

.headerwap .navbar-expand-lg .navbar-brand img {
    width: 140px;
}

.userdetails.show .fa svg {
    transform: rotate(-180deg);
}

.headerwap .navbar-toggler-icon {
    color: #fff;
    font-size: 27px;
    position: absolute;
    left: 250px;
    top: 11px;
    cursor: pointer;
    display: none;
}

.headerwap .collapse.show {
    display: none !important;
    opacity: 0;
}

.headerwap .navbar-expand-lg .navbar-nav {
    justify-content: flex-end;
    width: 100%;
}

.navbar-nav.navmenuwrap .nav-item {
    position: relative;
    padding: 20px 18px 10px 18px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif!important;
}

.navbar-nav.navmenuwrap .nav-item.userdetails.log_out_popup {
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 0 !important;
}

.navbar-nav.navmenuwrap .nav-item .nav-link {
    position: relative;
    font-size: 17px;
    padding: 0;
    color: #565648;
    font-weight: 300;
}

.navbar-nav.navmenuwrap .nav-item:hover .nav-link,
.navbar-nav.navmenuwrap .nav-item.active .nav-link {
    color: #1c3e7d;
    font-weight: 300;
}

.navbar-nav.navmenuwrap .nav-item .notificationdot {
    position: absolute;
    left: 10px;
    top: 20px;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #1bc167;
}

.log_out_popup .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 72px;
    left: 0;
    opacity: 1;
    background-color: rgb(0 0 0 / 25%);
    overflow-x: hidden;
    transition: 0.5s;
    display: none;
}

.show .overlay {
    display: block;
}

.log_out_popup .dropdown-menu {
    position: absolute;
    border-radius: 0px 0px 4px 4px;
    border: 0;
    padding: 0;
    overflow: hidden;
    top: 65px !important;
    left: auto !important;
    right: -30px !important;
    box-shadow: 0px 2px 6px #0000000a;
    background: #fff;
    min-width: 200px;
    border-radius: 0px 0px 10px 10px;
    padding: 0px 0px;
}

.log_out_popup .dropdown-toggle::after {
    display: none !important;
}

.log_out_popup .dropdown-menu .dropdown-item {
    position: relative;
    padding: 12px 20px;
    /* padding-left: 0px; */
    font-weight: 600;
    color: #1E3E7B;
    font-size: 14px;
}

.log_out_popup .dropdown-menu .dropdown-item:focus {
    background-color: #bfbfbf;
}

.log_out_popup .dropdown-menu .dropdown-item:hover a {
    color: #fff;
}

.log_out_popup .dropdown-menu .dropdown-item svg {
    position: absolute;
    top: 12px;
    left: 24px;
    font-size: 20px;
    color: #fff;
}

.log_out_popup .dropdown-menu .dropdown-item img {
    border: 0px!important;
    width: 22px!important;
    height: 22px!important;
    border-radius: 0px!important;
    margin-right: 20px!important;
}

.log_out_popup .dropdown-menu .dropdown-item:hover {
    color: #3a5082;
}

.log_out_popup .dropdown-menu .dropdown-item:hover svg path {
    fill: #3a5082;
}

.navbar-nav.navmenuwrap .nav-item.userdetails .nav-link {
    position: relative;
    padding-right: 0px;
}


/* .navbar-nav.navmenuwrap .nav-item.userdetails .nav-link{
    height: 40px;
  } */

.navbar-nav.navmenuwrap .nav-item.userdetails a img {
    position: relative;
    top: 0px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    box-shadow: 0px 9.03012px 27.0904px rgb(191 203 203 / 32%), 0px 3.38629px 5.64383px rgb(223 226 227 / 32%);
    border: 2px dashed #d5a002;
    object-fit: contain;
    padding: 4px;
}

.navbar-nav.navmenuwrap .dropdown-menu a img {
    box-shadow: 0px 0px 0px 0px!important;
}

.navbar-nav.navmenuwrap .nav-item.userdetails a p {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: inherit;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: #000;
    top: 5px;
    font-weight: 500;
}

.navbar-nav.navmenuwrap .nav-item.userdetails a i {
    display: inline;
    position: relative;
    right: 0px;
    top: 0px;
    margin-left: 10px;
    background-color: transparent;
    color: #000;
    font-size: 13px;
}

.navbar-nav.navmenuwrap .nav-item a .fa-bell {
    font-size: 26px;
    color: #3a5082;
}

.inner-page-list {
    margin-top: 72px;
    padding: 15px 80px;
    background: #FFF;
}

.inner-page-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inner-page-list ul li:first-child {
    justify-content: start;
}

.inner-page-list ul li {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-page-list ul li.active a:after {
    opacity: 1;
}

.inner-page-list ul li.active a {
    opacity: 1;
}

.inner-page-list ul li a:after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -15px;
    width: 100%;
    height: 3px;
    background-color: #1c3e7d;
    opacity: 0;
}

.inner-page-list ul li a {
    position: relative;
    font-size: 15px;
    color: #1E3E7B;
    font-weight: 700;
    opacity: 0.5;
}

.inner-page-list ul li a svg {
    margin-right: 15px;
    height: 30px;
}

.inner-page-list ul li.active a svg g {
    opacity: 1;
}


/* Header CSS End Here */


/* Mobile Menu CSS Start Here */

.menuBtn {
    display: none;
}

.mobilewrapnv {
    display: none;
}

.mobile {
    margin-top: 100px;
}

header {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

header .logo {
    position: absolute;
    left: 15px;
    top: 18px;
    z-index: 9999;
    display: none;
}

header .logo img {
    width: 110px;
}

.menuBtn {
    height: 30px;
    width: 25px;
    position: absolute;
    right: 15px;
    top: 16px;
    z-index: 101;
}

.menuBtn>span {
    background-color: #000;
    border-radius: 1px;
    height: 2px;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -8px;
}

.menuBtn>span:after,
.menuBtn>span:before {
    content: "";
    background-color: #000;
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 50%;
    margin-left: -10px;
}

.menuBtn>span:after {
    top: -7px;
}

.menuBtn>span:before {
    bottom: -7px;
}

.menuBtn.act>span {
    height: 0;
}

.menuBtn.act>span:after,
.menuBtn.act>span:before {
    background-color: #000;
    top: 1px;
}

.menuBtn.act>span:after {
    transform: rotate(45deg);
}

.menuBtn.act>span:before {
    transform: rotate(-45deg);
}

.mainMenu {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 100vh;
    width: 100%;
    display: table;
    text-align: center;
    opacity: 0;
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(0);
    padding-top: 0px;
}

.mainMenu.act {
    opacity: 1;
    transform: scale(1);
}

.mainMenu.act ul li {
    opacity: 1;
    transform: translateX(0);
}

.mainMenu.act ul li a.active {
    font-weight: normal;
    color: #000 !important;
    font-family: inherit;
}

.mainMenu ul {
    margin-top: 70px;
}

.mainMenu li {
    padding: 8px 0;
    transition: all 400ms 510ms;
    opacity: 0;
}

.mainMenu li:nth-child(odd) {
    transform: translateX(30%);
}

.mainMenu li:nth-child(even) {
    transform: translateX(-30%);
}

.mainMenu li:last-child {
    transform: none;
}

.mainMenu a {
    color: #000;
    display: inline-block;
    font-size: 16px;
    padding: 0;
}

.modal-backdrop {
    z-index: -1;
}

.fade.show {
    opacity: 1 !important;
}

.notifymob {
    display: none;
}

.navbar-nav.navmenuwrap .nav-item.svgicon {
    margin-top: -9px;
}

.navbar-nav.navmenuwrap .nav-item.svgicon svg:hover {
    fill: #1c3e7d !important;
}

.navbar-nav.navmenuwrap .nav-item.svgicon a.active svg {
    fill: #1c3e7d !important;
}


/* Mobile Menu CSS End Here */


/* Header or Footer Media Start */

@media only screen and (max-width: 1550px) {
    .navbar-nav.navmenuwrap .nav-item {
        padding: 22px 18px 10px 18px;
    }
    .headerwap .navbar-expand-lg {
        padding: 0px 90px;
    }
    .inner-page-list ul li a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1440px) {
    .navbar-nav.navmenuwrap .nav-item .nav-link {
        font-size: 16px;
    }
    .navbar-nav.navmenuwrap .nav-item {
        padding: 22px 10px 10px 8px;
    }
    .navbar-nav.navmenuwrap .nav-item.svgicon {
        margin-top: -9px;
    }
    .blink_me {
        top: 10px !important;
    }
    .inner-page-list {
        padding: 15px 60px;
    }
}

@media only screen and (max-width: 1320px) {
    .inner-page-list ul li a {
        font-size: 12px;
    }
    .inner-page-list ul li a svg {
        margin-right: 8px;
        height: 24px;
    }
    .inner-page-list {
        padding: 15px 20px;
    }
    .headerwap .navbar-expand-lg {
        height: 70px;
    }
    .log_out_popup .overlay {
        top: 70px;
    }
    .navbar-nav.navmenuwrap .nav-item {
        padding: 22px 10px 10px 10px;
    }
    .navbar-nav.navmenuwrap .nav-item .nav-link {
        font-size: 14px;
    }
    .nav-item a {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1220px) {
    .navbar-nav.navmenuwrap .nav-item.userdetails a img {
        width: 35px;
        height: 35px;
    }
    .navbar-nav.navmenuwrap .nav-item {
        padding: 22px 5px 10px 5px;
    }
    .blink_me {
        top: 10px;
    }
    .inner-page-list ul li a {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1023px) {
    .inner-page-list ul li a svg {
        margin-right: 4px;
    }
}

@media only screen and (max-width: 991px) {
    .inner-page-list {
        display: none;
    }
    .inner-page-list ul li a {
        font-size: 13px;
    }
    .inner-page-list {
        padding: 15px 15px;
    }
    .star-user {
        left: 13px;
        bottom: -10px;
    }
    .bell-count.cart {
        left: 20px!important;
        top: 0px!important;
        right: auto;
        z-index: 1;
    }
    .menuBtn {
        display: block;
    }
    .notifymob {
        position: absolute;
        left: -60px;
        display: inline-block !important;
        top: 10px;
    }
    .notifymob a svg {
        width: 35px;
    }
    .notifymob a {
        margin: 0px 5px 0px 0px;
    }
    .notifymob a .fa-bell {
        width: 21px;
    }
    .notifymob .bell-count {
        position: absolute;
        right: -3px;
        top: -2px;
        font-size: 9px;
        height: 15px;
        width: 15px;
        line-height: 15px;
    }
    .navbar-nav.navmenuwrap .nav-item {
        display: none;
    }
    .mobile {
        margin-top: 0px;
    }
    header .logo {
        display: block;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails.log_out_popup {
        display: block;
        position: absolute;
        right: 50px;
    }
    .mobilewrapnv {
        display: block;
    }
    .headerwap .navbar-toggler-icon {
        color: #000;
        font-size: 27px;
        position: absolute;
        right: 0px;
        left: auto;
        top: 13px;
        display: block;
    }
    .headerwap .navbar-expand-lg .navbar-brand img {
        width: 85px;
    }
    .log_out_popup .overlay {
        top: 62px;
    }
    .log_out_popup .dropdown-menu {
        right: -35px !important;
        top: 62px !important;
    }
    .footerwrap .container {
        display: block;
    }
    .footerwrap .conactus span {
        line-height: 20px;
        margin-right: 8px;
    }
    .conactus.followswrap p {
        margin-left: 0px;
        text-align: center;
        margin-top: 15px;
    }
    .conactus {
        text-align: center;
    }
    .conactus p {
        text-align: center;
    }
    .conactus.followswrap ul {
        margin: 0 auto;
        display: table;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails a p {
        display: none;
        font-size: 14px;
        max-width: 71px;
        padding-top: 0px;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails a i {
        margin-left: 0px;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails a i {
        top: 2px;
        right: 5px;
    }
    .headerwap .navbar-expand-lg {
        height: 62px;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails.log_out_popup {
        right: 40px;
        top: 0px;
    }
    .log_out_popup .dropdown-menu .dropdown-item {
        font-size: 14px;
    }
    .log_out_popup .dropdown-menu .dropdown-item svg {
        top: 8px;
        left: 26px;
        width: 18px;
    }
    .bannersection {
        padding: 20px 16px 20px 16px !important;
        min-height: 200px;
    }
    .navbar-nav.navmenuwrap .nav-item.userdetails.log_out_popup {
        padding-left: 20px;
    }
}

@media (max-width:767px) {
    .bell-count.cart {
        left: 20px!important;
        top: 0px!important;
        right: auto;
        z-index: 1;
    }
}


/* Header or Footer Media End */

.nav-item a {
    color: #565648;
}

.nav-item a .svg-inline--fa.fa-w-18 {
    transform: scale(1.3);
}

.nav-item a .svg-inline--fa.fa-w-18 path {
    fill: #3a5082;
    ;
}

.active {
    color: #1c3e7d !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
}

.bell-count {
    position: absolute;
    right: 14px;
    top: 14px;
    content: attr(data-count);
    padding: 0;
    border-radius: 2em;
    line-height: 0.75em;
    color: white;
    background: rgb(255, 0, 0);
    text-align: center;
    min-width: auto;
    font-weight: bold;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    z-index: 2;
}

.bell-count.cart {
    right: 10px;
    top: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    background: #3a5082;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-block;
    font-size: 13px;
    text-align: center;
    position: absolute;
    right: 2px;
    top: 15px;
}

@keyframes blinker {
    49% {
        color: yellow;
    }
    50% {
        color: yellow;
    }
    99% {
        color: yellow;
    }
    100% {
        color: #000;
    }
}