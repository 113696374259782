@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1700px) {}

@media only screen and (max-width: 1600px) {
    .logincontent .logincontcard h2 {
        font-size: 44px;
        margin-bottom: 5px;
    }
    .logincontent .logincontcard p {
        line-height: 36px;
    }
    .footer-link ul li a,
    .footer-link .address-div span,
    .footer-link .mailtext {
        font-size: 12px;
    }
    .logincontent p {
        font-size: 21px;
    }
    .logincard span {
        font-size: 18px;
    }
    .common_input_warp .form-group .form-control {
        height: 50px;
    }
    .common_btn button {
        height: 50px;
    }
    .logoutpopupwrap h2 {
        font-size: 26px !important;
    }
    .logoutpopupwrap p {
        font-size: 18px;
    }
    .footerwrap .container {
        padding: 0px 160px!important;
    }
    .logincontent .logincontcard {
        padding: 0 50px 0 0px;
    }
}

@media only screen and (max-width: 1550px) {
    .container {
        padding: 0px 90px!important;
    }
    .headerwap .navbar-expand-lg {
        padding: 0px 90px;
    }
    .logincard {
        max-width: 500px;
    }
    .common_input_warp .form-group .form-control {
        height: 55px;
    }
}

@media only screen and (max-width: 1440px) {
    .emptycart-wrap p {
        font-size: 16px;
    }
    .common_input_warp .form-group i {
        font-size: 16px;
    }
    .footerwrap .container {
        padding: 0px 100px!important;
    }
    .logincontent .logincontcard {
        padding: 0 25px 0 0px;
    }
    .logincontent .logincontcard h2 {
        font-size: 40px;
        margin-bottom: 5px;
    }
    .logincontent .logincontcard p {
        line-height: 30px;
        font-size: 18px;
    }
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 80px !important;
        max-width: 100% !important;
    }
    .logoutpopupwrap .like_img img {
        width: 55px;
    }
    .logoutpopupwrap .like_img {
        margin: 0 auto;
        width: auto;
        text-align: center;
    }
    .logoutpopupwrap h2 {
        font-size: 22px !important;
        line-height: 35px;
    }
    .logoutpopupwrap p {
        font-size: 16px;
    }
    .logoutpopupwrap .common_btn button {
        height: 45px;
        margin: 10px 5px 0;
        max-width: 150px;
        width: 150px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 1366px) {
    .footerwrap .container {
        padding: 0px 80px!important;
    }
}

@media only screen and (max-width: 1320px) {
    .customimputcheckbox .form-group {
        margin-bottom: 15px!important;
    }
    .logincard {
        max-width: 450px;
    }
    .logincard span {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 5px
    }
    .common_input_warp .form-group .form-control {
        height: 45px;
    }
    .customimputcheckbox .form-group label {
        margin-bottom: 0px;
        line-height: 29px;
    }
    .logincard .common_btn button {
        margin: 10px 0px 20px 0px;
        font-size: 15px;
        padding: 20px 40px;
    }
}

@media only screen and (max-width: 1220px) {
    .footerwrap .container {
        padding: 0px 60px!important;
    }
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 50px !important;
    }
    .navbar-nav.navmenuwrap .nav-item .nav-link {
        padding: 44px 6px 0 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 80px;
    }
    .logincontent .logincontcard h2 {
        font-size: 32px;
        margin-bottom: 0;
    }
    .logincontent p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1170px) {}

@media only screen and (max-width: 1080px) {
    .footerwrap .container {
        padding: 0px 50px!important;
    }
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 30px !important;
    }
    .logincard img {
        width: 120px;
    }
    .logincard {
        max-width: 420px;
        padding: 30px 30px;
    }
    .headerwap .navbar-expand-lg,
    .container {
        padding: 10px 15px !important;
    }
    .customimputcheckbox .form-group label:before {
        margin-right: 10px;
    }
    .logincontent .logincontcard {
        padding: 0 25px 0 50px;
        max-width: 100%;
    }
    .logincontent .logincontcard h2 {
        font-size: 28px;
        margin-bottom: 0;
    }
    .logincontent .logincontcard p {
        line-height: 25px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 1023px) {}

@media only screen and (max-width: 991px) {
    .emptycart-wrap p {
        font-size: 15px;
    }
    .footerwrap .container {
        padding: 0px 25px!important;
    }
    .footerwrap {
        padding-top: 30px;
    }
    .logincard {
        max-width: 360px;
        padding: 30px 10px 20px 0px;
    }
    .headerwap .navbar-expand-lg,
    .container {
        padding: 0 15px;
    }
    .explorewrap>img {
        top: 4px;
        width: 200px !important;
        height: 200px !important;
    }
    .customimputcheckbox .form-group label:before {
        margin-right: 7px;
    }
    .customimputcheckbox .form-group label {
        margin-bottom: 0px;
        line-height: 24px;
        font-size: 13px;
    }
}

@media only screen and (max-width: 840px) {}

@media only screen and (max-width: 767px) {
    .customimputcheckbox .form-group input:checked+label:after {
        top: 4px!important;
    }
    .services-pg-wrap .tabledatawrap table tbody tr td .action-text a {
        font-size: 13px;
    }
    .serialnumberpop-wrap select {
        height: 40px;
        margin-bottom: 20px;
    }
    .serialnumberpop-wrap .common_btn {
        display: flex;
        justify-content: center;
    }
    .serialnumberpop-wrap .common_btn button {
        margin: 0px 10px!important;
        height: 40px;
        min-height: 40px;
        font-size: 14px;
        width: auto!important;
    }
    .serialnumberpop-wrap {
        max-width: 100%;
        margin: auto;
    }
    .explorewrap.services-pg-wrap.termscondition {
        min-height: 200px!important;
    }
    #svg-section {
        margin: 90px 0px 15px 0px;
    }
    .bannersection {
        display: none;
    }
    .footerwrap .container {
        padding: 0px 30px!important;
    }
    .footer-linkwrap [class^="col-"] {
        width: 50%;
    }
    .footer-link ul li {
        margin-bottom: 0;
    }
    .logincontent {
        display: none;
    }
    .logincardwrap {
        padding: 0 15px;
    }
    .logincard img {
        width: 110px;
    }
    .common_btn button {
        margin-bottom: 15px;
    }
    .sm-none {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .footer-link.footer-menu {
        margin-bottom: 30px;
    }
    .footer-menu ul li {
        margin-bottom: 0;
    }
    .footerwrap {
        padding: 30px 0 20px 0px;
    }
    .footer-linkwrap [class^="col-"] {
        width: 100%;
        text-align: center;
    }
    .footer-link .address-div {
        margin-bottom: 30px;
    }
    .footer-link .address-div .footcountryimg {
        justify-content: center;
        font-size: 14px;
    }
    .footer-link .address-div img {
        /* margin-left: auto;
        margin-right: auto; */
    }
    .empty-p {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .footerwrap .container {
        padding: 0px 15px!important;
    }
    .logoutpopupwrap .common_btn button {
        max-width: 120px;
        width: 120px;
    }
}