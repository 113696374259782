@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
html {
    height: 100%;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    /* font-family: "FiraSans-Regular" !important; */
    font-family: 'Montserrat', sans-serif !important;
    background: #fff;
    max-width: 1920px !important;
    margin: 0 auto !important;
}

body.modal-open {
    overflow: hidden;
    overflow-y: auto;
}

.scroll-overflow {
    overflow: auto;
}


/* @font-face {
    font-family: "FiraSans-Regular";
    src: url("../fonts/FiraSans-Regular.woff2") format("woff2"), url("../fonts/FiraSans-Regular.woff") format("woff"), url("../fonts/FiraSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FiraSans-Medium";
    src: url("../fonts/FiraSans-Medium.woff2") format("woff2"), url("../fonts/FiraSans-Medium.woff") format("woff"), url("../fonts/FiraSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FiraSans-SemiBold";
    src: url("../fonts/FiraSans-SemiBold.woff2") format("woff2"), url("../fonts/FiraSans-SemiBold.woff") format("woff"), url("../fonts/FiraSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
   font-weight: 500;
    src: url("../fonts/FiraSans-Bold.woff2") format("woff2"), url("../fonts/FiraSans-Bold.woff") format("woff"), url("../fonts/FiraSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FiraSans-Italic";
    src: url("../fonts/FiraSans-Italic.woff2") format("woff2"), url("../fonts/FiraSans-Italic.woff") format("woff"), url("../fonts/FiraSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
} */

#wrapper {
    position: relative;
}


/* Cmmon  CSS Start Here */

body>iframe {
    display: none;
}

.clearfix {
    content: "";
    clear: both;
    display: table;
}

.common_btn button {
    color: #fff;
    font-size: 16px;
    line-height: 10px;
    width: auto;
    padding: 15px 10px;
    border: 0;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #1c3e7d;
    border: 1px solid #1c3e7d;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.common_btn a {
    color: inherit;
    text-decoration: none;
}

.common_btn button:hover {
    background-color: transparent;
    color: #1c3e7d;
    border: 1px solid #1c3e7d;
}

button:focus,
input:focus,
.common_input_warp .form-group select.classic:focus,
textarea:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

a {
    text-decoration: none !important;
}

a:focus {
    outline: transparent;
}

a:hover {
    text-decoration: none;
}

.no_padding {
    padding: 0;
}

.dflex {
    display: flex;
}

.dflex_bet {
    justify-content: space-between;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    font-size: 20px;
}

label,
p {
    font-size: 18px;
    margin: 0;
    line-height: 34px;
    font-weight: 300;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.common_input_warp .form-group {
    position: relative;
    margin-bottom: 15px;
}

.common_input_warp .form-group i {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 18px;
    cursor: pointer;
    transform: translateY(-50%);
}

.common_input_warp .form-group .form-control {
    font-size: 14px;
    color: #808080;
    height: 56px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    width: 100%;
}

.common_input_warp .form-group label {
    color: #000;
    margin-bottom: 5px;
    font-size: 16px;
    /* font-family: "FiraSans-Medium"; */
}

.common_input_warp .form-group textarea.form-control {
    font-size: 14px;
    padding: 14px;
    color: #808080;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    resize: none;
    height: 148px;
}

.common_input_warp .form-group select.classic {
    font-size: 14px;
    padding: 14px;
    color: #808080;
    margin: 0;
    height: 56px;
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /* background-image: url("images/down_arrow.png"); */
    background-position: calc(100% - 12px) calc(1em + 0px), calc(100% - 24px) calc(1em + 2px), 100% 0;
    background-size: 24px 24px, 24px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    background-color: #fff;
}

.common_input_warp .form-group .form-control:focus,
.common_input_warp .form-group select.classic:focus,
.common_input_warp .form-group textarea.form-control:focus {
    border: 1px solid #1c3e7d;
}

.container {
    max-width: 100% !important;
    width: 100%;
    padding: 0 138px !important;
}


/* Common  CSS End Here */


/* Login CSS Start Here */

.logicardwrap {
    position: relative;
    /* background-image: url("images/loginbg.png"); */
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    height: 100vh;
    text-align: left;
    background-color: #fff;
}

.logincardwrap {
    position: relative;
    height: 100vh;
}

.logincard {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    background: #ffffff;
    /* border: 1px solid #707070; */
    border-radius: 24px;
    padding: 40px 50px 50px 50px;
    display: grid;
    align-items: center;
}

.logincard img {
    width: 147px;
}

.logincard span {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
}

.common_input_warp .form-group {
    margin-bottom: 15px;
}

.logincard p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 20px;
    margin-top: 0px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif !important;
}

.logincontent {
    position: relative;
    height: 100%;
    background-size: 100% 100%;
    /* background-image: url(./public/images/loginbg.jpg); */
}

.logincontent .logincontcard {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 550px;
}

.logincontent h2 {
    font-size: 36px;
    line-height: 60px;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 600;
}

.logincontent p {
    font-size: 26px;
    line-height: 40px;
    color: #fff;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif !important;
}

.customimputcheckbox .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.customimputcheckbox .form-group label {
    position: relative;
    cursor: pointer;
}

.customimputcheckbox .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    padding: 9px;
    border-radius: 0px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
    top: -2px;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.customimputcheckbox .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    background-color: transparent;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    box-shadow: none;
    transition: all 400ms linear;
}

.customimputcheckbox .form-group input:checked+label::before {
    background-color: #1c3e7d;
    border: 1px solid #1c3e7d;
}

.customimputcheckbox .form-group label {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif !important;
}

.logincard .common_btn button {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: auto;
    padding: 26px 50px;
    line-height: 0px;
    width: auto;
    border: 1px solid #1E3E7B;
}

.logincard .common_btn button:hover {
    background-color: #fff;
    border: 1px solid #1E3E7B;
    color: #1E3E7B;
}

.forgotcontwrap a {
    display: block;
    font-size: 13px;
    color: #808080;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 7px;
}

.logincard.resetpassword a {
    text-align: right;
}

.logincard.resetpassword {
    padding-bottom: 60px;
}

.logincard.resetpassword.otpwrap {
    padding-bottom: 50px;
}

.logincard.resetpassword.otpwrap .common_btn button {
    margin-bottom: 10px;
    margin-top: 20px;
}

.logincard.resetpassword span {
    margin-bottom: 15px;
}

.forgotcontwrap.resendlink p {
    /* text-align: center; */
    font-size: 14px;
    font-family: "FiraSans-Medium";
}

.forgotcontwrap.resendlink p a {
    color: #1c3e7d;
    font-size: 14px;
    display: inline-block;
    margin-left: 2px;
}


/* Login CSS End Here */


/* OTP CSS Start Here */

.otp_form_wrap .right_form {
    padding: 40px 20px 20px 20px;
    width: 410px;
}

.otp_form_wrap .right_form h2 {
    text-align: center;
    font-family: "Roboto-Regular";
    padding-left: 0px;
    margin-bottom: 15px;
}

.otp_form_wrap .right_form span {
    display: block;
    font-size: 14px;
    color: #000;
    opacity: 0.51;
    font-family: "Roboto-Regular";
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.otp_fields {
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 5px;
}

.otp_time_wrap {
    display: none;
}

.otp_time_wrap .otp_time {
    position: relative;
    font-size: 14px !important;
    color: #000 !important;
    opacity: 1 !important;
    margin-top: 10px;
}

.otp_time_wrap .otp_resend {
    position: relative;
    font-size: 14px !important;
    font-weight: 500;
    color: #1877f2 !important;
    margin-top: 10px;
}

.digit-group input {
    width: 60px !important;
    height: 50px;
    background-color: transparent;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 200;
    color: #000;
    margin-right: 20px;
    margin-top: 5px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.digit-group input[type="number"]::-webkit-inner-spin-button,
.digit-group input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.digit-group input:focus {
    outline: 0px solid #20427f;
    border: 1px solid #20427f;
}

.digit-group .splitter {
    padding: 0 5px;
    color: #000;
    font-size: 24px;
}


/* OTP CSS End Here */


/* Footer Section CSS Start Here */

.footerwrap {
    position: relative;
    background-color: #1c3e7d;
    padding: 40px 0 20px 0px;
}

.footerwrap .footer-linkwrap {
    padding-bottom: 20px;
    border-bottom: 1px solid #365286;
}

.footerwrap .container {
    max-width: 100% !important;
    width: 100%;
    padding: 0 200px !important;
}

.footerwrap .footer-link p {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.58px;
}

.footer-link ul li {
    margin-bottom: 10px;
}

.footer-link ul li a,
.footer-link .address-div span {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.43px;
    text-decoration: none;
    line-height: 18px;
}

.footer-link .socialicon ul li {
    display: inline-block;
    margin-right: 10px;
}

.footer-link .mailtext a {
    color: #fff;
}

.footer-link .mailtext {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.43px;
}

.footer-link .address-div {
    margin-bottom: 15px;
}

.footer-link .address-div .footcountryimg {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
}

.footer-link .address-div img {
    display: block;
    margin-bottom: 0px;
    margin-right: 10px;
}

.conactus p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.conactus span {
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    margin-right: 35px;
    display: inline-block;
    cursor: pointer;
}

.conactus span svg {
    position: relative;
    margin-right: 10px;
    top: -3px;
}

.conactus.followswrap p {
    font-size: 15px;
    margin-left: 25px;
}

.conactus.followswrap ul li {
    list-style-type: none;
    margin-left: 25px;
    display: inline-block;
}

.conactus.followswrap ul li:last-child a {
    position: relative;
    top: 4px;
}

.conactus.followswrap ul li a {
    color: #fff;
}

.conactus.followswrap ul li a img {
    width: 25px;
    height: 25px;
}


/* .conactus.followswrap ul li a:hover img {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
} */

.copyright_section {
    padding: 30px 10px 0px 10px;
}

.copyright_section p {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
    opacity: 0.7;
}


/* Footer Section CSS End Here */


/* Assets Link, About, PP and T&C  Screen CSS Start Here */

.explorewrap.aboutuswrap .explorecont {
    padding: 40px 0 100px 0;
}

.explorewrap.assetslinkwrap ul {
    padding-top: 10px;
}

.explorewrap.assetslinkwrap ul li {
    list-style-type: none;
    padding-bottom: 50px;
    margin: 50px 0px 50px 0px;
    border: 0;
    height: 350px;
    border-bottom: 1px solid #00000014;
}

.explorewrap.assetslinkwrap ul li:last-child {
    border-bottom: 0;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap {
    box-shadow: none;
    position: relative;
    padding-left: 400px;
    background-color: transparent;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap .productimgcard {
    position: absolute;
    left: 0;
    top: 0;
    width: 332px;
    height: 291px;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    display: grid;
    place-items: center;
    background-color: #fff;
}

.explorewrap.assetslinkwrap ul li a .assetscardwrap .productimgcard img {
    object-fit: cover;
}

.explorewrap.assetslinkwrap ul li .assetscardwrap h2,
.explorewrap.assetslinkwrap ul li .assetscardwrap p,
.explorewrap.assetslinkwrap ul li .assetscardwrap span {
    text-align: left;
}

.explorewrap.assetslinkwrap ul li .assetscardwrap .common_btn button {
    width: 225px;
    height: 56px;
}

.explorewrap.termscondition {
    padding-bottom: 50px;
}

.explorewrap.termscondition .explorecont {
    padding: 15px 0 0px 0;
}

.explorewrap.termscondition .explorecont p {
    padding-bottom: 20px;
}

.explorewrap.termscondition .more_btn a span svg {
    width: 72px;
    height: 46px;
    margin-top: 20px;
}


/* Assets Link, About, PP and T&C  Screen CSS End Here */


/* Custom Popup CSS Start Here */

.modal-dialog.custom_modal_wrap {
    position: relative;
    margin: auto;
    display: table;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border-radius: 28px;
    overflow: hidden;
}

.modal-dialog.custom_modal_wrap .close {
    position: absolute;
    right: -5px;
    top: -15px;
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 100%;
    background-color: #f0505329;
    opacity: 1;
    border: 0;
    color: #f05053;
    text-shadow: none;
    font-size: 25px;
    display: block;
    text-align: center;
}


/* .modal-dialog.custom_modal_wrap .close span {
  color: #f05053;
  text-shadow: none;
  font-size: 22px;
  display: block;
} */

.modal-dialog.custom_modal_wrap .modal-content {
    width: 650px !important;
    padding: 20px 20px 20px 20px;
    border: 0;
    border-radius: 28px;
}

.modal-open .modal.modal-center .modal-dialog {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0 15px;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
    display: none;
}

.logoutpopupwrap .like_img {
    margin: 0 auto;
    width: 86px;
}

.logoutpopupwrap .like_img img {
    width: 100%;
}

.logoutpopupwrap h2 {
    font-size: 32px !important;
    text-align: center;
    line-height: 30px;
    margin: 0px 0px 10px !important;
    font-weight: 600;
}

.logoutpopupwrap p {
    font-size: 24px;
    color: #000000;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
}

.logoutpopupwrap .common_btn {
    margin: 0 auto;
    display: table;
}

.logoutpopupwrap .common_btn button {
    width: 200px;
    height: 55px;
    max-width: 200px;
    margin: 20px 5px 0 5px;
    display: inline-block;
}

.maintenance-month-list .maintenancediv-wrap.greenbox {
    background: #d1f3ce;
    border: 1px solid #d1f3ce;
}

.mysubmittedrequested .maintenancediv-wrap.greenbox:before {
    border: 1px dashed #d1f3ce;
}


/* Custom Popup CSS End Here */


/* Change Password Screen CSS Start Here */

.changespasswordwrap {
    margin-top: 30px;
    margin-bottom: 30px;
}

.changespasswordwrap .common_input_warp .form-group,
.common_btn button {
    margin-bottom: 35px;
}


/* Change Password Screen CSS End Here */

.error {
    color: #bf1650 !important;
    font-size: 14px !important;
    /* text-transform: capitalize; */
    text-align: left !important;
}

.error::before {
    display: inline;
    content: "⚠ ";
}

.pagination {
    display: flex;
    background-color: #f0f3f6;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    justify-content: center;
    align-items: center;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px !important;
    height: fit-content !important;
}

.pagination li:hover {
    text-decoration: underline;
}

.pagination .disabled a {
    color: #707070 !important;
}

.pagination .disabled:hover {
    text-decoration: none !important;
}

.pagination .disabled a:hover {
    cursor: default;
}

.pagination .selected a {
    background: #1c3e7d;
    padding: 0px 10px;
    color: white !important;
}

.Toastify__toast-container {
    z-index: 999999 !important;
}

.courser-pointer {
    cursor: pointer;
}

.empty {
    text-align: center;
    font-size: 22px;
}

.word-break {
    word-break: break-word !important;
}

.ellipsis {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.explorewrap {
    min-height: calc(100vh - 230px);
}

.loaderdv {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background: #00000091;
    z-index: 9999999 !important;
    overflow: hidden;
}

.loader-svg {
    position: fixed;
    right: 0;
    top: 72px;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 9999999 !important;
    overflow: hidden;
}

.loaderdv svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    width: 300px !important;
    height: 300px !important;
}

.loaderdv rect {
    fill: transparent;
}

.blur-text {
    display: inline-block;
    filter: blur(8px);
}

table {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: 1px solid lighten(#ccc, 10%);
    border-radius: 10px;
}

.svg-section-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 147px);
}

.explorewrap .pro-img img {
    position: relative;
    right: auto;
    top: auto;
    width: 50px;
    height: 50px;
}

.explorewrap.services-pg-wrap.termscondition {
    padding-bottom: 0px;
    margin: 20px 60px 0px 60px;
    /* border: 1px solid #c9d6e1; */
    border-radius: 10px;
    min-height: 300px !important;
    border: 0px !important;
}

.emptycart-wrap p {
    font-size: 17px;
    color: #000;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 500;
}

.services-pg-wrap .tabledatawrap table {
    margin: 0px;
}

.services-pg-wrap .tabledatawrap table thead th {
    padding: 18px 10px !important;
    color: #86A3BB;
}

.services-pg-wrap .tabledatawrap table thead {
    width: 100%;
    background: #fff;
    color: #a7c3db;
}

.services-pg-wrap .tabledatawrap {
    border-radius: 10px;
}

.services-pg-wrap .tabledatawrap table tbody tr {
    border-top: 1px solid #e0e0e0;
    background: #fff !important;
    border-bottom: 2px dashed #e0e0e0 !important;
}

.services-pg-wrap .tabledatawrap table tbody tr:last-child {
    border-bottom: 0px !important;
}

.services-pg-wrap .tabledatawrap table tbody tr td {
    padding: 20px 10px;
    color: #193870;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    align-items: center;
    justify-content: center;
}

.services-pg-wrap .tabledatawrap table tbody tr td .action-text a {
    color: #fff;
    margin: 0px 10px;
    /* text-decoration: underline!important; */
    padding: 12px;
    background-color: #1b3e7c;
    border-radius: 6px;
    font-weight: 500;
}

#svg-section {
    margin: 20px 0px 20px 0px;
}

.serialnumberpop-wrap {
    max-width: 50%;
    margin: auto;
}

.serialnumberpop-wrap select {
    height: 50px;
    margin-bottom: 25px;
}

.serialnumberpop-wrap .common_btn {
    display: flex;
}

.serialnumberpop-wrap .common_btn button {
    margin: 0px 10px !important;
}

.pac-container {
    z-index: 10000 !important;
}

.request-form-wrap {
    border: 0px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #e4afaf29;
    padding: 30px 40px;
}

.display-none {
    display: none !important;
}

.disabled {
    pointer-events: none;
    cursor: pointer;
}


/* ================= */


/* ================= */