.comming-page.explorewrap .container {
    padding: 0px 80px !important;
}

.comming-page .commingpage-wrap {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    margin: 120px 50px;
    padding: 50px 0px;
}

.commingpage-wrap .heading-icon img {
    position: relative;
    right: auto;
    top: auto;
    width: 91px;
    height: 69px;
    margin-right: 25px;
}

.page-content .left-Aboutimg {
    margin-bottom: 35px;
}

.page-content .content-wrap {
    margin-top: 50px;
}

.page-content .content-wrap p,
.page-content .content-wrap li {
    font-size: 18px;
    color: #4D4D4D;
    font-weight: 500;
    line-height: 35px;
}

.page-content .content-wrap li {
    line-height: 45px;
}

.page-content .content-wrap ul {
    margin: 0.75em 0;
    padding: 0 1em;
    list-style: none;
}

.commingpage-wrap .heading-icon.assetsicon img {
    width: 90px;
    height: 90px;
}

.commingpage-wrap .heading-icon.geoicon img {
    width: 120px;
    height: 80px;
}

.page-name-heading h4 {
    font-size: 26px;
    font-weight: 700;
    color: #1E3E7B;
    line-height: 30px;
}

.page-content .content-wrap li::before {
    content: "";
    border-color: transparent #1E3E7B;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -20px;
    top: 30px;
    position: relative;
}

.commingpage-wrap .coming-imge img {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    max-width: 70%;
    margin: 0px auto;
    display: table;
}

.page-content .page-name-heading {
    display: flex;
    align-items: center;
}

.star-circle {
    position: relative;
    right: inherit;
    top: inherit;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    padding: 5px;
    box-shadow: 0px 9.03012px 27.0904px rgb(191 203 203 / 32%), 0px 3.38629px 5.64383px rgb(223 226 227 / 32%);
    border: 2px dashed#d5a002;
    object-fit: cover;
}

.star-user {
    position: absolute;
    left: 15px;
    bottom: -13px;
}

.star-user svg {
    width: 10px !important;
    color: #d5a002 !important;
}

@media (max-width:1600px) {
    .page-name-heading h4 {
        font-size: 24px;
    }
    .comming-page .commingpage-wrap {
        padding: 40px 0px;
    }
    .page-content .content-wrap p,
    .page-content .content-wrap li {
        font-size: 17px;
        line-height: 30px;
    }
    .page-content .content-wrap li::before {
        top: 22px;
    }
}

@media (max-width:1460px) {
    .comming-page.explorewrap .container {
        padding: 0px 40px !important;
    }
    .comming-page .commingpage-wrap {
        padding: 30px 0px;
    }
    .comming-page.explorewrap .left-Aboutimg>img {
        width: 130px;
    }
    .commingpage-wrap .heading-icon.assetsicon img {
        width: 70px;
        height: 70px;
    }
    .page-content .content-wrap p,
    .page-content .content-wrap li {
        font-size: 17px;
        line-height: 27px;
    }
    .page-name-heading h4 {
        font-size: 21px;
    }
    .page-content .content-wrap li::before {
        top: 20px;
    }
    .commingpage-wrap .heading-icon img {
        width: 81px;
        height: 59px;
    }
}

@media (max-width:1340px) {
    .commingpage-wrap .heading-icon img {
        width: 71px;
        height: 49px;
    }
    .comming-page .commingpage-wrap {
        padding: 25px 0px;
        margin: 110px 40px;
    }
    .page-name-heading h4 {
        font-size: 19px;
    }
    .page-content .content-wrap {
        margin-top: 35px;
    }
    .commingpage-wrap .heading-icon.assetsicon img {
        width: 60px;
        height: 60px;
    }
    .comming-page.explorewrap .left-Aboutimg>img {
        width: 120px;
    }
    .commingpage-wrap .heading-icon.geoicon img {
        width: 100px;
        height: 60px;
    }
    .page-content .content-wrap p,
    .page-content .content-wrap li {
        font-size: 15px;
        line-height: 27px;
    }
    .page-content .content-wrap li::before {
        top: 23px;
    }
    .comming-page.explorewrap .container {
        padding: 0px 30px !important;
    }
}

@media (max-width:1023px) {
    .comming-page.explorewrap .container {
        padding: 0px 20px !important;
    }
    .commingpage-wrap .heading-icon.geoicon img {
        width: 80px;
        height: 40px;
    }
    .commingpage-wrap .heading-icon img {
        margin-right: 15px;
    }
}

@media (max-width:767px) {
    .star-user {
        bottom: -10px;
    }
    .commingpage-wrap .heading-icon img {
        width: 71px;
        height: 49px;
    }
    .commingpage-wrap .heading-icon img {
        width: 61px;
        height: 45px;
    }
    .commingpage-wrap .heading-icon.geoicon img {
        width: 70px;
        height: 40px;
    }
    .coming-imge {
        display: none;
    }
    .comming-page .commingpage-wrap {
        padding: 20px 0px;
        margin: 85px 15px 40px 15px;
    }
    .comming-page.explorewrap .left-Aboutimg>img {
        width: 100px;
    }
    .page-content .left-Aboutimg {
        margin-bottom: 20px;
    }
    .commingpage-wrap .heading-icon.assetsicon img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
    }
    .page-name-heading h4 {
        font-size: 17px;
    }
    .page-content .content-wrap {
        margin-top: 20px;
    }
    .page-content .content-wrap p,
    .page-content .content-wrap li {
        font-size: 14px;
        line-height: 25px;
    }
    .page-content .content-wrap li::before {
        top: 18px;
    }
}