.overlay-div1,
.overlay-div2,
.overlay-div3 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
}

.overlay-div2,
.overlay-div3 {
    /* display: none; */
}

.overlayshow1 {
    position: absolute;
    left: 100px;
    bottom: 50px;
    width: 600px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.overlayshow2 {
    position: absolute;
    left: 70px;
    bottom: 50px;
    width: 600px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.overlayshow2 .arrowimage img {
    width: 190px;
}

.overlayshow3 {
    position: absolute;
    left: 100px;
    bottom: 50px;
    width: 600px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.overlayshow3 .arrowimage {
    margin-left: 40px;
}

.overlayshow3 .arrowimage img {
    width: 140px;
}

.overlayshow3 .info-div {
    margin-left: 20px;
}

.info-div {
    margin-left: 40px;
}

.arrowimage img {
    width: 150px;
}

.info-div p {
    font-size: 14px;
    line-height: 25px;
}

.nxt-btn button.btnnxt {
    color: #1E3E7B;
    font-weight: 600;
    padding: 5px 12px;
    border: 0px;
    border-radius: 10px;
    font-size: 13px;
    margin-right: 5px;
}

.main-banner-top {
    position: relative;
    /* width: 100%;
    height: 251px;
    border-radius: 10px;
    background: url(../../images/config_bans.jpg)no-repeat;
    background-size: 100%;
    object-fit: cover; */
}

.main-banner-top img {
    position: static;
    width: 100%;
    right: auto;
    top: auto;
    height: auto !important;
    border-radius: 8px;
}

.explorewrap.minHn {
    min-height: inherit;
}

.main-banner-top .banner-inner-heading {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 20px 100px 20px 20px;
    background: rgb(195 195 195 / 80%);
    border-radius: 0px 0px 10px 0px;
}

.image-gallery-swipe {
    /* height: calc(100vh - 130px); */
    width: 100%;
}

.main-banner-top .banner-inner-heading h3 {
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
}

.configuator-list ul li span {
    display: block;
    text-align: center;
    max-width: 80px;
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    margin: 0px 10px;
    font-size: 12px;
}

.p-zero {
    padding: 0px;
}

.image-gallery-swipe .image-gallery-slides {
    width: 100%;
    height: 100%;
}

.star-nec {
    color: #e93636;
}

.configurator-machine-name h4 button {
    border: 0px;
    padding-left: 0px;
}

.configurator-machine-name h4 button .svg-inline--fa {
    color: #193870;
}

.configurator-machine-name p {
    margin: 0px;
    padding: 5px 0px 10px 30px;
    font-weight: 600;
}

.configurator-summary {
    /* width: 200px;
    height: 200px; */
    margin-bottom: 0px;
}

.configurator-summary img {
    position: relative !important;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    padding: 2px;
    right: auto;
    top: auto;
    max-width: 100%;
    max-height: 100%;
}

.configurator-summary .summary-list {
    margin-bottom: 20px;
}

.configurator-summary .summary-list label,
.configurator-view-img label {
    display: block;
}

.request-form-wrap h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.configurator-summary {
    display: flex;
}

.configurator-summary.configurator-view-img {
    display: block;
}

.summarydetail-wrap .dflex {
    display: flex;
    align-items: center;
}

.summarydetail-wrap .dflex label {
    margin-right: 20px;
}

.assets-configurator-wrap {
    padding: 20px 0;
}

.explorepage-wrap.configurationlist-wrap .container {
    padding: 0px !important;
}

.explorepage-wrap.configurationlist-wrap {
    background-color: #f0f3f6 !important;
}

.align-item-center {
    align-items: center;
}

.assets-configurator-wrap .explorebg-image {
    margin-left: auto;
    text-align: right;
}

.assets-configurator-wrap .explorebg-image img {
    width: 55% !important;
    text-align: right;
}

.assets-configurator-wrap .explorecont-heading h3 {
    line-height: 40px;
    font-size: 26px;
}

.configure-btn {
    margin-top: 30px;
}

.configure-btn a {
    font-size: 14px;
    padding: 12px 40px;
}

.configurator-types-wrap {
    padding: 40px 60px 0px 60px;
}

.configurator-type {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    min-height: 245px;
}

.configurator-type h4 {
    font-weight: 700;
    text-align: center;
    color: #1E3E7B;
    font-size: 20px;
}

.configuratorinfo {
    display: flex;
    align-items: start;
    margin-top: 20px;
}

.configuratorinfo p {
    padding: 0px 20px;
    font-size: 14px;
    color: #6C6C6C;
    font-weight: 500;
}

.configurator-section {
    position: relative;
    margin-top: 0px;
    background-color: white !important;
    /* height: 100vh; */
}

.cloudimage-360-fullscreen-icon {
    width: 45px !important;
    height: 45px !important;
    margin-bottom: 5px;
    cursor: pointer;
    background: url(/public/images/expandd.png)no-repeat !important;
    margin-right: 15px !important;
    background-size: 100% 100% !important;
}

.configurator-machine-name button img {
    width: 30px;
}

.image-gallery-right-nav {
    right: 15px !important;
    top: 50% !important;
    transform: translateY(-50%);
}

.image-gallery-left-nav {
    left: 15px !important;
    right: auto !important;
    top: 50% !important;
    transform: translateY(-50%);
}


/* .configurator-img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
} */

.configuratorinfo .configurator-img {
    width: 130px;
    margin-right: 20px;
}

.configuratorinfo .configurator-img img {
    max-width: 100%;
    max-height: 100%;
}

.configuratorinfo ul li {
    margin-bottom: 5px;
    font-size: 14px;
    color: #6C6C6C;
}

.configuratorinfo ul li a {
    color: #6C6C6C;
}

.configurator-controller {
    overflow: hidden;
    overflow-y: auto;
}

.tags0,
.tags1,
.tags2 {
    list-style: none;
    margin-left: 25px !important;
    margin-bottom: 20px !important;
    display: flex;
    flex-wrap: wrap;
}

.tags0 li img {
    margin: 10px 5px;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    border: 3px solid transparent;
}

.tags1 li {
    font-size: 20px;
}

.tags1 li img {
    margin: 10px 5px;
    padding: 2px;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    border: 3px solid transparent;
}

.tags2 li {
    font-size: 20px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    border: 3px solid transparent;
}

.tags0,
.tags1,
.tags2 {
    /* width: 450px; */
    margin: 0 auto;
    /* background: rgba(16 18 27 / 40%); */
    border-radius: 8px;
    /* backdrop-filter: blur(20px); */
}

.active-tag img {
    border: 3px solid #1e3e7b !important;
    color: black;
}

.machine-sm-img {
    width: 90px;
    height: 90px;
    object-fit: fill;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 0px;
    background: #1e3e7b;
    border-radius: 100%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 55px;
    width: 55px;
}

.image-gallery-left-nav .image-gallery-svg .polyline {
    fill: #1E3E7B;
    color: #1E3E7B;
}


/* Configurator list page */

.configuraotorlist-wrap {
    margin-top: 0px;
}

.configuraotorlist-heading {
    padding: 25px 0px;
    background: #1E3E7B;
    color: #fff;
}

.configuraotorlist-heading .container {
    padding: 0px 80px !important;
}

.machine-img-big {
    position: relative;
}

.machine-img-big img {
    width: 100%;
}

.configuraotorlist-wrap .assets-machine-box {
    position: relative;
    height: calc(100vh - 230px);
    max-height: 700px;
    width: 100%;
}

.configurator-image .image-gallery-slides .image-gallery-slide {
    height: 100vh;
}


/* .configuraotorlist-wrap .assets-machine-box img {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
} */

.image-gallery-content .image-gallery-slide .image-gallery-image {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.assets-machine-box .assets-machine-name {
    position: absolute;
    bottom: 80px;
    right: 100px;
    text-align: right;
    width: 420px;
    padding: 20px 20px 20px 20px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
}

.assets-machine-box .assets-machine-name p {
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: 20px;
}

.assets-machine-name .configurbtn button {
    background: #E1F0FF;
    border: 0px;
    padding: 12px 30px;
    border-radius: 6px;
    color: #1E3E7B;
    font-weight: 600;
    font-size: 15px;
}

.assets-machine-box .row {
    height: 100%;
    align-items: center;
}

.assets-machine-box .machine-img {
    margin-left: 80px;
}

.assets-machine-box .machine-img img,
.assets-machine-box .machine-blur img {
    width: 543px;
}

.assets-machine-box .machine-blur {
    background: url(../../images/diagonal-bg.png);
    background-position: right center;
    background-size: 100% 100%;
    height: 600px;
    display: flex;
    align-items: center;
    backdrop-filter: blur(19px);
    -webkit-backdrop-filter: blur(19px);
    -moz-filter: blur(19px);
    -o-filter: blur(19px);
    -ms-filter: blur(19px);
    filter: blur(19px);
}

.assets-machine-wrap li:nth-child(even) .assets-machine-box .machine-blur {
    background: url(../../images/diagonal-bg-or.png);
}


/* Configurator list page */


/* Configurator Action page */

.configurator-machine-name {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    padding: 15px 30px;
}

.configurator-controller h4 {
    /* background: #E1F0FF; */
    color: #fff;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
}

.configurator-controller {
    /* position: absolute;  */
    left: 0px;
    /* width: 255px; */
    background: rgb(0 0 0 / 70%);
    padding-right: 0px !important;
    order: 1;
    -webkit-order: 1;
    height: calc(100vh - 135px);
    bottom: 0;
    top: 0px;
}

.configurator-controller .bottom-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    right: 0px;
    display: flex;
    width: 250px;
    z-index: 2;
}

.configurator-controller .bottom-btn button {
    position: relative;
    padding: 8px;
    background-color: transparent;
    border: 0px;
    border-radius: 6px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    margin: 0px 5px;
}

.configurator-controller .bottom-btn button span {
    position: absolute;
    opacity: 0;
    line-height: 1.125em;
    letter-spacing: .125rem;
    font-weight: 300;
    text-transform: uppercase;
    color: inherit;
    padding-right: 0;
    bottom: 100%;
    left: 50%;
    text-align: center;
    margin-left: 0;
    transform: translateX(-50%);
    width: auto;
    max-width: 10rem;
    word-wrap: break-word;
    font-size: 8px;
}

.configurator-controller .bottom-btn button:hover span {
    opacity: 1;
}

.configurator-controller .bottom-btn button img {
    width: 40px;
}


/* .configurator-section .cloudimage-360-fullscreen-icon {
    background: url(../../../public/images/expand.png)no-repeat!important;
    width: 64px!important;
    height: 64px!important;
    background-size: 100%!important;
    margin-right: 40px!important;
    margin-top: 10px!important;
} */

.configurator-image {
    position: relative;
    padding-left: 0px;
    order: 2;
    -webkit-order: 2;
    height: calc(100vh - 135px);
}

.image-gallery-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.machine-name {
    font-size: 13px;
    color: #515151;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: center;
}

.pad-zero {
    padding: 10px 0px 0px 0px !important;
}

.configurator-controller h4.no-border {
    border: 0px;
}

.close-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 6px;
}


/* .cloudimage-360-fullscreen-modal {
    top: 80px !important;
} */

.ondesktop {
    display: inherit;
}

.onphone {
    display: none;
}


/* Configurator Action page */

@media (max-width:1460px) {
    .configurator-machine-name {
        font-size: 20px;
        padding: 12px 25px;
    }
    .configurator-controller h4 {
        font-size: 18px;
        padding: 8px 25px;
    }
    .tags0,
    .tags1,
    .tags2 {
        margin-left: 20px !important;
    }
    .machine-sm-img {
        width: 90px;
        height: 90px;
    }
    .assets-configurator-wrap .explorecont-heading h3 {
        font-size: 28px;
        line-height: 40px;
    }
    .configurator-type h4 {
        font-size: 18px;
    }
    .configuratorinfo .configurator-img {
        width: 120px;
    }
    .assets-configurator-wrap {
        padding: 20px 30px;
    }
    .configurator-types-wrap {
        padding: 40px 40px 0px 40px;
    }
    .explorewrap.services-pg-wrap.termscondition {
        margin: 0px 40px 0px 40px;
    }
    .configuraotorlist-heading {
        padding: 20px 0px;
    }
    .configuraotorlist-heading h4 {
        font-size: 22px;
    }
    .assets-machine-box .machine-blur {
        height: 570px;
    }
    .assets-machine-box .machine-img img,
    .assets-machine-box .machine-blur img {
        width: 500px;
    }
    .configuraotorlist-wrap .assets-machine-box {
        max-height: 580px;
    }
    .assets-machine-box .assets-machine-name p {
        font-size: 26px;
    }
    .configuraotorlist-heading .container {
        padding: 0px 60px !important;
    }
}

@media (max-width:1380px) {
    .overlayshow1 .info-div {
        margin-left: 20px;
    }
    .overlayshow1 .info-div h4,
    .overlayshow2 .info-div h4,
    .overlayshow3 .info-div h4 {
        font-size: 20px;
    }
    .overlayshow1 {
        left: 85px;
    }
    .arrowimage img {
        width: 140px;
    }
    .info-div {
        margin-left: 25px;
    }
    .overlayshow2 .arrowimage img {
        width: 160px;
    }
    .overlayshow2 {
        left: 50px;
    }
    .overlayshow3 .arrowimage img {
        width: 130px;
    }
}

@media (max-width:1280px) {
    .machine-sm-img {
        width: 75px;
        height: 75px;
    }
    .overlayshow2 .arrowimage img {
        width: 140px;
    }
    .overlayshow1 .info-div h4,
    .overlayshow2 .info-div h4,
    .overlayshow3 .info-div h4 {
        font-size: 18px;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 45px;
        width: 45px;
    }
    .configurator-machine-name {
        font-size: 16px;
        word-break: break-word;
        padding: 10px 25px;
    }
    .main-banner-top .banner-inner-heading h3 {
        font-size: 20px;
    }
    /* .configurator-image .image-gallery-slides .image-gallery-slide{
        height: auto;
    } */
    .configurator-controller h4 {
        font-size: 14px;
        padding: 10px 25px;
    }
    .configuator-list ul li span {
        font-size: 14px;
    }
    .configuraotorlist-heading .container {
        padding: 0px 40px !important;
    }
    .configurator-type {
        padding: 15px;
    }
    .configuratorinfo .configurator-img {
        width: 110px;
        margin-right: 10px;
    }
    .assets-configurator-wrap .explorecont-heading h3 {
        font-size: 24px;
        line-height: 35px;
    }
    .configurator-type h4 {
        font-size: 17px;
    }
    .assets-configurator-wrap {
        padding: 30px 20px;
    }
    .configurator-types-wrap {
        padding: 30px 30px 0px 30px;
    }
    .explorewrap.services-pg-wrap.termscondition {
        margin: 0px 30px 0px 30px;
    }
    .assets-machine-box .machine-img img,
    .assets-machine-box .machine-blur img {
        width: 450px;
    }
    .assets-machine-box .assets-machine-name p {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .assets-machine-box .assets-machine-name {
        right: 60px;
    }
    .configuraotorlist-heading h4 {
        font-size: 20px;
    }
}

@media (max-width:1220px) {
    .image-gallery-swipe {
        height: calc(100vh - 135px);
    }
    .configurator-controller {
        height: calc(100vh - 135px);
    }
    .configurator-image {
        height: 100%;
    }
    .configuraotorlist-wrap .assets-machine-box {
        height: auto;
    }
    .configurator-controller .bottom-btn {
        bottom: 0px;
        width: 100%;
    }
    .image-gallery-slides .image-gallery-slide {
        height: 100%;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        top: auto;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        /* height: 100%; */
    }
}

@media (max-width:1080px) {
    .machine-sm-img {
        width: 65px;
        height: 65px;
    }
    .overlayshow1 {
        left: 70px;
        max-width: 500px;
    }
    .overlayshow1 {
        max-width: 500px;
    }
    .overlayshow2 {
        left: 40px;
    }
    .configurator-controller .bottom-btn button img {
        width: 30px;
    }
    .overlayshow3 .arrowimage img {
        width: 120px;
    }
    .overlayshow3 {
        left: 75px;
    }
    .overlayshow1 .info-div h4,
    .overlayshow2 .info-div h4,
    .overlayshow3 .info-div h4 {
        font-size: 17px;
    }
    .overlayshow3 .arrowimage {
        margin-left: 25px;
    }
    .overlayshow2 .arrowimage img {
        width: 120px;
    }
    .overlayshow2 .info-div {
        margin-left: 10px;
    }
    .arrowimage img {
        width: 120px;
    }
    .overlayshow1 .info-div {
        margin-left: 10px;
    }
    .configurator-machine-name {
        font-size: 14px;
    }
    .configuator-list ul li span {
        font-size: 14px;
    }
    .configurator-controller h4 {
        font-size: 14px;
    }
    .tags0,
    .tags1,
    .tags2 {
        margin-bottom: 10px!important;
    }
    .configuraotorlist-heading .container {
        padding: 0px 30px !important;
    }
    .assets-machine-box .assets-machine-name p {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .assets-machine-box .machine-img {
        margin-left: 30px;
    }
    .assets-machine-box .machine-img img,
    .assets-machine-box .machine-blur img {
        width: 400px;
    }
    .assets-machine-box .assets-machine-name {
        right: 40px;
    }
    .configuraotorlist-heading {
        padding: 15px 0px;
    }
}

@media (max-width:991px) {
    .configurator-controller {
        height: 100vh;
    }
    .magnify-Image .image-gallery-swipe {
        height: 100%;
    }
    .detail-assets-lt .pdfdownloadbtn .flex-div button {
        margin: 0 auto 10px !important;
    }
    .configuraotorlist-wrap {
        margin-top: 65px !important;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 42px;
        width: 42px;
    }
    .configuraotorlist-wrap .assets-machine-box {
        max-height: 480px;
    }
    .machine-img-big img {
        width: 100%;
    }
    .assets-machine-box .assets-machine-name {
        right: 40px;
        width: 320px;
    }
    .configurator-section {
        margin-top: 60px;
        height: 100%
    }
    .configurator-controller .bottom-btn button img {
        width: 30px;
    }
    .assets-configurator-wrap .explorebg-image {
        display: none;
    }
    .explorepage-wrap .explore-btn.configure-btn {
        display: block;
    }
    .configuratorinfo .configurator-img {
        width: 90px;
    }
    .assets-configurator-wrap .explorecont-heading h3 {
        font-size: 22px;
        line-height: 35px;
    }
    .explorewrap.services-pg-wrap.termscondition {
        margin: 70px 15px 0px 15px;
    }
    .explorewrap.termscondition .explorecont {
        padding: 0px 0 20px 0 !important;
    }
    .configurator-types-wrap {
        padding: 20px 15px 0px 15px;
    }
    .assets-machine-box .assets-machine-name p {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .configuraotorlist-heading h4 {
        font-size: 18px;
    }
    .configurator-machine-name {
        font-size: 16px;
    }
}

@media (max-width:767px) {
    .overlay-div1,
    .overlay-div2,
    .overlay-div3 {
        top: -75px;
    }
    .configurator-controller {
        height: calc(100vh - 170px);
    }
    .overlayshow1,
    .overlayshow2 {
        max-width: 300px;
        width: 300px;
        bottom: 190px;
    }
    .overlayshow1 .arrowimage img,
    .overlayshow2 .arrowimage img,
    .overlayshow3 .arrowimage img {
        width: auto;
        height: 60px;
    }
    .overlayshow1 .arrowimage {
        position: absolute;
        left: 40%;
        transform: rotate(180deg);
        bottom: -100px
    }
    .overlayshow2 .arrowimage {
        transform: rotate(180deg);
        position: absolute;
        bottom: -100px;
        left: 30px;
    }
    .overlayshow3 {
        left: auto;
        max-width: 300px;
        width: 300px;
        right: 30px;
        text-align: right;
        bottom: 190px;
    }
    .overlayshow3 .arrowimage {
        transform: rotate(180deg);
        position: absolute;
        bottom: -100px;
        left: auto;
        right: 30px;
    }
    .ondesktop {
        display: none;
    }
    .onphone {
        display: block;
    }
    .tags0,
    .tags1,
    .tags2 {
        margin-bottom: 5px !important;
    }
    .configurator-controller {
        padding: 10px 10px 70px 10px;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 36px;
        width: 36px;
    }
    .tags0,
    .tags1,
    .tags2 {
        margin-left: 15px !important;
    }
    .configurator-machine-name {
        font-size: 15px;
        padding: 10px 15px;
    }
    .configurator-controller h4 {
        font-size: 14px;
        padding: 10px 15px;
    }
    .assets-machine-name .configurbtn button {
        padding: 10px 20px;
        font-size: 14px;
    }
    .assets-machine-box .assets-machine-name p {
        font-size: 16px;
        line-height: 30px;
    }
    .configuraotorlist-wrap {
        margin-top: 60px;
    }
    .configuraotorlist-wrap .assets-machine-box {
        max-height: 100%;
        margin-top: 20px;
        height: auto;
        max-height: 100%;
    }
    .configurator-type h4 {
        font-size: 16px;
        text-align: left;
    }
    .configuratorinfo .configurator-img {
        width: 70px;
    }
    .configurator-controller .bottom-btn {
        right: 0px;
        bottom: 5px;
        justify-content: center;
    }
    .configurator-controller .bottom-btn button img {
        width: 30px;
    }
    .configurator-section {
        margin-top: 60px;
    }
    .configurator-image .image-gallery {
        margin-bottom: 0px;
    }
    .configuraotorlist-heading .container {
        padding: 0px 15px !important;
    }
    .configuraotorlist-heading h4 {
        font-size: 14px;
    }
    .assets-configurator-wrap .explorecont-heading h3 {
        font-size: 18px;
        line-height: 30px;
    }
    .assets-configurator-wrap {
        padding: 10px 0px;
    }
    .explorewrap.minHn .assets-configurator-wrap {
        padding: 0 10px;
    }
    .assets-machine-box .assets-machine-name {
        right: 40px;
        width: 220px;
        padding: 10px;
        max-width: 100%;
        /* margin-left: 30px; */
    }
    .configurator-type {
        min-height: initial;
    }
    .assets-machine-box .assets-machine-name p {
        color: #fff;
    }
    .assets-machine-box .row {
        height: auto;
        align-items: center;
    }
    .assets-machine-box .machine-blur {
        display: none;
    }
    .configuraotorlist-wrap .assets-machine-box {
        max-height: 350px;
        margin-top: 0px;
    }
    .assets-machine-box .machine-img {
        margin: 0px auto;
        display: table;
    }
    .assets-machine-box .machine-img img,
    .assets-machine-box .machine-blur img {
        width: 320px;
    }
}

@media (max-width:460px) {
    .configuraotorlist-wrap .assets-machine-box {
        max-height: 300;
        margin-top: 0px;
        height: auto;
    }
    .assets-machine-box .assets-machine-name {
        right: 20px;
        width: 190px;
        bottom: 20px;
        padding: 10px 10px 10px 5px;
    }
}