/* =================== */

.subitprefenctbtn {
    display: flex;
    align-items: center;
}

.mysubmittedrequested .cartPage-heading button.prefrencebtn {
    color: #fff;
    font-size: 16px;
    line-height: 10px;
    width: auto;
    padding: 15px 10px;
    border: 0;
    width: auto;
    height: 45px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #1c3e7d;
    border: 1px solid #1c3e7d;
}

.rightsid-btn {
    align-items: center;
}

.cartPage-heading .common_btn button.orngebtn {
    background-color: #FFA2006E;
    border: 1px solid #FFA2006E;
    color: #FFAA00;
}

.cartPage-heading .common_btn button.yellowbtn {
    background-color: #e1f0ff;
    border: 1px solid #e1f0ff;
    color: #1e3e7b;
}

.cartPage-heading .common_btn button.greenbtn {
    background-color: #eaffec;
    border: 1px solid #ceffd3;
    color: #04ff00;
}

.downloadbtn {
    font-size: 22px;
    margin-left: 20px;
    color: #1E3E7B;
}

.submiteddetail-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    border-radius: 10px;
}

.submiteddetail-wrap .dtlsdivbox {
    width: 60%;
    padding: 0px;
    background-color: #fff;
    border-radius: 10px 0px 0px 10px;
}

.dtlsdivbox .dtlsdiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
}

.prefrencelist {
    padding: 0px 10px;
}

.prefrencelist ul li input {
    margin-right: 10px;
}

.prefrencelist ul li {
    width: 50%;
    display: inline-block;
}

.logoutpopupwrap .prefrencelist .common_btn {
    width: 50%;
    margin: 20px auto;
    display: flex;
}

.logoutpopupwrap .prefrencelist .common_btn button {
    margin: 0px 10px;
}

.dtlsdiv .dtlsheading {
    width: 50%;
    text-align: left;
    padding: 20px 30px;
    background-color: #fff;
    color: #1E3E7B;
    font-size: 17px;
    font-weight: 600;
}

.dtlsdiv .dtlsbxrow {
    width: 50%;
    text-align: right;
    padding: 20px 30px;
    background-color: #E1F0FF;
    color: #06152B;
    font-size: 17px;
    font-weight: 600;
}

table.sub {
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

table.sub th {
    padding: 10px;
}

table.sub .tbrrw {
    display: flex;
}

table.sub th {
    width: 33.33%;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

table.sub .tbrrw span {
    width: 33.33%;
    padding: 10px;
    display: block;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table.sub .tbrrw span:last-child {
    border-right: 0;
}

.submiteddetail-wrap .addressdiv-left {
    width: 40%;
    padding: 10px;
    background: #fff;
}

.addresswrap {
    padding: 30px 40px;
}

.divbdr {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 20px;
}

.addresswrap.commentdiv {
    border-bottom: 0px;
}

.addresswrap p {
    color: #1E3E7B;
    font-weight: 700;
    font-size: 20px;
}

.addresswrap span {
    font-size: 16px;
    font-weight: 500;
    color: #06152B;
    line-height: 28px;
}


/* ==================== */

.table_full_width {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.tabledatawrap {
    width: 100%;
    margin: 15px 0 20px 0;
    border-radius: 10px;
}

.tabledatawrap table {
    width: 100%;
}

.td_center {
    text-align: center;
}

.tabledatawrap table thead {
    width: 100%;
    background: #1b3e7c;
}

.tabledatawrap table thead th {
    padding: 25px 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
    vertical-align: inherit;
}

.tabledatawrap table tbody tr {
    border-top: 15px solid #fff;
    background: #f4f7fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.tabledatawrap table tbody tr:last-child {
    border-bottom: 0px solid #f1f1f1;
}

.tabledatawrap table tbody tr td {
    padding: 25px 10px;
    color: #1b3e7c;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
}

@media (max-width:1440px) {
    .explorewrap.services-pg-wrap.termscondition {
        margin: 100px 40px 0px 40px;
    }
    .dtlsdiv .dtlsheading,
    .dtlsdiv .dtlsbxrow {
        font-size: 16px;
    }
    .addresswrap p {
        font-size: 18px;
    }
    .addresswrap span {
        font-size: 15px;
        line-height: 25px;
    }
}

@media (max-width:1320px) {
    .mysubmittedrequested .cartPage-heading {
        padding: 15px 25px;
    }
    .mysubmittedrequested .cartPage-heading h3 {
        font-size: 20px;
    }
    .dtlsdiv .dtlsheading,
    .dtlsdiv .dtlsbxrow {
        font-size: 15px;
        padding: 15px 22px;
    }
    .addresswrap p {
        font-size: 17px;
        margin-bottom: 5px;
    }
    .addresswrap {
        padding: 25px 30px;
    }
}

@media (max-width:1080px) {
    .explorewrap.services-pg-wrap.termscondition {
        margin: 100px 20px 0px 20px;
    }
    .mysubmittedrequested .cartPage-heading h3 {
        font-size: 18px;
    }
    .mysubmittedrequested .cartPage-heading .common_btn button {
        margin-bottom: 0px;
        margin-top: 0px;
        height: 40px;
        padding: 10px 15px;
        width: auto;
    }
    .mysubmittedrequested .cartPage-heading {
        padding: 15px 20px;
    }
}

@media (max-width:991px) {
    .subitprefenctbtn {
        justify-content: center;
    }
    .addresswrap p {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .dtlsdiv .dtlsheading,
    .dtlsdiv .dtlsbxrow {
        font-size: 14px;
        padding: 15px 18px;
    }
    .addresswrap span {
        font-size: 14px;
    }
    .addresswrap {
        padding: 20px 5px;
    }
    .submiteddetail-wrap {
        display: block;
    }
    .submiteddetail-wrap .dtlsdivbox {
        width: 100%;
        border-radius: 10px 10px 10px 10px;
    }
    .submiteddetail-wrap .addressdiv-left {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .prefrencelist ul {
        display: flex;
        flex-wrap: wrap;
    }
    .prefrencelist ul li {
        width: 50%;
        display: flex;
        align-items: baseline;
    }
    .prefrencelist ul li input {
        margin-right: 5px;
    }
    .prefrencelist ul li label {
        font-size: 15px;
    }
    .logoutpopupwrap .prefrencelist .common_btn button {
        width: 120px;
    }
    .dtlsdiv .dtlsheading,
    .dtlsdiv .dtlsbxrow {
        font-size: 13px;
        padding: 12px 15px;
        width: 100%;
        text-align: left;
    }
    .mysubmittedrequested .cartPage-heading {
        display: block;
    }
    .mysubmittedrequested .cartPage-heading button.prefrencebtn {
        font-size: 14px;
        padding: 8px 12px;
        height: 40px;
    }
    .subitprefenctbtn {
        margin-top: 10px;
    }
    .tabledatawrap {
        width: 100%;
        padding: 0px 0px 20px 0px;
    }
    .mysubmittedrequested .cartPage-heading h3 {
        font-size: 16px;
    }
    .mysubmittedrequested .cartPage-heading .common_btn button {
        padding: 0px 10px;
    }
    .mysubmittedrequested .cartPage-heading {
        padding: 15px 15px;
    }
    .tabledatawrap table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .explorewrap.services-pg-wrap.termscondition {
        margin: 90px 15px 0px 15px;
    }
    .pagination li {
        margin: 0 5px !important;
        font-size: 13px;
    }
}

@media (max-width:410px) {
    .mysubmittedrequested .cartPage-heading .common_btn button {
        padding: 0px 8px;
    }
    .mysubmittedrequested .cartPage-heading h3 {
        font-size: 16px;
        /* width: 150px; */
    }
    .prefrencelist ul {
        display: block;
    }
    .prefrencelist ul li {
        width: 80%;
        display: flex;
        align-items: baseline;
    }
    .logoutpopupwrap .prefrencelist .common_btn {
        width: 100%;
    }
}