/* Changes CSS */

.explorewrap.assets-box-wrap {
    margin-top: 0px;
}

.assetslinkswpap .container {
    max-width: 100% !important;
    padding: 0px 100px !important;
}

.assets-type-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/* .assets-bg-first {
    background: url(../../images/Asset_Config_Bckgd.jpg)no-repeat;
    background-size: cover;
}

.assets-bg-second {
    background: url(../../images/Linked_Assets_Bckgd.jpg)no-repeat;
    background-size: cover;
}

.assets-bg-third {
    background: url(../../images/Geo_Location_Bckgd.jpg)no-repeat;
    background-size: cover;
}

.assets-bg-fourth {
    background: url(../../images/Predictive_Maintenance_Bckgd.jpg)no-repeat;
    background-size: cover;
}

.assets-bg-fifth {
    background: url(../../images/Technical_Support_Bckgd.jpg)no-repeat;
    background-size: cover;
} */

.explorewrap .assetslink-div {
    width: calc(20% - 40px);
    margin: 20px 20px;
    padding: 30px 10px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px #1E3E7B57;
    border-radius: 10px;
}

.explorewrap .assets-icon {
    min-height: 85px;
}

.explorewrap .assets-icon img {
    position: relative;
    left: auto !important;
    top: auto !important;
    right: auto !important;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
}

.assetslink-div .assets-heading {
    font-size: 15px;
    /* line-height: 35px; */
    text-align: center;
    margin: 10px 0px 0px 0px;
    color: #383838;
    font-weight: 700;
    text-transform: capitalize;
}

.assetslink-div .commingtxt {
    color: #6C6C6C;
    font-weight: 600;
    font-size: 8px;
    background-color: #EAEAEA;
    padding: 5px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}


/* Changes CSS */


/* Banner Section CSS Start Here */

.bannersection {
    margin: 72px auto 0px auto;
    background: #f4f7fa;
    padding: 0px 0px;
}

.bannersection .banner-div {
    position: relative;
    /* top: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%; */
    /* height: 500px */
}

.bannersection .banner-div img {
    width: 100%;
}

.bannersection .homebannercontent {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
}

.bannersection .homebannercontent h2,
.bannersection .homebannercontent p {
    font-size: 24px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.bannersection .homebannercontent p {
    padding: 0 100px;
    font-size: 18px;
    line-height: 35px;
    margin-top: 15px;
    font-family: "FiraSans-Regular";
}

.explorewrap .assetslink-div.assets-bg-second {
    cursor: inherit!important;
}

.explorewrap .assetslink-div.assets-bg-third {
    cursor: inherit!important;
}

.explorewrap .assetslink-div.assets-bg-fourth {
    cursor: inherit!important;
}

.banner-div>div {
    width: 100% !important;
}

.banner-div>div>div {
    width: 100% !important;
}

.ondesktop {
    display: block;
}

.onphone {
    display: none;
}


/* Banner Section CSS End Here */


/* Explore Section CSS Start Here */

.explorewrap.assetslinkwrap {
    min-height: 0px !important;
    border: 0px !important;
    background-color: #F0F3F6;
}

.explorewrap.assetslinkwrap ul {
    padding-top: 10px;
    background-color: #f0f3f6 !important;
}

.explorewrap.assetslinkwrap ul li {
    list-style-type: none;
    padding-bottom: 20px !important;
}

.explorewrap {
    position: relative;
    margin-top: 0px;
    background-color: #F0F3F6;
}

.explorewrap .cart-wrap {
    position: relative;
    margin-top: 70px;
    background-color: #F0F3F6;
}

.explorewrap-sec.margin-tp-zero {
    background-color: #f4f7fa;
    margin-top: 0px !important;
    padding: 40px 0px 100px 0px;
    min-height: 0px !important;
    overflow: hidden;
}

.leftrightwrap .imagewrap {
    position: relative;
    text-align: center;
    background-color: #fff;
}

.rightfirst-img .imagewrap {
    padding: 40px 30px 30px 30px;
    border-radius: 58px;
    width: 282px;
    height: 110px;
}

.rightsecond-img .imagewrap {
    padding: 40px 30px 30px 30px;
    border-radius: 58px;
    width: 220px;
    height: 90px;
}

.rightthird-img .imagewrap {
    padding: 40px 30px 30px 30px;
    border-radius: 58px;
    width: 282px;
    height: 105px;
}

.leftrightwrap .rightthird-img .imagewrap p {
    margin-top: 14px;
}

.leftrightwrap .rightsecond-img .imagewrap p {
    margin-top: 0px;
}

.rightfirst-img .imagewrap svg {
    position: absolute;
    top: -74px;
    left: 50%;
    transform: translateX(-50%);
}

.rightsecond-img .imagewrap svg {
    position: absolute;
    top: -110px;
    left: 50%;
    transform: translateX(-50%);
}

.rightthird-img .imagewrap svg {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}


/* .rightfirst-img:hover .imagewrap svg path,
.rightsecond-img:hover .imagewrap svg path,
.rightthird-img:hover .imagewrap svg path {
    fill: #1E3E7B !important;
} */

.image-div .imagewrap p {
    width: 100%;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #1E3E7B;
    margin-bottom: 5px;
    margin-top: 10px !important;
}

.explorewrap-sec .explorecont h2 {
    font-size: 30px;
    color: #1E3E7B;
    font-weight: 700;
}

.explorewrap-sec {
    position: relative;
}

.explorewrap-sec .covr-bgimg {
    position: absolute;
    right: -440px;
    top: -250px;
}

.explorewrap-sec .covr-bgimg img {
    width: 100%;
    height: 100%;
}

.explorewrap.margin-tp-zero .more_btn {
    margin-bottom: 50px;
}

.explorewrap img {
    position: absolute;
    right: 5px;
    top: 0px;
    width: 368px;
    height: 368px;
}

.explorecont {
    padding: 80px 0 0px 0;
}

.explorecont h2 {
    font-size: 35px;
    line-height: 68px;
    font-weight: 600;
}

.explorecont p {
    font-size: 17px;
    color: #000;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 500;
}

.more_btn a h5 {
    color: #20427f;
    font-size: 17px;
    line-height: 27px;
    margin-top: 0px;
    display: block;
    font-weight: 600;
    border-bottom: 2px dashed #20427f;
}

.more_btn a span {
    position: relative;
    display: inline-block;
    top: 12px;
    margin-left: 10px;
}

.more_btn a {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.more_btn a span svg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-top: -28px;
}

.imgprod1,
.imgprod2,
.imgprod3 {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px 10px;
    margin: 10px 0;
    z-index: 10;
    position: relative;
}

.imgprod1 img,
.imgprod2 img,
.imgprod3 img {
    position: relative;
    width: 100%;
    object-fit: scale-down;
}

.imageswrap {
    margin-top: 100px;
}

.imageswrap .imgprod1 {
    width: 152px;
    height: 117px;
    margin-top: -50px;
    float: right;
    overflow: hidden;
}

.imageswrap .imgprod2 {
    width: 267px;
    height: 325px;
    float: right;
    overflow: hidden;
}

.imageswrap .leftrightwrap {
    position: relative;
}

.imageswrap .rightsecond-img {
    position: absolute;
    top: 110px;
    left: 300px;
}

.imageswrap .rightthird-img {
    position: absolute;
    left: -20px;
    bottom: -200px;
}

.imageswrap .imgprod3 {
    width: 216px;
    height: 287px;
    float: right;
}

.imageswrap .leftimgtr {
    max-width: 230px;
}

.imageswrap .rightimgtr {
    max-width: 250px;
    padding-left: 0;
}

.imageswrap .imgprod1 img,
.imageswrap .imgprod2 img,
.imageswrap .imgprod3 img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.imageswrap .imgprod1:hover img,
.imageswrap .imgprod2:hover img,
.imageswrap .imgprod3:hover img,
.assetscardwrap:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/* Explore Section CSS End Here */


/* Assets Link Section CSS Start Here */

.explorewrap.assetslinkswpap .explorecont {
    padding: 0px;
}

.explorewrap.assetslinkswpap {
    background-color: #f4f7fa;
    padding-top: 50px;
    padding-bottom: 30px;
    min-height: 0px;
}

.explorewrap-sec .explorecont {
    padding: 20px 0px 0px 0px;
}

.explorewrap-sec .imageswrap {
    margin-top: 40px;
}

.explorewrap.assetslinkswpap img {
    right: inherit;
    left: 0;
}

.assetscardwrap {
    position: relative;
    padding: 15px 10px;
    background: #ffffff;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    margin-bottom: 10px;
}

.assetscardwrap img {
    position: relative;
    width: 100%;
    height: 180px;
    object-fit: scale-down;
    display: block;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.explorewrap.assetslinkswpap .explorecont h2,
.explorewrap.assetslinkswpap .explorecont p {
    text-align: center !important;
    max-width: 600px !important;
    margin: 0 auto;
}

.explorewrap.assetslinkswpap .explorecont p {
    margin-bottom: 30px;
}

.explorewrap.assetslinkswpap .more_btn {
    float: right;
    margin-bottom: 20px;
}

.assetscardwrap h2 {
    color: #193870;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
}

.assetscardwrap p {
    position: relative;
    color: #1c3e7d;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 120px;
}

.assetscardwrap p a {
    position: absolute;
    right: 0;
    bottom: 4px;
    background-color: #fff;
    padding: 0 2px;
}

.assetscardwrap span {
    color: #7a7a7a;
    display: block;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
}

.assetscardwrap span.prodaddress {
    color: #7a7a7a;
    display: block;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 55px;
}

.content-css {
    min-height: 90px;
}

.content-css span span span span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.content-css>.my-anchor-css-class.showbtn {
    display: inline;
}


/* Assets Link Section CSS End Here */

@media only screen and (max-width: 1600px) {
    .explorecont h2 {
        font-size: 30px;
        line-height: 60px;
    }
}

@media only screen and (max-width: 1440px) {}

@media (max-width:1320px) {
    .bannersection {
        padding: 0px 50px;
    }
    .assetslinkswpap .container {
        padding: 0px 60px !important;
    }
    .explorewrap .assetslink-div {
        width: calc(20% - 24px);
        margin: 12px 12px;
    }
    .explorewrap-sec .explorecont h2 {
        font-size: 25px;
        line-height: 40px;
    }
    .explorecont p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1200px) {
    .imageswrap .imgprod2 {
        width: 220px;
        height: 200px;
    }
    .imageswrap .imgprod3 {
        width: 210px;
        height: 250px;
    }
    .rightfirst-img .imagewrap,
    .rightthird-img .imagewrap {
        padding: 30px 30px 30px 30px;
        border-radius: 36px;
        width: 230px;
        height: 90px;
    }
    .rightfirst-img .imagewrap svg {
        width: 90px;
    }
    .rightthird-img .imagewrap svg {
        width: 100px;
    }
    .image-div .imagewrap p {
        font-size: 14px !important;
        line-height: 27px;
    }
    .imageswrap .rightthird-img {
        left: 0px;
        bottom: -180px;
    }
    .rightsecond-img .imagewrap svg {
        position: absolute;
        top: -90px;
        width: 80px;
    }
    .rightsecond-img .imagewrap {
        padding: 30px 30px 30px 30px;
        border-radius: 36px;
        width: 170px;
        height: 78px;
    }
    .imageswrap .rightsecond-img {
        top: 90px;
        left: 250px;
    }
    .navbar-nav.navmenuwrap .nav-item.svgicon {
        margin-top: -12px;
    }
    .navbar-nav.navmenuwrap .nav-item.svgicon svg {
        width: 35px;
    }
}

@media only screen and (max-width:1080px) {
    .assetslinkswpap .container {
        padding: 0px 40px !important;
    }
    .bannersection {
        padding: 0px 15px;
    }
    .assetslink-div .assets-heading {
        font-size: 14px;
    }
    .explorewrap .assetslink-div {
        width: calc(20% - 20px);
        margin: 10px 10px;
    }
    .bannersection .homebannercontent h2,
    .bannersection .homebannercontent p {
        font-size: 20px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 991px) {
    .bannersection.homebanner {
        display: block!important;
        padding: 0px 0px 10px 0px !important;
        min-height: 100%;
        margin: 60px auto 0px auto;
    }
    .explorewrap .assetslink-div {
        width: calc(50% - 40px);
        padding: 25px;
    }
    .assetslink-div .assets-heading {
        font-size: 14px;
        line-height: 30px;
    }
    .explorecont h2 {
        margin-bottom: 20px;
    }
    .setting_wrap ul li a {
        top: 1px;
    }
    .assetscardwrap p {
        font-size: 14px;
        line-height: 30px;
    }
    .imageswrap .imgprod2 {
        width: 190px;
        height: 200px;
    }
    .imageswrap .imgprod3 {
        width: 170px;
        height: 199px;
    }
    .explorecont p {
        font-size: 15px;
        padding-right: 5px;
        line-height: 30px;
    }
    .rightfirst-img .imagewrap,
    .rightthird-img .imagewrap {
        padding: 20px;
        width: 210px;
        height: 84px;
    }
    .rightfirst-img .imagewrap svg {
        width: 75px;
        top: -70px;
    }
    .rightthird-img .imagewrap svg {
        width: 90px;
        top: -50px;
    }
    .rightsecond-img .imagewrap {
        padding: 20px;
        width: 150px;
        height: 70px;
    }
    .imageswrap .rightsecond-img {
        top: 80px;
        left: 210px;
    }
    .rightsecond-img .imagewrap svg {
        width: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .ondesktop {
        display: none;
    }
    .onphone {
        display: block;
    }
    .explorewrap-sec.margin-tp-zero {
        padding: 0px 0px 50px 0px;
    }
    .imageswrap {
        display: none;
    }
    .explorewrap .assetslink-div {
        width: calc(50% - 30px);
        padding: 25px 15px;
        margin: 15px 10px;
    }
    .assetslink-div .assets-heading {
        /* font-size: 18px; */
        line-height: 25px;
        margin: 10px 0px 0px 0px;
    }
    .assets-type-wrap {
        margin-left: -15px;
        margin-right: -15px;
    }
    .assetslink-div .commingtxt {
        font-size: 12px;
    }
    .explorewrap {
        margin-top: 70px;
    }
    .bannersection {
        height: auto;
        background-size: auto 100%;
        padding: 70px 16px 20px 16px !important;
        padding: 20px 16px 35px 16px !important;
    }
    .bannersection .homebannercontent {
        padding: 0 10px;
        bottom: -70px;
    }
    .bannersection .homebannercontent h2 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
    }
    .bannersection .homebannercontent p {
        padding: 0 0px;
        font-size: 14px;
        line-height: 25px;
        margin-top: 10px;
        padding-bottom: 20px;
    }
    .explorecont {
        padding: 20px 0 0px 0;
    }
    .explorecont h2 {
        font-size: 22px;
        line-height: 48px;
    }
    .explorecont p {
        font-size: 14px;
        line-height: 26px;
    }
    .imageswrap .imgprod2 {
        width: auto;
        height: auto;
        float: none;
    }
    .imageswrap .leftrightwrap {
        float: none;
    }
    .imageswrap .leftimgtr {
        max-width: initial;
    }
    .imageswrap {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .imageswrap .rightimgtr {
        max-width: 100%;
        padding-left: 15px;
    }
    .imageswrap .imgprod3 {
        float: none;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }
    .imageswrap .imgprod1 {
        margin: 0 auto;
        float: none;
        width: 100%;
        height: 100%;
    }
    .more_btn a span svg {
        width: 30px;
        height: 30px;
    }
    .more_btn a span {
        top: 12px;
    }
    .more_btn a {
        font-size: 16px;
        line-height: 4px;
    }
    .explorewrap.assetslinkswpap {
        padding-top: 10px;
    }
}

@media (max-width:460px) {
    .explorewrap .assetslink-div {
        width: 100%;
        padding: 25px 15px;
        margin: 10px 20px;
    }
}