.assetslinkwrap .assetscardwrap h2 {
    color: #193870;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
}

.explorewrap.assetslinkwrap .pro-img img {
    position: relative;
    right: auto;
    top: auto;
    width: 100px;
    height: 80px;
}

.assetslinkwrap .assetscardwrap p {
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 28px;
}

.assetslinkwrap .assetscardwrap span.prodaddress,
.assetscardwrap span {
    font-weight: 500;
    max-width: 70%;
}

.assetslinkwrap .tabledatawrap table tbody tr td .action-text a svg {
    width: 20px;
}

@media (max-width:1320px) {
    .explorewrap.assetslinkwrap .pro-img img {
        width: 80px;
        height: 60px;
    }
}

@media only screen and (max-width: 991px) {
    .explorewrap.assetslinkwrap {
        height: 100%;
    }

    .explorewrap.assetslinkwrap .pagination {
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .explorewrap.assetslinkwrap .pro-img img {
        width: 60px;
        height: 40px;
    }

    .explorewrap.assetslinkwrap ul li {
        padding-bottom: 0px;
        margin: 20px 0px 10px 0px;
        height: auto;
    }

    .explorewrap.assetslinkwrap ul li a .assetscardwrap .productimgcard {
        position: relative;
        left: inherit;
        top: inherit;
        width: inherit;
        max-width: 320px;
        height: inherit;
        min-height: 250px;
        margin-bottom: 20px;
    }

    .explorewrap.assetslinkwrap ul li a .assetscardwrap {
        box-shadow: none;
        position: relative;
        padding-left: 0px;
        background-color: #fff;
    }
}