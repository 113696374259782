@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  background-color: #fff;
  height: auto;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.csv-picker {
  /* display: none; */
  position: fixed;
  top: 6rem;
  left: 2px;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  right: 0;
  left: 0;
  margin: auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid #1b3c78;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1b3c78 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.intl-tel-input,
.country-list {
  width: 100%;
}

.country-list .country {
  margin-bottom: unset !important;
}

.intl-tel-input-custom {
  height: 50px !important;
}

.selected-flag :focus-visible {
  border-color: #bac2c7;
}

.disabled {
  color: #dcdcdc !important;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.no-edit {
  pointer-events: none;
  background-color: #f9f9f9; /* or your desired color */
}