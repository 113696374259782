.noficationwrap {
    position: relative;
}

.notification-page-wrap .container {
    padding: 0px 80px!important;
}

.noficationwrap .page-heading {
    color: #1E3E7B;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}

.notification-page-wrap .noficationwrap {
    margin: 20px 0px 30px 0px;
    background: #FFFFFF;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 30px 30px;
}

.noficationlist .notificationlistdiv {
    display: flex;
    justify-content: space-between;
}

.notificationlistdiv .noticontent {
    display: flex;
    align-items: center;
}

.noticontent .notiIcon img {
    width: 40px;
}

.noficationwrap ul li span.notitime {
    margin-left: 5px;
}

.noficationwrap ul li span.notificationType {
    background-color: #e3edff;
    padding: 10px;
    border-radius: 6px;
    margin-right: 25px;
}

.notificationlistdiv .noticontent .noticont {
    margin-left: 40px;
}

.notificationlistdiv .noti-delet .delete {
    cursor: pointer;
}

.noficationwrap ul li {
    position: relative;
    padding: 20px 15px 25px 15px;
    border-bottom: 2px dashed #e0e0e0;
}

.noficationwrap ul li img {
    /* position: absolute !important;
    top: 30px;
    left: 20px;
    width: 44px;
    height: 44px;
    overflow: hidden; */
}

.noficationwrap ul li p {
    font-size: 18px;
    color: #1E3E7B;
    line-height: 28px;
    font-weight: 600;
    padding-bottom: 0 !important;
    margin-bottom: 5px;
}

.noficationwrap ul li span {
    font-size: 14px;
    color: #6C6C6C;
    font-weight: 400;
}

.pagination {
    border: 0;
}

.cart-wrap {
    position: relative;
}

.cart-wrap .noficationwrap ul li img {
    position: absolute !important;
    border-radius: 4px;
    width: 100px;
    height: 80px;
}

.cart-wrap .noficationwrap ul li p {
    font-size: 20px;
}

.cart-wrap .noficationwrap ul li {
    padding: 20px 15px 25px 15px;
}

.cart-wrap .cart-part-wrap,
.cart-wrap label {
    font-size: 16px;
    margin-bottom: 0;
}

.cart-wrap .setting_wrap ul {
    margin-bottom: 0px;
}


/* .cart-wrap .setting_wrap ul {
    min-height: 350px;
} */

.cart-wrap .common_btn {
    width: 35%;
    margin: 0 auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-wrap .delete {
    top: 10px;
    right: 15px;
}

.notification-page-wrap {
    margin-bottom: 20px;
}

.noficationwrap ul li:last-child {
    border: 0px;
}

@media(max-width:1460px) {
    .notification-page-wrap .container {
        padding: 0px 60px!important;
    }
    .noficationwrap .page-heading {
        font-size: 21px;
        margin-bottom: 20px;
    }
    .notificationlistdiv .noticontent .noticont {
        margin-left: 35px;
    }
}

@media (max-width:1320px) {
    .noticontent .notiIcon img {
        width: 35px;
    }
    .notificationlistdiv .noticontent .noticont {
        margin-left: 30px;
    }
    .noficationwrap ul li p {
        font-size: 17px;
    }
    .noficationwrap ul li span {
        font-size: 13px;
    }
    .notification-page-wrap .noficationwrap {
        padding: 25px 25px
    }
    .notification-page-wrap .container {
        padding: 0px 40px!important;
    }
    .noficationwrap .page-heading {
        font-size: 19px;
        margin-bottom: 16px;
    }
}

@media (max-width:1080px) {
    .notification-page-wrap .container {
        padding: 0px 20px!important;
    }
    .notification-page-wrap .noficationwrap {
        padding: 20px 20px;
    }
    .noficationwrap ul li p {
        font-size: 16px;
    }
    .noticontent .notiIcon img {
        width: 30px;
    }
    .notificationlistdiv .noticontent .noticont {
        margin-left: 25px;
    }
}

@media only screen and (max-width: 991px) {
    .cart-wrap .noficationwrap ul li {
        padding: 20px 10px 20px 10px;
    }
    .cart-wrap .noficationwrap ul li img {
        position: relative !important;
        top: inherit;
        left: inherit;
        text-align: center;
        margin: 0 auto;
        display: table;
        margin-bottom: 20px;
    }
}

@media (max-width:767px) {
    .notificationlistdiv .noticontent .noticont {
        margin-left: 15px;
        width: 80%;
    }
    .notification-page-wrap .noficationwrap {
        padding: 15px 15px;
        margin: 90px 0px 20px 0px;
    }
    .noficationwrap .page-heading {
        font-size: 17px;
        margin-bottom: 12px;
    }
    .noticontent .notiIcon img {
        width: 26px;
    }
    .noficationwrap ul li p {
        font-size: 16px;
        margin-bottom: 0px;
    }
    .noficationwrap ul li span {
        font-size: 12px;
    }
    .noficationlist .notificationlistdiv {
        position: relative;
        display: block;
    }
    .noti-delet {
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .noficationwrap ul li {
        border-bottom: 1px dashed #1e3e7b;
        padding: 15px 10px 20px 10px;
    }
}

@media (max-width:460px) {
    .noficationwrap ul li p {
        font-size: 15px;
        line-height: 24px;
    }
}