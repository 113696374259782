/* My Profile Screen CSS Start here */

.user-profile {
    position: relative;
}

.address-user {
    padding: 30px 10px;
}

.address-user ul li {
    display: flex;
    align-items: center;
}

.address-user span {
    color: #1E3E7B;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
}

.prodfile_wrap .row {
    align-items: center;
}

.mypforiletabs .addressaction a svg {
    width: 30px!important;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li {
    position: relative;
    padding: 15px 25px;
    width: calc(33.33% - 30px);
    margin: 0px 15px 15px 0px;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addressaction {
    position: absolute;
    right: 25px;
    top: auto;
    bottom: 20px;
    text-align: right;
}

.profilecard .contacticon img {
    position: relative;
    margin-right: 20px;
    right: auto!important;
    top: auto!important;
    width: auto!important;
    height: auto!important;
}

.prodfile_wrap .profilecard a {
    color: #1E3E7B;
    font-size: 16px;
    font-weight: 500;
}

.profile-page .container {
    padding: 0px 80px!important;
}

.profile-page .cartPage-heading {
    margin: 20px 0px 0px 0px;
    display: block;
}

.mypforiletabs {
    background: #FFFFFF;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    margin: 15px 0px;
}

.profile-page .prodfile_wrap {
    padding: 40px 0px 10px 0px;
}

.profile-page .prodfile_wrap .userimagewrap {
    display: flex;
    align-items: center;
}

.explorecont.mypforiletabs {
    padding-top: 20px;
}

.myprofilewraptab {
    margin-top: -15px;
}

.myprofilewraptab #tile-1 .tab-pane {
    padding: 15px 0;
}

.myprofilewraptab #tile-1 .nav-tabs {
    position: relative;
    border: none !important;
    width: 618px;
    height: 65px;
    margin: 0 auto;
    background-color: #1b3c78 !important;
    border-radius: 70px !important;
    overflow: hidden;
}

.myprofilewraptab #tile-1 .nav-tabs li {
    margin: 0px !important;
    background: #f4f7fa;
    border-radius: 0px;
    padding: 2px 0px;
    margin-bottom: 35px;
    height: 58px;
    box-shadow: none;
    background-color: #1b3c78 !important;
    border-radius: 70px !important;
}

.myprofilewraptab #tile-1 .nav-tabs li a {
    position: relative;
    margin-right: 0px !important;
    padding: 0px 40px !important;
    font-size: 23px;
    line-height: 50px;
    border: none !important;
    color: #fff;
    z-index: 2;
    padding: 3px 40px;
}

.myprofilewraptab #tile-1 .slider {
    width: 303px !important;
    display: inline-block;
    height: 57px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    bottom: 4px;
    margin-left: 3px;
    transition: all 0.4s linear;
    border-radius: 70px !important;
}

.mypforiletabs .myprofilewraptab #tile-1 .nav-tabs .active {
    font-family: "FiraSans-Bold";
    background-color: transparent !important;
    border: none !important;
    color: #1b3c78 !important;
    padding: 6px 40px;
}


/* .userimagewrap {
    margin: 0 auto;
    margin-top: 60px;
    display: table;
} */

.userimagewrap .userimage {
    position: relative;
    width: 80px;
    height: 80px;
    /* overflow: hidden; */
    border-radius: 100%;
    margin-right: 15px;
}

.userimagewrap .userimage .staruser {
    position: absolute;
    left: 30px;
    bottom: -11px;
}

.userimagewrap .userimage .staruser svg {
    width: 18px;
    color: #d5a002!important;
}

.setting_wrap.profilecard ul {
    margin: 0px!important;
}

.setting_wrap.profilecard ul li {
    background: #fff;
    box-shadow: 0px 0px 0px #e4afaf29;
    border-radius: 0px;
    padding: 0px;
    margin-bottom: 0px;
}

.setting_wrap.addresscontwrap {
    padding: 20px 40px;
}

.addresscontwrap .savedaddress-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.savedaddress-detail h3 {
    font-size: 25px;
    font-weight: bold;
    color: #193870;
    margin-bottom: 0px;
}

.savedaddress-detail a {
    font-size: 16px!important;
    font-weight: 500!important;
    background-color: #1E3E7B;
    color: #fff;
    padding: 11px;
    border-radius: 4px
}

.userimagewrap p {
    font-weight: 600!important;
    margin-top: 0px!important;
}

.userimagewrap .userimage img {
    position: relative;
    right: inherit;
    top: inherit;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    padding: 5px;
    box-shadow: 0px 9.03012px 27.0904px rgb(191 203 203 / 32%), 0px 3.38629px 5.64383px rgb(223 226 227 / 32%);
    border: 2px dashed#d5a002;
    object-fit: contain;
}

.userimagewrap p {
    font-size: 20px !important;
    text-align: center;
    color: #1c3e7d !important;
    margin-top: 15px;
    font-weight: 500;
    margin-bottom: 0;
}

.myprofilewraptab .tab-content {
    min-height: 0px;
}


/* My Profile Screen CSS End here */


/* Address Screen CSS End here */

.setting_wrap.addresscontwrap {
    position: relative;
}

.setting_wrap.addresscontwrap ul li {
    position: relative;
    padding: 20px 200px 15px 125px;
    display: block;
    min-height: 170px;
}

.setting_wrap.addresscontwrap ul li span {
    position: absolute;
    left: 30px;
    top: 20px;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont {
    margin-top: 5px;
    width: 100%;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont label {
    float: right;
    text-align: right;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont h2 {
    font-size: 19px;
    color: #193870;
    line-height: 30px;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont p {
    font-size: 15px;
    color: #7a7a7a;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont label {
    font-size: 14px;
    font-weight: bold;
    color: #a5beeb;
}

.setting_wrap.addresscontwrap ul li .addressaction {
    position: absolute;
    right: 30px;
    top: 50px;
}

.mypforiletabs .setting_wrap.addresscontwrap ul li .addressaction a {
    display: inline-block !important;
    top: -5px;
}

.setting_wrap.addresscontwrap ul li .addressaction a svg {
    padding-left: 10px;
}

.setting_wrap.addresscontwrap ul {
    margin-bottom: 20px;
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
}

.setting_wrap.addresscontwrap .common_btn {
    width: 407px;
    font-size: 16px;
    font-family: "FiraSans-Bold";
    margin: 0 auto;
    display: table;
}

.addresscontwrap img {
    height: 60px !important;
    width: 60px !important;
}

.modal-dialog.custom_modal_wrap.new-address-modal .modal-content {
    /* width: 848px !important; */
    margin-bottom: 0px;
}

.modal-open .modal.modal-center .modal-dialog.custom_modal_wrap.new-address-modal {
    top: inherit;
    left: inherit;
    position: relative;
    transform: inherit;
    background-color: transparent;
    padding: 0 15px;
    padding-bottom: 50px;
}

.modal-dialog.custom_modal_wrap.new-address-modal .close {
    right: -5px;
    top: -5px;
}

.new-address-modal .logoutpopupwrap .common_btn button {
    margin: 0 auto;
    display: table;
}

.setting_wrap.addresscontwrap ul li .addressaction a svg#edit {
    fill: #1e3e7b;
}


/* Address Screen CSS End here */

@media (max-width:1600px) {
    .logoutpopupwrap .common_input_warp .form-group .form-control {
        height: 45px;
    }
    .logoutpopupwrap .common_input_warp .form-group {
        margin-bottom: 20px;
    }
}

@media (max-width:1480px) {
    .intl-tel-input-custom {
        height: 40px !important;
    }
    .logoutpopupwrap .common_input_warp .form-group .form-control {
        height: 40px;
    }
}

@media only screen and (max-width: 1380px) {
    .profile-page .cartPage-heading {
        padding: 30px 30px 20px 30px;
    }
    .profile-page .prodfile_wrap {
        padding: 20px 0px 10px 0px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont h2 {
        font-size: 17px;
        line-height: 25px;
    }
}

@media only screen and (max-width: 1080px) {
    .setting_wrap.addresscontwrap ul li .addressaction a svg,
    .setting_wrap.addresscontwrap ul li span svg,
    .setting_wrap ul li a span svg {
        width: 60px;
    }
}

@media only screen and (max-width: 991px) {
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont h2 {
        font-size: 16px;
    }
    .modal-dialog.custom_modal_wrap.new-address-modal .modal-content {
        width: 100% !important;
        margin-bottom: 30px;
    }
    .new-address-modal .logoutpopupwrap .common_btn button {
        width: 170px
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li {
        width: 50%;
        margin-right: 0;
    }
    .setting_wrap.profilecard {
        margin: 20px 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .userimagewrap .userimage .staruser {
        left: 50%;
        bottom: -11px;
        transform: translateX(-50%);
    }
    .address-user {
        padding: 15px 0px;
    }
    .address-user ul li {
        align-items: flex-start;
    }
    .address-user span {
        margin-left: 0px;
    }
    .address-user span {
        font-size: 14px;
    }
    .new-address-modal .logoutpopupwrap .common_btn button {
        width: auto;
        min-height: 40px;
        font-size: 14px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont h2 {
        font-size: 15px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li {
        width: 100%;
        margin-right: 0;
    }
    .profile-page .prodfile_wrap .userimagewrap {
        flex-direction: column;
    }
    .profile-page .container {
        padding: 0px 15px !important;
    }
    .myprofilewraptab #tile-1 .nav-tabs .active {
        padding: 2px 20px !important;
    }
    .mypforiletabs .myprofilewraptab #tile-1 .nav-tabs li a {
        line-height: 50px;
        font-size: 14px;
        padding: 3px 20px !important;
    }
    .explorecont p {
        font-size: 14px !important;
    }
    .myprofilewraptab {
        margin-top: 30px;
    }
    .myprofilewraptab #tile-1 .nav-tabs {
        width: 290px;
        height: 52px;
    }
    .myprofilewraptab #tile-1 .nav-tabs li {
        padding: 0px 0px;
    }
    .myprofilewraptab #tile-1 .slider {
        width: 139px !important;
        height: 44px;
        bottom: 4px;
    }
    .mypforiletabs .setting_wrap ul li a span svg {
        top: -1px;
        margin-right: 15px;
        width: 40px;
    }
    .setting_wrap ul li {
        padding: 5px 10px 5px 10px;
    }
    .setting_wrap ul li a {
        font-size: 16px;
    }
    .userimagewrap {
        margin-top: 20px;
    }
    .userimagewrap .userimage {
        width: 120px;
        height: 120px;
        margin-right: 0;
    }
    .setting_wrap.addresscontwrap ul li .addressaction a svg,
    .setting_wrap.addresscontwrap ul li span svg {
        width: 50px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul {
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li {
        padding: 20px 15px 15px 15px;
        margin-bottom: 15px;
        height: auto;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont label {
        font-size: 16px;
        float: none;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addressaction {
        right: 10px;
        bottom: 10px;
        left: auto;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addressaction svg {
        width: 26px!important;
        margin-top: -4px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li span {
        position: absolute;
        left: 15px;
        top: 10px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap .common_btn {
        width: 100%;
    }
    .myprofilewraptab #tile-1 .tab-pane {
        padding: 15px 0;
    }
    .explorecont.mypforiletabs h2 {
        font-size: 22px;
        line-height: 20px;
        margin-bottom: 5px;
    }
    .profile-page .cartPage-heading {
        margin: 80px 0px 0px 0px;
        display: block;
        padding: 20px;
    }
    .profile-page .prodfile_wrap {
        padding: 0 0px 10px 0px;
    }
    .setting_wrap.addresscontwrap {
        padding: 20px 20px;
    }
    .savedaddress-detail h3 {
        font-size: 18px;
    }
    .profilecard .contacticon img {
        margin-right: 10px;
        width: 20px!important;
    }
    .savedaddress-detail a {
        font-size: 12px !important;
    }
    .prodfile_wrap .profilecard a {
        font-size: 14px;
    }
    .logoutpopupwrap h2 {
        font-size: 20px !important;
        line-height: 36px;
        margin-top: 10px;
    }
    .addaddresswrap .common_input_warp .form-group,
    .react-tel-input {
        margin-bottom: 15px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont p {
        font-size: 14px!important;
    }
    .mypforiletabs .customimputcheckbox .form-group input:checked+label:after {
        top: 4px;
    }
}

@media (max-width:460px) {
    .userimagewrap .userimage {
        width: 90px;
        height: 90px;
    }
    .setting_wrap.addresscontwrap {
        padding: 10px 10px;
    }
    .savedaddress-detail h3 {
        font-size: 16px;
    }
    .savedaddress-detail a {
        font-size: 12px !important;
        padding: 8px;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li {
        padding: 5px 10px 10px 10px;
        margin-bottom: 15px;
        height: auto;
    }
    .mypforiletabs .setting_wrap.addresscontwrap ul li .addresscont p {
        font-size: 13px!important;
    }
}