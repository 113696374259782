.detail-assets-lt .maintenancediv-wrap .maintenance-info .info-content p {
    margin: 0px 0px 0px 5px;
    font-size: 18px;
    line-height: 30px;
}

.detail-assets-lt .partnumberdiv .partdiv p {
    margin: 0px 0px 0px 5px;
}

.detail-assets-lt .monthwise-list .month-date {
    color: #193870;
    font-size: 18px;
    font-weight: 700;
    line-height: 35px;
    display: flex;
    align-items: center;
    margin-left: 2px;
    margin-bottom: 10px;
}

.detail-assets-lt .maintenance-month-list .maintenancediv-wrap {
    margin-bottom: 10px;
}

.detail-assets-lt .maintenance-alerts-wrap {
    margin: 30px auto 0px auto;
    padding: 20px 80px 30px 80px;
}

.detail-assets-lt .maintenance-alerts-wrap p.sm-heading {
    font-size: 18px;
}


/* =============== */

.magnify-Image {
    display: flex;
}

.magnify-Image .image-to-magnify {
    margin-right: 20px;
    border: 2px solid #E1F0FF;
    border-radius: 4px;
}

.tabs-content-wrap {
    background: #FFF;
    border: 2px solid #E1F0FF;
    border-radius: 22px;
    max-width: 95%;
    margin: 30px auto;
    padding: 0px 10px 30px 10px;
}

.pdfdownloadbtn .flex-div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pdfdownloadbtn .flex-div button {
    font-size: 14px;
    width: calc(33.33% - 20px);
    margin: 0px 20px 20px 0px !important;
    border-radius: 8px;
    margin-top: 20px;
    color: #fff;
    background-color: #1c3e7d;
    border: 1px solid #1c3e7d;
    font-weight: 500;
}

.image-gallery-thumbnail {
    display: block !important;
    margin-bottom: 5px !important;
}


/* .pdfdownloadbtn .flex-div button:nth-child(2) {
        color: #1c3e7d;
        background-color: #fff;
        border: 1px solid #E1F0FF;
    }
    
    .pdfdownloadbtn .flex-div button:nth-child(3) {
        color: #1c3e7d;
        background-color: #E1F0FF;
        border: 1px solid #E1F0FF;
    } */

.pdfdownloadbtn .common_btn {
    width: 100% !important;
}

.maintenance-alerts-wrap {
    background: #FFF;
    border: 2px solid #E1F0FF;
    border-radius: 22px;
    max-width: 95%;
    margin: 30px auto 0px auto;
    padding: 40px 100px 30px 100px;
}

.maintenance-alerts-wrap p.sm-heading {
    font-size: 16px;
    color: #193870;
    font-weight: 700;
}

.tabs-content-wrap .container {
    padding: 0 70px !important;
}


/* .maintenance-month-list {
    display: flex;
    flex-wrap: wrap;
} */

.maintenance-month-list .blue-circle {
    width: 7%;
}

.maintenance-month-list .monthwise-list {
    width: 100%;
}

.maintenance-month-list .maintenancediv-wrap {
    position: relative;
    width: 100%;
    background: #fef5eb;
    border: 1px solid #E1F0FF;
    border-radius: 9px;
    padding: 20px;
    margin-bottom: 25px;
}

.statusline.greenline ul li:after {
    border: 1px dashed #d6fbdd;
    height: calc(50vh - 55px);
}

.maintenance-info .partnumberdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}

.partnumberdiv .partdiv,
.info-content .runingdiv {
    background-color: transparent;
    display: flex;
    align-items: center;
}

.info-content .runingdiv {
    background-color: transparent;
    display: flex;
    align-items: center;
}

.faqwrap.maintancealerts .partnumberdiv .partdiv p,
.faqwrap.maintancealerts .info-content .runingdiv p {
    top: auto;
    color: #193870;
    font-weight: 600;
    margin: 0px 10px;
    padding: 0px !important;
    line-height: 28px;
}

.partnumberdiv .markdiv {
    color: #193870;
    background-color: #eae5e0;
    padding: 12px 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}

.maintenance-content-box ul {
    margin-top: 0px !important;
}

.mysubmittedrequested .statusline {
    margin-right: 45px;
}

.maintenancediv-wrap .info-content p {
    padding: 0 20px 0px 0px !important;
}

.maintenance-info .info-content p {
    font-size: 23px;
    font-weight: 600;
    color: #193870;
    line-height: 40px;
}


/*     
    .info-content .runingdiv {
        display: inline-block;
        color: #193870;
        background-color: #e9f1f9;
    }
     */

.info-content .maintenance-number {
    font-size: 18px;
    font-weight: 600;
    color: #193870;
    margin-top: 15px;
    line-height: 40px;
}

.partnumberdiv .markdiv {
    cursor: pointer;
    color: #fff;
    background-color: #193870;
}

.monthwise-list .month-date {
    color: #193870;
    font-size: 22px;
    font-weight: 700;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.monthwise-list .month-date img {
    width: 26px;
    margin-right: 10px;
    height: auto;
}

.maintenancepoint-list {
    border-top: 2px solid #f6eee5;
    margin-top: 20px;
    padding-top: 10px;
    max-width: 70%;
}

.maintenancepoint-list ul ol {
    font-size: 16px;
    color: #4D4D4D;
    font-weight: 400;
}

.maintenance-month-list .maintenancediv-wrap.blue-bg {
    background-color: #edfdf1;
    border: 1px solid #E1F0FF;
}

.maintenancediv-wrap.blue-bg .maintenance-info .info-content {
    border-bottom: 2px solid #e8f7ec;
    padding-bottom: 10px;
}

.circlediv {
    display: block;
    width: 25px;
    height: 25px;
    background: #193870;
    border-radius: 100%;
    margin-top: 13px;
    margin-right: 50px;
}

.maintenance-content-box ul li {
    display: flex;
    box-shadow: 0px 0px 0px 0px;
    margin-bottom: 0px;
}

.statusline {
    margin-right: 75px;
}

.maintenancediv-wrap.blue-bg .partnumberdiv .partdiv {
    background-color: #dbece6;
}

.statusline ul li {
    position: relative;
}

.statusline ul li:after {
    content: "";
    position: absolute;
    left: 12px;
    border: 1px dashed #fff5de;
    height: 50vh;
}


/* =============== */


/* Common CSS Start here */

.maincontentwrap {
    margin-top: 0px;
    padding: 0px 40px;
    background-color: #F0F3F6;
}

.myprofilewraptab.asstesdetailswrap .tab-content {
    margin-top: 30px;
}

.magnify-Image .image-gallery-image {
    display: none !important;
}

.magnify-Image {
    padding-right: 50px;
}

.assetsdetailsitm p b {
    font-weight: 500;
}

.maintinancetable h2,
.zoomercontent h2 {
    font-weight: 600;
}

.image-to-magnify {
    /* -webkit-box-shadow: 3px 2px 23px 8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 2px 23px 8px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 2px 23px 8px rgba(0, 0, 0, 0.75); */
    width: 100%;
    height: auto;
    border: 0px solid #000;
}

/* .image-gallery {
    max-height: 330px !important;
} */

/* .image-to-magnify img {
  max-height: 330px !important;
} */

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    width: 100px;
    height: 100px;
    background: #ffffff 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #00000029; */
    overflow: hidden;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner img {
    padding: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
    max-height: 340px !important;
    overflow-y: auto !important;
}

.image-gallery-thumbnail {
    width: auto;
    margin: 0 2px;
    border: 2px solid transparent;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active:hover {
    border: 2px solid #E1F0FF;
}

.myprofilewraptab {
    margin-top: -15px;
}

.myprofilewraptab #tile-1 .tab-pane {
    padding: 0px 0;
}

.myprofilewraptab #tile-1 .nav-tabs {
    position: relative;
    border: none !important;
    width: 618px;
    height: 65px;
    margin: 0 auto;
    background-color: #1b3c78 !important;
    border-radius: 70px !important;
    overflow: hidden;
}

.myprofilewraptab #tile-1 .nav-tabs li {
    margin: 0px !important;
    background: #f4f7fa;
    border-radius: 0px;
    padding: 2px 0px;
    margin-bottom: 35px;
    height: 58px;
    box-shadow: none;
    background-color: #1b3c78 !important;
    border-radius: 70px !important;
}

.myprofilewraptab #tile-1 .nav-tabs li a {
    position: relative;
    margin-right: 0px !important;
    padding: 0px 40px !important;
    font-size: 23px;
    line-height: 50px;
    border: none !important;
    color: #fff;
    z-index: 2;
    padding: 5px 40px !important;
}

.myprofilewraptab #tile-1 .slider {
    width: 303px !important;
    display: inline-block;
    height: 57px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    bottom: 4px;
    margin-left: 3px;
    transition: all 0.4s linear;
    border-radius: 70px !important;
}

.myprofilewraptab #tile-1 .nav-tabs .active {
    font-family: "FiraSans-Bold";
    background-color: transparent !important;
    border: none !important;
    color: #1b3c78 !important;
    padding: 5px 40px !important;
}

.productzoomwrap {
    margin: 0px auto 150px auto;
}

.maintinancetable h2,
.zoomercontent h2 {
    font-size: 30px;
}

.zoomercontent p {
    font-size: 16px;
}

.riaseticket {
    margin: 0 auto;
    margin-top: 40px;
}

.common_btn.riaseticket.reqestbtn {
    width: 200px;
    margin: 15px auto;
}

.pdfdownloadbtn .common_btn {
    width: 350px;
}

.pdfdownloadbtn .common_btn button {
    margin: 0;
    margin-bottom: 20px;
}

.serial-no-dropdown {
    margin-bottom: 20px;
    /* margin-right: 20px;
    float: right;
    margin-top: 30px; */
}

.serial-no-dropdown label {
    display: inline-block;
    font-size: 16px;
    margin-right: 5px;
}

.serial-no-dropdown>select.form-control {
    width: 285px;
    border-radius: 10px;
    border: 1px solid #E1F0FF;
    display: inline-block;
    /* background-size: 18px 18px;
    background-position: center right;
    background-color: #fff;
    background-repeat: no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; */
}

.serial-no-dropdown select.form-control:focus {
    box-shadow: none;
}

.clearfix {
    content: "";
    display: table;
    clear: both;
}

.prduct-details-table {
    height: 400px;
    overflow-y: scroll;
    margin-top: 20px !important;
}


/* Changes CSS */

.serial-no-box {
    margin-top: 10px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.zoomercontent .assets-items-box p {
    margin-bottom: 0px;
}

.pdfdownloadbtn {
    margin-top: 40px;
}

.zoomercontent {
    padding-top: 10px;
}

.ondesktop {
    display: block;
}

.onphone {
    display: none !important;
}


/* Changes CSS */

@media only screen and (max-width: 1600px) {
    .magnify-Image {
        padding-right: 30px;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
        width: 100px;
        height: 80px;
    }

    .maintinancetable h2,
    .zoomercontent h2 {
        font-size: 24px;
    }
}

@media (max-width: 1440px) {
    .maintenance-info .info-content p {
        font-size: 21px;
    }

    .monthwise-list .month-date {
        font-size: 16px;
    }

    .monthwise-list .month-date img {
        width: 18px;
    }

    .maintaincardimg p {
        font-size: 16px;
    }

    .maintaincardimg span {
        font-size: 15px;
    }

    .circlediv {
        width: 20px;
        height: 20px;
    }

    .maintenance-alerts-wrap {
        padding: 40px 70px 30px 70px;
    }

    .info-content .maintenance-number {
        font-size: 18px;
        margin-top: 0;
    }

    .maintenancepoint-list ul ol {
        font-size: 15px;
    }

    .zoomercontent p {
        font-size: 15px;
    }

    .myprofilewraptab #tile-1 .nav-tabs li a {
        font-size: 20px;
    }

    .maintinancetable h2,
    .zoomercontent h2 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1460px) {
    .detail-assets-lt .maintenance-alerts-wrap {
        margin: 20px auto 0px auto;
        padding: 20px 50px 20px 50px;
    }

    .detail-assets-lt .monthwise-list .month-date,
    .detail-assets-lt .maintenance-alerts-wrap p.sm-heading,
    .detail-assets-lt .maintenancediv-wrap .maintenance-info .info-content p {
        font-size: 16px;
    }

    .detail-assets-lt .partnumberdiv .partdiv p {
        font-size: 16px;
    }

    .pdfdownloadbtn .flex-div button {
        font-size: 14px;
        width: calc(50% - 20px);
    }

    .tabs-content-wrap .container {
        padding: 0 50px !important;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
        width: 80px;
        height: 60px;
    }
}

@media only screen and (max-width: 1320px) {
    .maincontentwrap {
        padding: 0px;
    }

    .detail-assets-lt .maintenance-alerts-wrap {
        padding: 20px 40px 20px 40px;
    }

    .assetsdetailsitm p b {
        font-size: 14px;
        line-height: 25px;
    }

    .pdfdownloadbtn {
        margin-top: 25px;
    }

    .maintinancetable h2,
    .zoomercontent h2 {
        font-size: 20px;
    }

    .myprofilewraptab #tile-1 .nav-tabs li a {
        font-size: 18px;
    }

    .pdfdownloadbtn .flex-div button {
        line-height: 20px;
        padding: 10px 10px;
        font-size: 13px;
    }

    .partnumberdiv .partdiv,
    .info-content .runingdiv {
        padding: 5px 0px;
        font-size: 13px;
    }

    .partnumberdiv .markdiv {
        padding: 10px 12px;
    }

    .maintenance-info .info-content p {
        font-size: 18px;
    }

    .monthwise-list .month-date {
        font-size: 16px;
    }

    .monthwise-list .month-date img {
        width: 18px;
    }

    .maintenance-alerts-wrap {
        padding: 40px 50px 30px 50px;
    }

    .info-content .maintenance-number {
        font-size: 18px;
    }

    .maintenancepoint-list ul ol {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1080px) {
    .detail-assets-lt .maintenance-alerts-wrap {
        padding: 20px 10px 20px 10px;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
        width: 70px;
        height: 50px;
    }

    .setting_wrap.addresscontwrap ul li .addressaction a svg,
    .setting_wrap.addresscontwrap ul li span svg,
    .setting_wrap ul li a span svg {
        width: 60px;
    }

    .assetsdetailsitm p {
        font-size: 14px;
    }

    .magnify-Image {
        padding-right: 0px;
    }

    .image-gallery-thumbnails {
        margin-bottom: 20px;
    }

    .tabs-content-wrap .container {
        padding: 0 30px !important;
    }
}

@media only screen and (max-width:1023px) {
    .image-gallery-thumbnail {
        display: flex !important;
        margin-bottom: 5px !important;
    }

    .image-gallery-thumbnails {
        max-height: 120px !important;
        overflow-x: auto !important;
        overflow-y: hidden;
    }

    .magnify-Image {
        display: block;
    }

    .image-gallery-thumbnails .image-gallery-thumbnails-container {
        display: flex;
    }
}

@media only screen and (max-width: 991px) {
    .maintinancetable h2 {
        margin-left: 10px;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .common_btn button {
        height: auto;
        min-height: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .detail-assets-lt .partnumberdiv.onphone .markdiv {
        text-align: center;
        margin: 0px;
    }

    .myprofilewraptab.detail-assets-lt #tile-1 .tab-pane {
        padding: 15px 0 0px 0px;
    }

    .detail-assets-lt .pdfdownloadbtn .flex-div button {
        margin: 0 auto 0px !important;
    }

    .detail-assets-lt .partnumberdiv .partdiv p {
        font-size: 14px;
        margin-left: 0px;
    }

    .detail-assets-lt .maintenancediv-wrap .maintenance-info .info-content p {
        font-size: 14px;
        margin-left: 0px;
    }

    .partnumberdiv .partdiv,
    .partnumberdiv .markdiv,
    .info-content .runingdiv {
        padding: 5px 0px;
        font-size: 13px;
    }

    .partnumberdiv.onphone .markdiv {
        text-align: center;
    }

    .onphone {
        display: block !important;
    }

    .ondesktop {
        display: none;
    }

    .maintenance-info .partnumberdiv {
        display: block;
    }

    .partnumberdiv .partdiv,
    .partnumberdiv .markdiv,
    .info-content .runingdiv {
        padding: 10px 0px;
    }

    .faqwrap.maintancealerts .partnumberdiv .partdiv p,
    .faqwrap.maintancealerts .info-content .runingdiv p {
        margin: 0px !important;
    }

    .partnumberdiv .partdiv,
    .info-content .runingdiv {
        background-color: transparent;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .info-content .maintenance-number {
        font-size: 14px;
    }

    .maintenanceAlert-page .container {
        padding: 0px 15px !important;
    }

    .maincontentwrap {
        margin-top: 40px;
        padding: 20px 0px;
    }

    .tabs-content-wrap .container {
        padding: 0 10px !important;
    }

    .pdfdownloadbtn .flex-div button {
        font-size: 14px;
        width: calc(100% - 20px);
        margin: 0 auto 15px !important;
    }

    .myprofilewraptab {
        margin-top: 30px;
    }

    .myprofilewraptab.asstesdetailswrap .tab-content {
        margin-top: 0px;
    }

    .myprofilewraptab #tile-1 .nav-tabs {
        width: 290px;
        height: 52px;
    }

    .myprofilewraptab #tile-1 .nav-tabs li {
        padding: 0px 0px;
    }

    .myprofilewraptab #tile-1 .nav-tabs li a {
        padding: 0px 0px !important;
        font-size: 14px;
    }

    .myprofilewraptab #tile-1 .slider {
        width: 139px !important;
        height: 44px;
        bottom: 4px;
    }

    .setting_wrap ul li a span svg {
        top: 21px;
        margin-right: 15px;
        width: 50px;
    }

    .setting_wrap ul li {
        padding: 5px 10px 5px 10px;
    }

    .setting_wrap ul li a {
        font-size: 16px;
    }

    .userimagewrap {
        margin-top: 35px;
    }

    .userimagewrap .userimage {
        width: 120px;
        height: 120px;
    }

    .setting_wrap.addresscontwrap ul li .addressaction a svg,
    .setting_wrap.addresscontwrap ul li span svg {
        width: 50px;
    }

    .setting_wrap.addresscontwrap ul li {
        padding: 70px 15px 15px 15px;
        margin-bottom: 25px;
    }

    .setting_wrap.addresscontwrap ul li .addressaction {
        right: 15px;
        top: 10px;
    }

    .setting_wrap.addresscontwrap ul li span {
        position: absolute;
        left: 15px;
        top: 10px;
    }

    .setting_wrap.addresscontwrap .common_btn {
        width: 100%;
    }

    .myprofilewraptab #tile-1 .tab-pane {
        padding: 15px 0;
    }

    .myprofilewraptab.asstesdetailswrap #tile-1 .nav-tabs li a {
        padding: 3px 10px !important;
    }

    .zoomercontent h2 {
        font-size: 20px;
    }

    .pdfdownloadbtn .common_btn {
        width: 100%;
    }

    .maintenance-alerts-wrap {
        padding: 40px 20px 30px 20px;
    }

    .statusline {
        margin-right: 25px;
    }

    .maintenance-month-list .maintenancediv-wrap {
        padding: 15px;
    }

    .maintenance-info .info-content p {
        font-size: 16px;
        line-height: 22px;
    }

    .markdiv {
        margin-left: 5px;
    }

    .serial-no-dropdown>select.form-control {
        width: 100%;
    }
}