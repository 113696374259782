.user-management {
    margin: 20px 60px 30px 60px;
    /* background: #FFF !important;
    border: 1px solid #D9E1E7CC; */
    border-radius: 9px;
}

.user-management .container {
    max-width: 100% !important;
    width: 100%;
    padding: 0 0px !important;
}

.user-management .tabledatawrap table tbody tr td .action-text a {
    color: #fff;
    margin: 0px 10px;
    /* text-decoration: underline!important; */
    padding: 12px;
    background-color: #1b3e7c;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.user-management .tabledatawrap table tbody tr td .action-text a svg {
    width: 20px;
}

.user-management .tabledatawrap tr td img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: contain;
}

.user-management .tabledatawrap table thead {
    width: 100%;
    background: #fff;
    color: #a7c3db;
}

.user-management .tabledatawrap table thead th {
    padding: 18px 10px !important;
    color: #86A3BB;
    border-top: 2px solid #dee2e6;
}

.user-management .tabledatawrap table tbody tr td {
    padding: 15px 10px;
    color: #193870;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    align-items: center;
    justify-content: center;
}

.addmorebtn {
    text-align: right;
}

.user-management .tabledatawrap table tbody tr:last-child {
    border-bottom: 0px !important;
}

.user-management .tabledatawrap table tbody tr {
    border-top: 1px solid #e0e0e0;
    background: #fff !important;
    border-bottom: 2px dashed #e0e0e0 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.useform-wrap {
    padding: 40px 40px;
    background-color: #fff;
    margin-top: 15px;
}

.useform-wrap .formData-wrap .form-group {
    margin-bottom: 30px;
}

.formData-wrap .form-group select.form-control {
    -webkit-appearance: none;
    background-position: calc(100% - 12px) calc(1em + 0px), calc(100% - 24px) calc(1em + 2px), 100% 0;
    background-size: 24px 24px, 24px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
}

.dtlbtn-class {
    background-color: #ffd6c9;
    color: #cf0000;
    border: 1px solid #ffd6c9;
    padding: 10px 30px;
    border-radius: 4px;
    margin-bottom: 30px;
    float: right;
}

.user-management .cartPage-heading {
    margin-top: 0px;
}

.subUser-profile {
    display: flex;
    align-items: center;
}

.subUser-profile .profileim {
    margin-right: 15px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
}

.subUser-profile .profileim img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.subUser-profile p {
    margin: 0px;
    font-weight: 500;
    color: #1c3e7d;
}

.addmorebtn button {
    width: auto;
    margin-top: 0px;
}

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out;
}

.avatar-upload .avatar-edit label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-preview div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.imgUploadwrap img {
    width: 90px;
    height: 90px;
    opacity: 1;
    position: relative;
    right: auto;
    top: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    object-fit: contain;
}

.imgUploadwrap {
    text-align: center;
}

.imgUploadwrap .hoverable {
    position: relative;
    display: block;
    cursor: pointer;
    width: 100px;
    border: 1px dashed #1C3E7D;
    border-radius: 6px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 100%;
}

.hoverable .hover-text {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.hoverable .background {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 1;
}

.hoverable:hover .hover-text {
    display: block;
}

.hoverable:hover .background {
    display: block;
}

#fileInput {
    display: none;
}

.confirm-alert-btn {
    display: inline-block !important;
    margin: 15px 5px !important;
}

@media (max-width:1380px) {
    .user-management {
        margin: 90px 50px 20px 50px;
    }
    .useform-wrap {
        padding: 30px 30px;
    }
    .addmorebtn button {
        font-size: 15px;
        height: 45px;
        min-height: 45px;
    }
    .dtlbtn-class {
        font-size: 15px;
        padding: 8px 20px;
    }
}

@media (max-width:1080px) {
    .dtlbtn-class {
        font-size: 14px;
        padding: 6px 10px;
    }
    .addmorebtn button {
        font-size: 14px;
        height: 40px;
        min-height: 40px;
    }
    .user-management {
        margin: 90px 30px 20px 30px;
    }
    .useform-wrap {
        padding: 20px 20px;
    }
    .imgUploadwrap img {
        width: 70px;
        height: 70px;
    }
    .subUser-profile .profileim {
        width: 50px;
        height: 50px;
    }
    .subUser-profile p {
        font-size: 14px;
    }
}

@media (max-width:767px) {
    .subUser-profile .profileim {
        width: 40px;
        height: 40px;
    }
    .imgUploadwrap .hoverable {
        width: 80px;
        height: 80px;
    }
    .imgUploadwrap img {
        width: 50px;
        height: 50px;
    }
    .useform-wrap {
        padding: 10px 10px;
    }
    .user-management {
        margin: 90px 15px 20px 15px;
    }
    .user-management .cartPage-heading .common_btn button {
        font-size: 13px;
        padding: 8px 10px !important;
        line-height: 12px;
    }
}

@media (max-width:414px) {
    .user-management .cartPage-heading .common_btn button {
        padding: 8px 5px !important;
    }
}