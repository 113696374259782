/* Common CSS Start here */

.explorewrap>img {
    position: absolute;
    right: 5px;
    top: 0px;
    width: 368px;
    height: 368px;
    z-index: 0;
}


/* Common CSS End here */


/* FAQ Screen CSS Start Here */

.faqwrap .accordion {
    margin: 20px 0;
}

.faqwrap .accordion .item {
    border: none;
    margin-bottom: 30px;
    background: none;
    border-radius: 10px;
    background-color: #F4F7FA;
    box-shadow: 0px 3px 6px #00000029;
}

.faqwrap .accodian_cont_wrap {
    padding: 20px 0px 0px 0px;
}

.faqwrap .accordion .item .item-header h2 button.btn.btn-link {
    border-radius: 0px;
    font-size: 18px;
    line-height: 36px;
    text-decoration: none;
    background-color: #F4F7FA;
    border-radius: 10px;
    color: #0E0E0F;
    white-space: normal;
    margin-bottom: 16px;
    font-weight: 500;
}

.faqwrap .accordion .item .item-header {
    border-bottom: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
}

.faqwrap .accordion .item .item-header h2 button {
    position: relative;
    color: white;
    font-size: 20px;
    padding: 15px;
    display: block;
    width: 100%;
    text-align: left;
    padding-right: 120px;
}

.faqwrap .accordion .item .item-header h2 button .faqcloseicons {
    position: absolute;
    ;
    top: 14px;
    right: 15px;
    background-color: transparent;
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.faqwrap .accordion .item .item-header h2 button.btn.btn-link.collapsed .open_faq,
.faqwrap .accordion .item .item-header h2 button.btn.btn-link .close_faq {
    display: none;
}

.faqwrap .accordion .item .item-header h2 button.btn.btn-link .open_faq {
    display: block;
}

.faqwrap .accordion .item .item-header h2 button.btn.btn-link.collapsed .close_faq {
    display: block;
}

.faqwrap .accordion .collapse p,
.collapsing p {
    position: relative;
    top: -25px;
    font-size: 17px;
    padding: 0px 20px 0px 20px !important;
    color: #7A7A7A;
}


/* FAQ Screen CSS End Here */

@media only screen and (max-width: 767px) {
    .faqwrap .accordion .item .item-header h2 button.btn.btn-link .open_faq,
    .faqwrap .accordion .item .item-header h2 button.btn.btn-link .close_faq {
        width: 50px;
    }
    .faqwrap .accordion .item .item-header h2 button {
        padding-right: 75px;
    }
    .faqwrap .accordion .item .item-header h2 button.btn.btn-link {
        font-size: 16px;
        line-height: 28px;
    }
    .faqwrap .accordion .collapse p,
    .collapsing p {
        font-size: 14px;
    }
    .faqwrap .accordion .item .item-header h2 button .faqcloseicons {
        top: 9px;
        right: 10px;
    }
    .faqwrap .accordion {
        margin: 20px 0;
    }
}