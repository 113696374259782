.user-machine-info-tabs .sideuser-img {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 10px auto;
    border: 1px dashed #d5a002;
    border-radius: 100%;
    padding: 2px;
}

.user-machine-info-tabs .sideuser-img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    object-fit: contain;
    width: 100%;
    height: 100%
}

.show-user-details .sideuser-img {
    width: 80px!important;
    height: 80px;
    border-radius: 100%;
    padding: 5px;
    box-shadow: 0px 9.03012px 27.0904px rgb(191 203 203 / 32%), 0px 3.38629px 5.64383px rgb(223 226 227 / 32%);
    border: 2px dashed#d5a002;
    object-fit: cover;
}

.show-user-details .sideuser-img img {
    width: 80px;
    height: 80px;
    margin: auto;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
}

.user-machine-info-tabs h5 {
    font-size: 18px;
    color: #1e3e7b;
    font-weight: 600;
}

.user-machine-info-tabs .star-user {
    left: 50%;
    transform: translateX(-50%);
}

.map-section {
    margin-top: 0px;
}

.map-view {
    width: calc(100% - 400px);
}

.user-machine-info-tabs {
    width: 400px;
    background-color: #fff;
    border-top: 1px solid #f9f5f5;
}

.user-machine-info-tabs .nav-tabs {
    border: 0px;
    justify-content: center;
}

.user-machine-info-tabs .nav-tabs li {
    width: 50%;
    text-align: center;
}

.user-machine-info-tabs .tab-content {
    padding: 20px 0px 20px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 275px);
}

.user-machine-info-tabs .user-information-div {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
}

.user-information-div .user-image {
    margin-right: 20px;
    width: 70px;
    height: 70px;
}

.machine-info-list .user-information-div .user-image {
    margin-right: 0px;
    width: 80px;
    height: 80px;
}

.machine-info-list .user-information-div .user-dtl {
    width: calc(100% - 90px);
}

.user-information-div .user-image img {
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 59px;
    height: 60px;
    width: 60px;
    padding: 2px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.user-information-div .user-dtl {
    width: calc(100% - 70px);
}

.user-information-div .user-dtl p {
    font-size: 14px;
    font-weight: 700;
    color: #193870;
    margin-bottom: 0px;
    line-height: 20px;
}

.user-information-div .user-dtl span {
    font-size: 13px;
    font-weight: 400;
    color: #193870;
}

.user-machine-info-tabs .nav-tabs .nav-link.active {
    border-bottom: 2px solid #1c3e7d;
    opacity: 1;
}

.user-machine-info-tabs .nav-tabs .nav-link {
    opacity: 0.5;
    font-size: 16px;
    font-weight: 700;
    color: #1c3e7d;
    border: 0px;
    border-bottom: 2px solid #fff;
}

.map-view-resize {
    width: calc(100% - 800px);
    /* -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear; */
}

.show-user-details {
    display: none;
    width: 400px;
    height: 100vh;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 140px);
    direction: rtl;
    /* -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear; */
}

.map-view.map-view-resize .show-user-details {
    /* -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear; */
}

.mapInfo-user {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 10px !important;
    background-color: #fff;
    position: relative;
}

.mapInfo-user button img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0px 0px;
}

.mapInfo-user img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: auto;
}

.mapInfo-user button {
    position: absolute;
    border: 0px;
    background-color: transparent;
    left: 10px;
    top: 30px;
}

.sideuser-info {
    width: 100%;
    text-align: center;
    margin: 10px 0px;
}

.mapInfo-user .sideuser-info span {
    display: block;
}

.locationUser-table {
    padding: 0px 15px 15px 15px;
}

.locationUser-table .machine-info {
    padding: 15px 0px;
    border-bottom: 2px dashed#183c7c;
}

.locationUser-table li:last-child .machine-info {
    border-bottom: 0px;
}

.locationUser-table .machine-info strong {
    color: #1c3e7d !important;
}

.locationUser-table .machine-info .dtl-span {
    display: block;
    padding-bottom: 5px;
}

.mapInfo-user span {
    color: #333;
    font-weight: 500;
    font-size: 16px;
}

.locationUser-table table {
    word-break: break-all;
    white-space: initial;
}

.locationUser-table table tbody h5 {
    font-size: 15px;
    font-weight: 400;
    color: #212529;
}

.minwidthAdress {
    min-width: 150px;
    width: 150px;
    word-break: break-all;
}

.small-minwidth {
    width: 70px;
    min-width: 70px;
    word-break: break-all;
}

#map-canvas {
    height: 100%
}

img[src="https://www.gravatar.com/avatar/0a9745ea7ac5c90d7acadb02ab1020cd?s=32&d=identicon&r=PG&f=1"] {
    border-radius: 16px;
    border: 1px solid #000 !important;
}

.sideuser-img.star-circle .star-user {
    position: absolute;
    left: 50%;
    bottom: -13px;
    transform: translateX(-50%);
}

.user-info-list ul li {
    padding: 15px 0px;
    border-bottom: 2px dashed #183c7c;
}

.user-info-list ul li:last-child {
    border-bottom: 0px;
}

@media (max-width:1460px) {
    .user-information-div .user-image img {
        width: 50px;
        height: 50px;
    }
    .user-information-div .user-image {
        margin-right: 10px;
        width: 50px;
        height: 50px;
    }
    .user-information-div .user-dtl p {
        font-size: 13px;
    }
    .user-information-div .user-dtl span {
        font-size: 12px;
        line-height: 18px;
    }
    .sideuser-info h5 {
        font-size: 16px;
    }
    .mapInfo-user span {
        font-size: 14px;
    }
    .locationUser-table .machine-info .dtl-span {
        font-size: 14px;
    }
    .user-machine-info-tabs .sideuser-img {
        width: 60px;
        height: 60px;
    }
    .user-machine-info-tabs h5 {
        font-size: 16px;
    }
}

@media (max-width:767px) {
    .locationUser-table li:last-child .machine-info {
        padding: 5px 0px;
    }
    .user-info-list ul li {
        padding: 10px 0px;
    }
    .user-machine-info-tabs .user-information-div {
        padding: 0px 0px 0px 0px;
    }
    .user-machine-info-tabs {
        width: 100%;
    }
    .user-machine-info-tabs .nav-tabs .nav-link {
        font-size: 14px;
    }
    .machine-info-list .user-information-div .user-image {
        margin-right: 5px;
        width: 50px;
        height: 50px;
    }
    .map-view-resize {
        width: 0vw;
    }
    .show-user-details {
        width: 100%;
        height: 350px;
        background-color: #fff;
        margin-left: 10px;
    }
    .map-view {
        width: 100%;
        height: 400px!important;
    }
    .machine-info-list .user-information-div .user-dtl {
        width: calc(100% - 55px);
    }
    .map-section {
        margin-top: 60px;
    }
    .user-machine-info-tabs .tab-content {
        padding: 10px 10px 20px 20px;
        overflow: hidden;
        overflow-y: auto;
        height: 266px;
    }
}


/* #map-canvas {
    height: 500px;
    width: 960px;
    margin: 0px;
    padding: 0px
  }
  #markerLayer img {
    border: 2px solid red !important;
    width: 85% !important;
    height: 90% !important;
    border-radius: 5px;
  } */