.explorewrap.contactuswrp {
    margin: 20px 60px 30px 60px!important;
    background-color: #F0F3F6;
}

.contactuswrp .container {
    padding: 0px!important;
}

.contactcardwrap {
    margin-top: 40px;
}

.contactuswrp {
    position: relative;
}

.contentmain-text .left-Aboutimg img {
    width: 150px;
    margin-bottom: 20px;
}

.contactSubheading {
    background-color: #E1F0FF;
    border-radius: 4px;
    color: #193870;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 30px;
    display: inline-block;
    margin: 20px 0px 30px 0px;
}

.contactuswrp .contactbanner {
    position: relative;
    /* background: url(/public/images/worldmap.png)no-repeat; */
    /* background-size: 100% 100%;
    background-position: top right; */
}

.contactbanner-wrap {
    /* background-image: url("images/down_arrow.png"); */
    background-color: #FFF;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 30px 30px;
}

.contactbanner-wrap .contactBanner-bg {
    background-image: url(/src/images/worldmap.png);
    background-size: 100%;
    background-position: top right;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    margin-top: 30px;
}

.contactuswrp .contactbanner p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    max-width: 800px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
}

.contactcard {
    padding: 15px 0;
    margin-bottom: 0px;
}

.contactcard h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #1c3e7d;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
}

.contactcard span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
    color: #1c3e7d;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
}

.internationdivisiondiv .contryname {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
    color: #1c3e7d;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
}

.internationdivisiondiv .contryname img {
    position: relative;
    right: auto;
    top: auto;
    height: auto;
    width: 50px;
    margin-right: 15px;
}

.contactcard p,
.internationdivisiondiv .internationadd p,
.industrail-division .divisiondiv p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #153441;
}

.contactcard p a {
    color: #000;
}

.contentmain-text .contact-content p {
    font-size: 20px;
    font-weight: 700;
    color: #1E3E7B;
    line-height: 35px;
}

@media only screen and (max-width: 1600px) {
    .contactcard h2 {
        font-size: 22px;
    }
    .contactcard span,
    .internationdivisiondiv .contryname {
        font-size: 18px;
    }
    .contactcard p,
    .internationdivisiondiv .internationadd p,
    .industrail-division .divisiondiv p {
        font-size: 16px;
    }
    .contentmain-text .contact-content p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1440px) {
    .contactuswrp .contactbanner p {
        max-width: 80%;
        width: 100%;
    }
}

@media (max-width:1320px) {
    .contentmain-text .contact-content p {
        font-size: 17px;
    }
    .contactSubheading {
        font-size: 16px;
    }
    .contactcard span,
    .internationdivisiondiv .contryname {
        font-size: 16px;
    }
    .contactcard p,
    .internationdivisiondiv .internationadd p,
    .industrail-division .divisiondiv p {
        font-size: 15px;
    }
    .contentmain-text .left-Aboutimg img {
        width: 130px;
    }
    .contactbanner-wrap {
        padding: 30px 20px;
    }
    .explorewrap.contactuswrp {
        margin: 110px 40px 30px 40px!important;
    }
}

@media only screen and (max-width: 1023px) {
    .contentmain-text .contact-content p {
        font-size: 16px;
    }
    .contactSubheading {
        font-size: 15px;
    }
    .contactcard span,
    .internationdivisiondiv .contryname {
        font-size: 15px;
    }
    .contactcard p,
    .internationdivisiondiv .internationadd p,
    .industrail-division .divisiondiv p {
        font-size: 14px;
    }
    .contentmain-text .left-Aboutimg img {
        width: 120px;
    }
    .contactbanner-wrap {
        padding: 20px 15px;
    }
    .explorewrap.contactuswrp {
        margin: 100px 20px 30px 20px!important;
    }
}

@media only screen and (max-width: 840px) {}

@media only screen and (max-width: 840px) {}

@media only screen and (max-width: 991px) {
    .explorewrap.contactuswrp {
        margin-top: 60px;
    }
    .contactcardwrap {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .explorewrap.contactuswrp {
        margin: 80px 15px 30px 15px!important;
    }
    .contactSubheading {
        font-size: 15px;
        padding: 8px 20px;
    }
    .contentmain-text .contact-content p {
        font-size: 16px;
        line-height: 27px;
    }
    .contentmain-text .left-Aboutimg img {
        width: 110px;
    }
    .contactuswrp .contactbanner p {
        position: relative;
        max-width: 100%;
        font-size: 16px;
    }
    .contactuswrp .contactbanner {
        padding: 20px 15px;
    }
    .explorewrap.contactuswrp {
        margin-top: 62px;
    }
    .contactcard h2 {
        font-size: 20px;
    }
}