.itemcontent-wrap h2 {
    font-size: 24px!important;
    line-height: 40px!important;
    font-weight: 600!important;
}

.changepassword {
    padding: 20px 0px;
}

.setting_wrap .itemcontent-wrap ul li {
    background: #f4f7fa;
    box-shadow: 0px 3px 6px #e4afaf29;
    border-radius: 10px;
    padding: 15px 25px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pos-realtive {
    position: relative;
}


/* Setting Screen CSS Start Here */

.setting-page .conatiner {
    padding: 0px 80px!important;
}

.settingpage-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.settingpage-item .itemcontent-wrap {
    width: calc(100% - 350px);
    padding: 15px;
}

.settingpage-item .setingitem-wrap {
    width: 350px;
    border-right: 2px dashed #E1F0FF;
}

.settingpage-item .setingitem-wrap ul {
    margin: 0px!important;
    padding-bottom: 70px;
}

.setingitem-wrap ul li {
    background: #fff!important;
    box-shadow: 0px 0px 0px!important;
    border-radius: 0px!important;
    padding: 0px!important;
    margin-bottom: 0px!important;
    width: 100%;
}

.setingitem-wrap ul li a {
    font-size: 16px;
    color: #6C6C6C;
    font-weight: 500;
    border-color: #fff!important;
    padding: 25px 50px!important;
    display: block;
    line-height: 0px!important;
    border-left: 2px solid #fff;
}

.setingitem-wrap ul li a.active {
    color: #1E3E7B;
}

.setingitem-wrap ul li a.active {
    border-left: 2px solid #1E3E7B!important;
    background-color: #E1F0FF!important;
}

.setingitem-wrap ul li a.active svg path {
    fill: #1E3E7B!important;
}

.setingitem-wrap ul li a.active svg {
    opacity: 1!important;
    fill: #1E3E7B!important;
}

.setingitem-wrap ul li svg {
    margin-right: 30px;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.explorecont.setting_wrap {
    padding-top: 20px;
}

.setting_wrap ul {
    list-style-type: none;
    margin-top: 40px;
    margin-bottom: 100px;
}

.setting_wrap ul li {
    background: #f4f7fa;
    box-shadow: 0px 3px 6px #e4afaf29;
    border-radius: 10px;
    padding: 5px 25px;
    margin-bottom: 35px;
}

.setting_wrap ul li a span svg {
    position: relative;
    top: -2px;
    margin-right: 15px;
}


/* .setting_wrap ul li a {
    position: relative;
    color: #0e0e0f;
    font-size: 20px;
    line-height: 68px;
    display: block;
    font-weight: 500;
    top: 4px;
    padding-bottom: 7px;
    text-decoration: none;
} */


/* Setting Screen CSS End Here */

@media only screen and (max-width: 767px) {
    .setting_wrap.settingcard ul {
        border-bottom: 1px solid #E1F0FF;
    }
    .setting_wrap.settingcard ul li {
        height: auto;
        text-align: center;
    }
    .setting_wrap.settingcard ul li a span svg {
        width: 50px;
    }
    .setting_wrap.settingcard ul li a {
        font-size: 13px;
        line-height: 19px !important;
        padding: 15px 20px !important;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .setingitem-wrap ul li svg {
        display: block;
        margin: 0 auto 10px auto;
    }
    .setting_wrap.settingcard ul li a span svg {
        top: -1px;
    }
    .settingpage-item .setingitem-wrap,
    .settingpage-item .itemcontent-wrap {
        width: 100%;
    }
    .settingpage-item .setingitem-wrap ul {
        padding-bottom: 0;
        flex-wrap: initial;
        overflow-x: scroll;
    }
    .faqwrap .accordion .item .item-header h2 button.btn.btn-link,
    .faqwrap .accordion p {
        word-wrap: break-word;
    }
    .faqwrap .accordion .item .item-header h2 button .faqcloseicons {
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
    }
}