/* ========== */

.raseticker-page .cartPage-heading {
    margin: 20px 0px 0px 0px;
    padding: 30px;
}

.raseticker-wrap {
    background-color: #fff;
    margin: 15px 0px;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 20px 20px;
}

.raseticker-page .container {
    padding: 0px 80px !important;
}

.raseticker-wrap .addaddresswrap {
    margin: 0px;
}

.raseticker-wrap .raiseaticket .common_input_warp .form-group select.classic {
    height: 55px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}

.raseticker-wrap .raiseaticket .common_input_warp .form-group input.form-control {
    height: 55px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}

.request-wrap .labeldiv label {
    position: relative;
    cursor: pointer;
}

.request-wrap .labeldiv label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #1e3e7b;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 100%;
}

.request-wrap .labeldiv input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 5px;
    width: 14px;
    height: 14px;
    /* border: solid #0079bf; */
    /* border-width: 0 2px 2px 0; */
    /* transform: rotate(45deg); */
    border-radius: 100%;
    background-color: #1e3e7b;
}

.request-wrap .labeldiv input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}


/* ========= */

.raiseaticket .common_input_warp label {
    font-weight: 500;
}

.setting_wrap.addresscontwrap {
    position: relative;
}

.setting_wrap.addresscontwrap ul li {
    position: relative;
    padding: 20px 200px 15px 125px;
    display: block;
    min-height: 170px;
}

.setting_wrap.addresscontwrap ul li span {
    position: absolute;
    left: 30px;
    top: 20px;
}

.request-wrap .labeldiv label {
    margin-left: 5px;
    font-weight: 400;
}

.addaddresswrap .common_input_warp .form-group.labeldiv {
    margin-bottom: 15px;
}

.setting_wrap.addresscontwrap ul li .addresscont {
    width: calc(100% - 70px);
}

.setting_wrap.addresscontwrap ul li .addresscont h2 {
    font-size: 19px;
    color: #193870;
    line-height: 20px;
    padding-right: 80px;
}

.setting_wrap.addresscontwrap ul li .addresscont p {
    font-size: 15px;
    color: #7a7a7a;
}

.setting_wrap.addresscontwrap ul li .addresscont label {
    font-size: 17px;
    font-weight: normal;
    color: #193870;
}

.setting_wrap.addresscontwrap ul li .addressaction {
    position: absolute;
    right: 30px;
    top: 50px;
}

.setting_wrap.addresscontwrap ul li .addressaction a {
    display: inline-block !important;
}

.setting_wrap.addresscontwrap ul li .addressaction a svg {
    padding-left: 10px;
}

.setting_wrap.addresscontwrap ul {
    margin-bottom: 20px;
    margin-top: 45px;
}

.setting_wrap.addresscontwrap .common_btn {
    width: 407px;
    font-size: 16px;
    font-family: "FiraSans-Bold";
    margin: 0 auto;
    display: table;
}

.setting_wrap.addresscontwrap.addressdatawrap ul {
    margin-bottom: 0px;
    margin-top: 5px;
}

.setting_wrap.addresscontwrap.addressdatawrap ul li {
    position: relative;
    display: flex;
    min-height: 0px !important;
    flex-wrap: wrap;
    align-items: start;
    padding: 20px;
}

.setting_wrap.addresscontwrap.addressdatawrap ul li .addresstypesvg,
.setting_wrap.addresscontwrap.addressdatawrap ul li .customradio {
    position: relative;
    left: auto;
    top: auto;
    left: auto;
    width: 60px;
    margin-right: 10px;
}

.setting_wrap.addresscontwrap.addressdatawrap ul li img {
    position: relative !important;
    left: auto !important;
    top: auto !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 100%;
    object-fit: cover;
}

.setting_wrap.addresscontwrap.addressdatawrap ul li .customradio {
    left: inherit;
    right: 25px;
    top: 20px;
}

.customradio [type="radio"]:checked,
.customradio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.customradio [type="radio"]:checked+label,
.customradio [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #1b3c78;
    font-size: 16px;
}

.customradio [type="radio"]:checked+label:before,
.customradio [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 0;
    background: #fff;
    transition: all 400ms linear;
}

.customradio [type="radio"]:checked+label:before {
    background-color: #193870;
    border: 1px solid #193870;
}

.customradio [type="radio"]:checked+label:after,
.customradio [type="radio"]:not(:checked)+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    background-color: transparent;
    border-width: 0 2px 2px 0;
    transform: rotate(46deg);
    box-shadow: none;
    transition: all 400ms linear;
}

.customradio [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.customradio [type="radio"]:checked+label:after {
    opacity: 1;
}

.custom-radio {
    position: absolute;
    right: 26px;
}

.common_input_warp.addaddressbtn a {
    color: #1e3e7b;
    display: block;
    /* background-color: #3a50823d; */
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline !important;
}

.requestform .addaddresswrap .common_input_warp .form-group {
    margin-bottom: 20px;
}

.addaddresswrap.raiseaticket .common_input_warp .form-group select.classic {
    padding-right: 40px;
}

.addaddresswrap.raiseaticket .common_btn {
    width: 100%;
    display: flex;
    justify-content: end;
}

.addaddresswrap.raiseaticket .common_btn button {
    max-width: 35%;
}

.quantity-input {
    border-radius: 4px;
    overflow: hidden;
}

.quantity-input button {
    background: #3a5082 !important;
    border: 1px solid #3a5082 !important;
    color: #fff;
}

.quantity-input input {
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid #3a5082;
    outline: 0;
}

.page-min-height {
    min-height: 350px;
}

.btn-width {
    width: 35%;
    margin: auto;
}

.raiseaticket .setting_wrap.addresscontwrap {
    padding: 10px 0px;
}

.setting_wrap.addresscontwrap ul li {
    border-radius: 10px;
    padding: 5px 25px;
    margin-bottom: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    opacity: 1;
}

.setting_wrap.addresscontwrap ul li .addresscont h2 {
    padding: 0px;
}

@media (max-width:1600px) {
    .addaddresswrap.raiseaticket .common_input_warp label {
        font-size: 16px;
    }
    .addaddresswrap .common_input_warp .form-group {
        margin-bottom: 25px;
    }
    .cartPage-heading h3 {
        font-size: 24px;
    }
    .raseticker-wrap .raiseaticket .common_input_warp .form-group input.form-control {
        height: 45px;
    }
    .logoutpopupwrap .common_input_warp .form-group {
        margin-bottom: 20px;
    }
}

@media (max-width:1440px) {
    .setting_wrap.addresscontwrap ul li .addresscont h2 {
        font-size: 17px;
    }
    .setting_wrap.addresscontwrap ul li .addresscont p {
        font-size: 13px;
        line-height: 20px;
    }
    .setting_wrap.addresscontwrap ul li .addresscont label {
        font-size: 15px;
    }
    .common_input_warp.addaddressbtn a {
        font-size: 15px;
    }
    .raseticker-page .container {
        padding: 0px 60px !important;
    }
    .raseticker-page .cartPage-heading {
        padding: 25px;
    }
    .cartPage-heading h3 {
        font-size: 22px;
    }
    .addaddresswrap.raiseaticket .common_input_warp label {
        font-size: 14px;
    }
    .addaddresswrap .common_input_warp .form-group {
        margin-bottom: 20px;
    }
    .raseticker-wrap .raiseaticket .common_input_warp .form-group select.classic {
        height: 50px;
        background-size: 18px 18px;
    }
    .raseticker-wrap .raiseaticket .common_input_warp .form-group input.form-control {
        height: 40px;
    }
}

@media (max-width:1320px) {
    .raseticker-page .container {
        padding: 0px 40px !important;
    }
    .raseticker-page .cartPage-heading {
        padding: 20px 25px;
    }
    .request-wrap .labeldiv label:before {
        padding: 8px;
    }
    .request-wrap .labeldiv input:checked+label:after {
        top: 12px;
        left: 5px;
        width: 11px;
        height: 11px;
    }
}

@media (max-width:1023px) {
    .raseticker-page .container {
        padding: 0px 30px !important;
    }
}

@media only screen and (max-width: 991px) {
    .addaddresswrap.raiseaticket .common_input_warp .form-group {
        margin-bottom: 15px;
    }
    .addaddresswrap.raiseaticket .common_input_warp label {
        font-size: 13px;
    }
    .setting_wrap.addresscontwrap ul li .addresscont h2 {
        font-size: 18px;
        padding-right: 0px;
        margin-bottom: 10px;
    }
    .setting_wrap.addresscontwrap.addressdatawrap ul li .addresstypesvg,
    .setting_wrap.addresscontwrap.addressdatawrap ul li .customradio {
        position: relative;
        left: -10px;
        top: 0px;
    }
    .setting_wrap.addresscontwrap.addressdatawrap ul li {
        padding: 20px 15px 15px 30px;
    }
    .common_input_warp.addaddressbtn a {
        float: right;
    }
    .raiseaticket .table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .addaddresswrap.raiseaticket .common_btn button {
        max-width: 48%;
    }
}

@media only screen and (max-width: 767px) {
    .raseticker-page .cartPage-heading {
        margin: 80px 0px 0px 0px;
    }
    .raseticker-page .container {
        padding: 0px 15px !important;
    }
}

@media (max-width:767px) {
    .request-wrap .labeldiv input:checked+label:after {
        top: 13px;
        left: 4px;
        width: 12px;
        height: 12px;
    }
    .setting_wrap.addresscontwrap ul li {
        margin-bottom: 10px;
    }
    .raseticker-page .cartPage-heading {
        font-size: 17px;
    }
    .raseticker-page .container {
        padding: 0px 15px !important;
    }
    .raseticker-page .cartPage-heading {
        margin: 90px 0px 0px 0px;
        padding: 30px;
    }
    .raseticker-page .cartPage-heading {
        padding: 15px 20px;
    }
}

.quantity-input {
    display: flex;
    float: right;
}

.quantity-input input {
    width: 40px;
    text-align: center;
}

.quantity-input button {
    background: #ccc;
    border: 1px solid #444;
}

.part-list {
    max-height: 114px;
    overflow: auto;
}

.note-p {
    font-size: 14px;
    line-height: 1.5;
    color: black;
}