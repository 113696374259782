/*==========Modal Css========*/

.fonticon {
    margin-bottom: 1rem!important;
    margin-top: 0.5rem;
}

.logoutpopupwrap h2.modlheading {
    margin-bottom: 1rem!important;
}

.modal-open .modal.modal-center .modal-dialog.custom_modal_wrap.new-address-modal {
    top: 45%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0 15px;
    padding-bottom: 0px;
}

.gap-btn {
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.gap-btn .rdrCalendarWrapper {
    position: absolute;
    right: 0px;
    top: 35px;
    z-index: 1;
}

.gap-btn .calender-icon img {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.apexcharts-datalabels-group {
    transform: translateY(30px) !important;
}

.modal-dialog.custom_modal_wrap.new-address-modal.new-modal .modal-content {
    width: 548px !important;
    margin-bottom: 60px;
}

.e-chip-pop-wrap .pop-image img {
    position: relative;
    right: auto;
    top: auto;
    width: 140px;
    height: 140px;
    margin: auto;
    text-align: center;
    display: table;
}

.e-chip-pop-wrap .chip-pop-text {
    max-width: 70%;
    margin: auto;
}

.e-chip-pop-wrap .chip-pop-text h4 {
    font-size: 20px;
    margin: auto;
    text-align: center;
    font-weight: 700;
    color: #06152B;
    line-height: 25px;
    margin: 20px auto 10px auto;
}

.e-chip-pop-wrap .chip-pop-button {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.chip-pop-button button.blue-btn {
    width: 50%;
    margin-right: 20px;
    padding: 12px;
    border: 0px;
    background: #1E3E7B;
    color: #1E3E7B;
    color: #E1F0FF;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px
}

.e-chip-pop-wrap .cube-text h4 {
    font-size: 26px;
    max-width: 70%;
    margin: auto;
    text-align: center;
    font-weight: 700;
    color: #000;
    line-height: 25px;
}

.e-chip-pop-wrap .cube-img svg g {
    margin: auto;
    display: table;
    width: 256px !important;
    height: 256px !important;
}

.chip-pop-button button.blue-btn.first-btn {
    width: calc(50% - 20px);
    margin: 0px;
}

.remind-text input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.remind-text label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    opacity: 0.5;
}

.remind-text label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #a6a9ab;
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
}

.remind-text input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.remind-text input:checked+label::before {
    border: 2px solid #000;
}

.checkbox-group {
    display: flex;
    align-items: center;
}

.checkbox-group span {
    font-weight: 500;
    color: #06152B;
}

.checkbox-group .chchbx {
    margin-left: 25px;
}

.entry-pop-wrap .chip-pop-button {
    max-width: 70%;
    margin: 40px auto 0px auto;
}

.entry-pop-wrap .form-group input.form-control {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    height: 50px;
    line-height: 50px;
    color: #adb2b9;
    font-size: 14px;
    font-weight: 500;
}

.entry-pop-wrap .entry-heading h3 {
    font-size: 32px;
    font-weight: 700;
    color: #1E3E7B;
    margin: 0px 0px 30px 0px;
    text-align: center;
}

.div-group {
    flex-wrap: wrap;
}

.div-group .divbox {
    width: 50%;
    margin-right: 20px;
}

.div-group .divbox.secndbox {
    width: calc(50% - 20px);
    margin: 0px;
}

.chchbx input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.chchbx [type=radio]:checked+label,
.chchbx [type=radio]:not(:checked)+label {
    position: relative;
    padding-left: 20px;
    line-height: 18px;
    cursor: pointer;
    color: #43425D;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.chchbx [type=radio]:not(:checked)+label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background: url(../../../public/images/check_icon.png) center center no-repeat;
    /* background-position-x: center;
    background-position-y: center; */
    background-size: auto;
    background-size: 36px 18px;
    background-position: 0 0;
}

.chchbx [type=radio]:checked+label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: url(../../../public/images/check_icon.png) center center no-repeat;
    /* background-position-x: center;
    background-position-y: center; */
    background-size: auto;
    background-size: 36px 18px;
    background-position: -18px 0;
}


/*==========Modal Css=======*/

.hours-graph-wrap .small-graph {
    margin-top: 30px;
}

.hours-graph-wrap .small-chart-div h6 {
    color: #1e3e7b;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 250px;
    margin: auto;
}

.hours-graph-wrap .small-chart-div .pard-card svg {
    position: absolute;
    top: 0px;
    right: -5px;
}

.hours-graph-wrap .small-chart-div span {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
}

.hours-graph-wrap .small-chart-div span p {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    line-height: 10px;
}

.small-chart-wrap button.reset-btn {
    background: #1E3E7B;
    border: 0px;
    color: #fff;
    border-radius: 5px;
    padding: 3px 14px;
    font-size: 14px;
}

.small-chart-wrap .maintinance-info-text span {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    opacity: 0.5;
}

.small-chart-wrap .maintinance-info-text p {
    font-size: 14px;
    font-weight: 700;
    color: #1E3E7B;
    margin: 0px 0px 10px 0px;
    line-height: 18px;
}

.bearing-wrap h3 {
    font-size: 22px;
    font-weight: 600;
    color: #1E3E7B
}

.bearing-wrap h3 strong {
    font-weight: bold;
}

.upcoming-list .date-div {
    margin-bottom: 15px;
}

.upcoming-list .date-div img {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    margin-right: 4px;
    display: inline-block;
}

.upcoming-list .date-div span {
    font-size: 12px;
    color: #000000;
    font-weight: 600;
}

.predective-detail-box {
    display: flex;
    flex-wrap: wrap;
}

.predective-detail-box .machine-box {
    width: 350px;
    margin-right: 20px;
    padding: 25px 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
}

.upcoming-maintenance {
    padding: 30px 15px;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
}

.upcoming-maintenance .upcoming-heading {
    margin-bottom: 20px;
}

.upcoming-maintenance .upcoming-heading h3 {
    font-size: 16px;
    color: #1b3e7c;
    font-weight: 900;
}

.upcoming-list .upcoming-list-box small {
    font-size: 11px;
    color: #cfc7c7;
    font-weight: 500;
}

.upcoming-list .upcoming-list-box h4 {
    font-size: 16px;
    color: #1b3e7c;
    font-weight: 700;
}

.upcoming-list ul li {
    border: 1px solid #d4ebfdcc;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.upcoming-list-box .runing-hours {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.runing-hours .hourstext span {
    font-size: 12px;
    font-weight: 500;
    color: #cfc7c7;
}

.runing-hours .hourstext p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    color: #1b3e7c;
}

.runing-hours .mark-btn button.bluebtn {
    background: #1c3e7d;
    color: #fff;
    border-radius: 8px;
    padding: 8px 10px;
    font-size: 13px;
    border: 0px;
}

.runing-hours .mark-btn button.bluebtn img {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    margin-right: 5px;
}

.machine-box .machine-image {
    margin: 50px 0px;
}

.machine-information {
    text-align: center;
}

.predective-detail-box .machine-detail-wrap {
    width: calc(100% - 370px);
    padding: 30px 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
}

.machine-detail-wrap .page-heading {
    background: #e8ebf2;
    padding: 10px;
    text-align: center;
    font-size: 24px;
    color: #1e3e7b;
    font-weight: 700;
    border-radius: 8px;
}

.predictive-content {
    position: relative;
    display: block;
}

.explorepage-wrap.predictive-wrap .container {
    padding: 0px !important;
}

.predictive-wrap .assets-configurator-wrap .explorebg-image img {
    width: 100% !important;
    height: 300px !important;
    border-radius: 10px;
}

.predictive-wrap .assets-configurator-wrap .explorebg-image img.autoheight {
    height: auto !important;
}

.predictive-wrap .assets-configurator-wrap .explorebg-image img.autoheight {
    height: auto !important;
}

.predictive-wrap .banner-heading {
    padding-left: 50px;
}

.predictive-types-wrap {
    margin-top: 40px;
}

.rwmn {
    margin-left: 0;
    margin-right: 0;
}

.predictive-types-wrap .container {
    padding: 0px !important;
}

.predictive-types-wrap .predictive-list {
    margin-top: 20px;
    text-align: center;
}

.predictive-wrap .assets-configurator-wrap {
    border-radius: 10px;
}

.predictive-types-wrap .predictive-list ul li {
    margin-bottom: 10px;
}

.view-modal {
    position: absolute;
    right: 0;
    top: 0;
    background: white;
    width: calc(100vw - 78%);
    height: 100vh;
    border: 1px solid #ccc;
    padding: 10px;
}

.small-chart-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.small-chart-wrap .small-chart-div {
    text-align: center;
    padding: 10px;
    border: 1px solid #0000001a;
    margin-right: 10px;
    width: calc(33.33% - 10px);
}

.predective-machine-bx {
    border: 1px solid #eaeaea;
    padding: 10px;
    box-shadow: 0.5rem 1rem 1.5rem 0 rgb(228 228 228);
}

.predective-machine-bx.bxnone {
    padding: 0;
    border: 0;
    box-shadow: none;
}

.apexcharts-text tspan {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
}

.predective-machine-bx .machine-image img {
    position: relative !important;
    right: auto !important;
    top: auto !important;
    width: 100% !important;
    height: auto !important;
    max-height: 220px;
}

.machine-information .machineName {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
}

.machine-information .machineSerialno,
.machine-information .machineStatus {
    font-weight: 500;
    margin-bottom: 5px;
}

.extraInfo-machine .extrainfo-div span {
    font-weight: 500;
    margin-bottom: 5px;
}

.extraInfo-machine .extrainfo-div {
    margin-bottom: 5px;
}

.extraInfo-machine .extrainfo-div small {
    font-weight: 400;
}

.chargraph {
    position: relative;
}

.predictive-wrap .explorecont-heading p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #93a3c0;
}

.calender-container {
    position: relative;
    text-align: right;
    top: 28px;
    z-index: 1;
}

.calender-container .predictive-data {
    position: relative;
    width: 330px;
    display: inline-block;
}

.calender-section {
    position: absolute;
    left: 0;
    top: 100%;
}

.comming-page .commingpage-wrap.predictive-View-wrap {
    margin: 30px 60px;
}

.comming-page.explorewrap .commingpage-wrap.predictive-View-wrap .container {
    padding: 0px 40px !important;
}

.apexcharts-title-text,
.small-graph h3 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    fill: #1E3E7B;
    color: #1E3E7B;
    margin: 30px 0px 10px 0px;
    font-family: 'Montserrat', sans-serif !important;
}

.small-graph h3 {
    margin-top: 0;
}

.small-graph {
    margin-bottom: 30px;
}

.predectivetime {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 36px;
    height: 36px;
    background-color: #1b3e7c;
    border-radius: 6px;
    font-weight: 500;
    z-index: 1;
    margin: 30px auto;
}

.machine-information {
    position: relative;
    padding-top: 15px;
}

.wd100 {
    width: 100%;
}

.small-graph h3 {
    margin-top: 0;
}

.small-graph {
    margin-bottom: 30px;
}

.rdrDateDisplayWrapper {
    display: none;
}

.pard-card {
    position: relative;
}

.pard-card .info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (max-width:1600px) {
    .apexcharts-title-text,
    .small-graph h3 {
        font-size: 22px;
    }
}

@media (max-width:1460px) {
    .entry-pop-wrap .entry-heading h3 {
        font-size: 28px;
    }
    .machine-detail-wrap .page-heading {
        font-size: 22px;
    }
    .apexcharts-title-text,
    .small-graph h3 {
        font-size: 20px;
    }
    .predective-detail-box .machine-detail-wrap {
        padding: 30px 50px;
    }
    .hours-graph-wrap .small-chart-div h6 {
        font-size: 15px;
    }
    .small-chart-wrap .maintinance-info-text p,
    .small-chart-wrap button.reset-btn {
        font-size: 13px;
    }
    .machine-information .machineName {
        font-size: 20px;
    }
    .machine-information .machineSerialno,
    .machine-information .machineStatus {
        font-size: 15px;
    }
    .upcoming-maintenance .upcoming-heading h3,
    .upcoming-list .upcoming-list-box h4 {
        font-size: 15px;
    }
    .runing-hours .hourstext p {
        font-size: 15px;
    }
    .upcoming-maintenance {
        padding: 20px 10px;
    }
    .bearing-wrap h3 {
        font-size: 20px;
    }
    .extraInfo-machine .extrainfo-div {
        font-size: 15px;
    }
}

@media (max-width:1380px) {
    .entry-pop-wrap .entry-heading h3 {
        font-size: 26px;
    }
    .predective-detail-box .machine-detail-wrap {
        padding: 25px 40px;
    }
    .machine-detail-wrap .page-heading {
        font-size: 20px;
    }
    .apexcharts-title-text,
    .small-graph h3,
    .bearing-wrap h3 {
        font-size: 18px;
    }
    .hours-graph-wrap .small-chart-div h6 {
        font-size: 14px;
    }
    .machine-information .machineName {
        font-size: 18px;
    }
    .machine-information .machineSerialno,
    .machine-information .machineStatus,
    .extraInfo-machine .extrainfo-div {
        font-size: 14px;
    }
    .e-chip-pop-wrap .chip-pop-text h4 {
        font-size: 18px;
    }
    .e-chip-pop-wrap .pop-image img {
        width: 120px;
        height: 120px;
    }
    .e-chip-pop-wrap .chip-pop-text {
        max-width: 80%;
        margin: auto;
    }
    .modal-dialog.custom_modal_wrap.new-address-modal.new-modal .modal-content {
        width: 498px !important;
        margin-bottom: 60px;
    }
    .chchbx [type=checkbox]:checked+label,
    .chchbx [type=checkbox]:not(:checked)+label {
        font-size: 16px;
    }
}

@media (max-width:1220px) {
    .entry-pop-wrap .entry-heading h3 {
        font-size: 24px;
    }
    .comming-page .commingpage-wrap.predictive-View-wrap {
        margin: 30px 20px;
    }
    .machine-detail-wrap .page-heading {
        font-size: 18px;
    }
    .apexcharts-title-text,
    .small-graph h3,
    .bearing-wrap h3 {
        font-size: 17px;
    }
    .small-chart-wrap {
        margin-top: 20px;
    }
    .hours-graph-wrap .small-chart-div span {
        font-size: 11px;
    }
    .small-chart-wrap .small-chart-div {
        width: calc(50% - 10px);
    }
    .small-graph {
        margin-bottom: 20px;
    }
    .predective-detail-box .machine-detail-wrap {
        padding: 20px 30px;
    }
    .predective-detail-box .machine-box {
        width: 310px;
        margin-right: 10px;
    }
    .predective-detail-box .machine-detail-wrap {
        padding: 20px 30px;
        width: calc(100% - 320px);
    }
    .machine-box .machine-image {
        margin: 30px 0px;
    }
    .modal-open .modal.modal-center .modal-dialog.custom_modal_wrap.new-address-modal {
        top: inherit;
        left: inherit;
        position: relative;
        transform: inherit;
        background-color: transparent;
        padding: 0 10px;
        padding-bottom: 20px;
    }
}

@media (max-width:1080px) {
    .machine-detail-wrap .page-heading {
        font-size: 17px;
    }
    .entry-pop-wrap .entry-heading h3 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 991px) {
    .chchbx [type=checkbox]:checked+label,
    .chchbx [type=checkbox]:not(:checked)+label {
        font-size: 15px;
    }
    .entry-pop-wrap .entry-heading h3 {
        font-size: 20px;
    }
    .modal-dialog.custom_modal_wrap.new-address-modal.new-modal .modal-content {
        width: 80% !important;
        margin: auto;
    }
    .assets-configurator-wrap .explorebg-image.blk {
        display: block;
    }
    .explorewrap {
        margin-top: 80px;
    }
    .predective-detail-box .machine-box,
    .predective-detail-box .machine-detail-wrap {
        width: 100%;
        margin: 0px;
    }
    .predective-machine-bx .machine-image img {
        /* width: 300px!important; */
        margin: auto;
        display: table;
    }
    .machine-box .machine-image {
        margin: 30px 0px 10px 0px;
    }
    .predective-detail-box .machine-detail-wrap {
        margin-top: 20px;
    }
    .upcoming-list ul li {
        width: 50%;
    }
    .apexcharts-title-text,
    .small-graph h3,
    .bearing-wrap h3 {
        font-size: 16px;
    }
    .machine-detail-wrap .page-heading {
        font-size: 16px;
    }
    .checkbox-group span {
        font-size: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .modal-open .modal {
        padding: 0px !important;
    }
    .entry-pop-wrap .entry-heading h3 {
        font-size: 18px;
    }
    .e-chip-pop-wrap .chip-pop-text h4 {
        font-size: 16px;
    }
    .e-chip-pop-wrap .pop-image img {
        width: 100px;
        height: 100px;
    }
    .e-chip-pop-wrap .chip-pop-text {
        max-width: 90%;
        margin: auto;
    }
    .assets-configurator-wrap .explorebg-image.blk {
        display: none;
    }
    .predictive-content .assets-configurator-wrap .explorebg-image.blk {
        padding: 0px 10px;
        display: block !important;
        margin-bottom: 20px;
    }
    .predictive-types-wrap {
        margin-top: 0px;
    }
    .predictive-types-wrap .row .p-0 {
        padding: 0px;
    }
    .calender-container .predictive-data {
        width: 100%;
    }
    .calender-container {
        text-align: center;
    }
    .comming-page.explorewrap .commingpage-wrap.predictive-View-wrap .container {
        padding: 0px 15px !important;
    }
    .upcoming-list ul li {
        width: 100%;
        margin-bottom: 10px;
    }
    .machine-information .machineName {
        font-size: 16px;
    }
    .small-chart-wrap .small-chart-div {
        width: 100%;
        margin: 0px;
    }
    .hours-graph-wrap .small-graph {
        margin-top: 20px;
    }
    .upcoming-maintenance .upcoming-heading h3,
    .upcoming-list .upcoming-list-box h4 {
        font-size: 14px;
    }
    .runing-hours .hourstext p {
        font-size: 14px;
    }
    .runing-hours .hourstext span {
        font-size: 11px;
    }
    .runing-hours .mark-btn button.bluebtn {
        padding: 6px 8px;
        font-size: 11px;
    }
    .predective-detail-box .machine-detail-wrap {
        padding: 10px 10px;
    }
    .modal-dialog.custom_modal_wrap.new-address-modal.new-modal .modal-content {
        width: 100% !important;
        margin: auto;
    }
    .chchbx [type=checkbox]:checked+label,
    .chchbx [type=checkbox]:not(:checked)+label {
        font-size: 14px;
    }
    .checkbox-group span {
        font-size: 14px;
    }
}

@media (max-width:460px) {
    .entry-pop-wrap .form-group input.form-control {
        font-size: 12px;
    }
}


/* .small-chart-wrap .small-chart-div svg{
    width: 200px;
} */