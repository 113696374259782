@media only screen and (max-width: 1600px) {
    .logincard span {
        font-size: 20px;
    }
    .common_input_warp .form-group .form-control,
    .common_btn button,
    .common_input_warp.coutrydropdwon .form-group select,
    .common_input_warp .form-group select.classic {
        height: 50px;
    }
}

@media only screen and (max-width: 1540px) {}

@media only screen and (max-width: 1440px) {
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 120px;
    }
}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1320px) {}

@media only screen and (max-width: 1220px) {}

@media only screen and (max-width: 1200px) {
    .container,
    .headerwap .navbar-expand-lg {
        padding: 0 80px;
    }
}

@media only screen and (max-width: 1170px) {}

@media only screen and (max-width: 1080px) {
    .logincard {
        max-width: 420px;
        padding: 30px 30px;
    }
    .headerwap .navbar-expand-lg,
    .container {
        padding: 10px 15px !important;
    }
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 1023px) {}

@media only screen and (max-width: 991px) {
    .logincard {
        max-width: 320px;
        padding: 30px 15px;
    }
    .conactus.followswrap ul li {
        margin-left: 0px;
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 840px) {}

@media only screen and (max-width: 767px) {
    .logincontent {
        display: none;
    }
    .logincardwrap {
        padding: 0 15px;
    }
    .logincard img {
        width: 110px;
    }
    .modal-dialog.custom_modal_wrap .modal-content {
        width: 100%!important;
        border-radius: 16px;
        padding: 20px 10px 20px 10px;
    }
    .logoutpopupwrap p {
        font-size: 16px;
        line-height: 22px;
    }
    .logoutpopupwrap h2 {
        font-size: 28px !important;
    }
    .logoutpopupwrap .like_img {
        width: 60px;
    }
    .modal-dialog.custom_modal_wrap {
        width: 100% !important;
    }
    .modal-dialog.custom_modal_wrap .modal-body {
        padding: 10px 0;
    }
    .logoutpopupwrap .common_btn button {
        width: 120px;
        height: 46px;
    }
    .digit-group input {
        width: 50px !important;
        height: 50px;
        margin-right: 14px;
    }
    .logincard.resetpassword.otpwrap {
        padding-bottom: 20px;
    }
    .closenav a .blink_me {
        width: 18px;
        height: 18px;
        position: relative;
        right: -7px;
        top: 0px!important;
    }
}

@media only screen and (max-width: 480px) {}