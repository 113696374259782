/* Cart Page Css */

.cart-wrap .container {
    padding: 0px 80px !important;
}

.cart-wrap .noficationwrap ul li {
    border: 0px;
    background: #fff;
    border-radius: 10px;
}

.cartPage-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: #fff;
    padding: 30px 40px;
    margin-top: 5px;
}

.cartPage-heading h3 {
    font-size: 28px;
    font-weight: bold;
    color: #193870;
    margin-bottom: 0px;
}

.cart-wrap .cartPage-heading .common_btn {
    text-align: right;
    display: block;
    margin: 0px;
}

.cartPage-heading .common_btn button {
    margin-bottom: 0px;
    margin-top: 0px;
    height: 45px;
    padding: 12px 20px;
    width: auto;
}

.cart-wrap .cart-product-wrap .product-img {
    text-align: center;
}

.cart-wrap .cart-product-wrap .product-img img {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    margin-top: 45px;
}

.productInfo-table table tr th,
.productInfo-table table tr td {
    background-color: #E1F0FF;
    padding: 14px;
    color: #193870;
    font-size: 12px;
    text-align: center;
    border-right: 2px solid #d2dae7;
}

.productInfo-table table tr td .partNumber-width {
    min-width: 250px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.productInfo-table table tr td {
    background-color: #f8fcff !important;
}

.productInfo-table table tr td {
    font-weight: 400;
}

.cart-wrap .setting_wrap ul {
    margin-top: 0px !important;
}

.productInfo-table .quantity-input {
    position: relative;
    display: block;
    float: none;
}

.productInfo-table .quantity-input button {
    background: #e1f0ff !important;
    border: 1px solid #e1f0ff !important;
    color: #1e3e7b;
    padding: 6px;
    font-weight: 500;
    width: 30px;
    height: 30px;
    line-height: 15px;
}

.productInfo-table .quantity-input input {
    width: 40px;
    text-align: center;
    height: 30px;
}

.cart-form-wrap .form-group {
    margin-bottom: 25px;
}

.cart-form-wrap .form-group input.form-control {
    height: 60px!important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 20px;
    color: #808080;
    font-size: 14px;
    font-weight: 500;
}

.cart-form-wrap .form-group textarea.form-control {
    min-height: 235px;
    resize: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 20px;
    color: #808080;
    font-size: 14px;
    font-weight: 500;
}

.formwrap {
    padding: 20px 15px;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: #fff;
}

.productInfo-table .quantity-input input {
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid #f8fcff;
    outline: 0;
    background-color: #f8fcff;
}

.cart-wrap .setting_wrap ul li {
    margin: 15px 0px;
}

.productInfo-table table tr th:last-child {
    border: 0px;
}

.productInfo-table table tr td:last-child {
    border: 0px;
}

.productInfo-table p {
    font-size: 22px;
    color: #193870 !important;
    line-height: 36px;
    font-weight: bold !important;
    padding-bottom: 0 !important;
    margin-bottom: 10px !important;
}

.cart-form-wrap .address-div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    padding: 20px;
    margin-bottom: 25px;
    min-height: 170px;
}

.cart-form-wrap .address-div .add-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-form-wrap .address-div .add-heading p {
    padding: 0px !important;
    color: #1E3E7B;
    font-weight: 600;
    font-size: 17px;
}

.cart-form-wrap .address-div .add-heading a {
    font-weight: 500;
    font-size: 17px;
    color: #e2e6ee;
}

.aboutsimg-div img {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    width: 500px !important;
    height: auto !important;
    text-align: center;
}

.cart-form-wrap .address-div .addressinfo {
    margin-top: 20px;
}

.cart-form-wrap .address-div .addressinfo p {
    font-size: 15px;
    color: #06152B;
    font-weight: 500;
}

.quantity-input.loadershow .quantity-input__screen {
    color: #f8fcff;
}

.quantity-input.loadershow .loader {
    display: block;
}

.loadershow {
    pointer-events: none;
}

.quantity-input .loader {
    display: none;
    position: absolute;
    left: 43%;
    top: 20%;
    transform: translate(-50%, -50%);
    border: 2px solid #1c3e7d;
    border-radius: 50%;
    border-top: 2px solid #e1f0ff;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.emptycart-wrap {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    opacity: 1;
    margin-top: 15px;
    padding: 50px 0px;
}

.react-tel-input .selected-flag {
    width: 35px!important;
}

.onphone {
    display: none;
}

.cart-form-wrap .form-group input.form-control.intl-tel-input-custom {
    padding: 0px 40px;
}

.cart-form-wrap .react-tel-input .flag-dropdown {
    background-color: #f5f5f5!important;
    border: 1px solid #e1e7ec!important;
    border-right: 0px!important;
}

.react-tel-input .selected-flag {
    background-color: #fff!important;
}

.cart-form-wrap small {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #1c3e7d;
    opacity: 1;
}


/* Cart Page Css */

@media (max-width:1600px) {
    .cart-form-wrap .form-group input.form-control {
        height: 50px!important;
    }
    .cartPage-heading h3 {
        font-size: 25px;
    }
}

@media (max-width:1460px) {
    .cart-wrap .container {
        padding: 0px 60px !important;
    }
    .cartPage-heading h3 {
        font-size: 24px;
    }
}

@media (max-width:1380px) {
    .cart-wrap .cart-product-wrap .product-img img {
        width: 80px;
        height: 80px
    }
    .cartPage-heading h3 {
        font-size: 22px;
    }
    .cart-wrap .container {
        padding: 0px 50px !important;
    }
    .cart-form-wrap .address-div .addressinfo p {
        font-size: 14px;
        line-height: 25px;
    }
    .cart-form-wrap .address-div .addressinfo {
        margin-top: 15px;
    }
    .cart-form-wrap .form-group textarea.form-control,
    .cart-form-wrap .address-div {
        min-height: 160px;
    }
    .cartPage-heading {
        padding: 20px 30px;
    }
}

@media (max-width:1220px) {}

@media (max-width:1080px) {
    .cart-wrap .container {
        padding: 0px 30px !important;
    }
}

@media (max-width:991px) {
    .cartPage-heading h3 {
        font-size: 20px;
    }
    .cartPage-heading {
        padding: 15px 25px;
    }
    .cart-wrap .cart-product-wrap .product-img img {
        width: 60px;
        height: 60px;
    }
    .common_btn.quotebbtn button {
        height: auto;
        min-height: 40px;
        max-width: 70%;
        margin: auto;
        font-size: 14px;
    }
}

@media (max-width:767px) {
    .cartPage-heading {
        padding: 12px 20px;
    }
    .emptycart-wrap {
        padding: 30px 0px;
    }
    .cart-form-wrap .form-group textarea.form-control,
    .cart-form-wrap .address-div {
        min-height: 120px;
    }
    .common_input_warp .form-group select.classic {
        padding: 0px 40px 0px 15px;
    }
    .cart-wrap .cart-product-wrap .product-img img {
        width: 80px;
        height: 60px;
        margin-top: 20px;
    }
    .cart-wrap .cartPage-heading {
        padding: 15px 20px;
        margin-top: 0px;
    }
    .cartPage-heading .common_btn button {
        font-size: 14px;
        padding: 8px 12px;
        height: 40px;
        min-height: 40px;
    }
    .cart-wrap .cartPage-heading h3 {
        font-size: 18px;
    }
    .cartPage-heading h3 {
        font-size: 18px;
    }
    .cart-wrap .common_btn {
        width: auto;
        font-size: 14px!important;
        height: 40px!important;
    }
    .cart-wrap .container {
        padding: 0px 10px !important;
    }
    .ondesktop {
        display: none;
    }
    .onphone {
        display: block;
    }
    .cart-wrap .deleticon .delete {
        top: 0px;
        right: 25px;
        position: absolute;
        margin-top: 0px;
    }
    .downloadbtn {
        font-size: 18px;
        margin-left: 10px;
    }
}

@media (max-width:460px) {
    .cart-wrap .cartPage-heading h3 {
        font-size: 16px;
    }
    .cartPage-heading h3 {
        font-size: 16px;
    }
    .cartPage-heading {
        padding: 12px 15px;
    }
    .cart-wrap .common_btn {
        width: auto;
    }
}

@media (max-width:360px) {}