.maintenanceAlert-page .container {
    padding: 0px 80px!important;
}

.faqwrap.maintancealerts .accordion .item .item-header h2 button.btn.btn-link {
    background-color: #fff;
    margin-bottom: 0;
    padding-left: 40px;
}

.faqwrap.maintancealerts .accordion .item .item-header h2 button {
    padding-right: 100px;
    padding-bottom: 0 !important;
}

.maintancealerts .maintenance-month-list .maintenancediv-wrap {
    margin-bottom: 10px;
}

.black-text {
    color: #000!important;
}

.collapse.show {
    display: block;
}

.faqwrap.maintancealerts .accordion .item .item-header h2 button .faqcloseicons {
    top: 40px;
    right: 30px;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.mysubmittedrequested .maintenancediv-wrap:before {
    content: "";
    position: absolute;
    left: -50px;
    border: 1px dashed #fddb8c;
    height: calc(100% - 5px);
    top: 5px;
}

.mysubmittedrequested .statusline.greenline .iconimg {
    position: absolute;
    left: 10px;
    top: 30px;
}

.maintenance-content-box .statusline.greenline ul li:after {
    border: 0px;
}

.maintenance-content-box .statusline ul li:after {
    border: 0px;
}

.faqwrap.maintancealerts .btn.btn-link.collapsed .faqcloseicons {
    transform: rotate(-180deg);
}

.statusline.greenline .iconimg {
    position: absolute;
    left: -13px;
    top: 30px;
}

.statusline.greenline .iconimg img {
    position: relative!important;
    left: auto!important;
    top: auto!important;
    right: auto!important;
    width: auto!important;
    height: auto!important;
}

.maintenancediv-wrap .maintenance-info .info-content p {
    top: auto!important;
    margin-bottom: 20px;
    padding: 0 !important;
    color: #193870;
}

.maintaincardimg .productsimg {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    padding: 10px;
    /* margin: 0 auto; */
    display: table;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.maintaincardimg .productsimg img {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80px;
    height: 80px;
    /* border-radius: 100%; */
    object-fit: contain;
}

.maintaincardimg .productsimg svg {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 50px;
    height: 50px;
    /* border-radius: 100%; */
    object-fit: contain;
}

.maintaincardimg span {
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif!important;
    display: block;
    /* text-align: center; */
}

.maintaincardimg p {
    color: #193870;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif!important;
    text-align: center;
    line-height: 20px;
}

.faqwrap.maintancealerts .tabledatawrap table {
    margin-bottom: 0;
}

.faqwrap.maintancealerts .tabledatawrap table tbody tr {
    box-shadow: none !important;
    border-top: 0px solid #fff;
}

.faqwrap.maintancealerts .tabledatawrap table tbody tr td {
    padding: 20px 10px;
    vertical-align: inherit;
    text-align: center;
}

.faqwrap.maintancealerts .tabledatawrap table tbody tr:nth-child(even) {
    background: #f4f7fa;
}

.faqwrap.maintancealerts .tabledatawrap table tbody tr:nth-child(odd) {
    background: #fff;
}

.tabledatawrap table thead th {
    padding: 12px !important;
}

.faqwrap.maintancealerts .mysubmittedrequested {
    height: 500px;
    overflow-y: auto;
    padding: 10px 40px;
}

.maintenance-content-box .statusline .iconimg img {
    width: auto!important;
    height: auto!important;
    right: -15px;
}

.maintaincardimg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.maintaince-dtls {
    margin-left: 20px;
}

.maintancealerts .monthwise-list .month-date {
    color: #193870;
    font-size: 18px;
    font-weight: 700;
    line-height: 35px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
    .faqwrap.maintancealerts .accordion .item .item-header h2 button {
        padding-right: 15px;
    }
    .faqwrap.maintancealerts .accordion .item .item-header h2 button.btn.btn-link {
        padding-left: 15px;
    }
    .maintaincardimg .productsimg {
        width: 80px;
        height: 80px;
    }
    .maintaince-dtls {
        margin-left: 10px;
    }
    .faqwrap.maintancealerts .accordion .item .item-header h2 button .faqcloseicons {
        top: 5px;
        right: 10px;
    }
    .faqwrap.maintancealerts .accordion .item .item-header h2 button .faqcloseicons svg {
        width: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .faqwrap.maintancealerts .mysubmittedrequested {
        height: auto;
    }
    .maintenance-content-box .statusline .iconimg img {
        display: none;
        width: auto!important;
        height: auto!important;
    }
    .partnumberdiv .partdiv,
    .partnumberdiv .markdiv,
    .info-content .runingdiv {
        padding: 5px 0px;
        font-size: 13px;
    }
    .circlediv {
        width: 15px;
        height: 15px;
        margin-top: 12px;
        margin-right: 20px;
    }
    .monthwise-list .month-date {
        font-size: 14px;
    }
    .mysubmittedrequested .maintenancediv-wrap:before {
        display: none;
    }
    .statusline.greenline .iconimg img {
        display: none;
    }
    .mysubmittedrequested .statusline {
        margin-right: 0px;
    }
    .maintenanceAlert-page .cartPage-heading {
        margin-top: 5px;
    }
    .maintenanceAlert-page .container {
        padding: 0px 15px!important;
    }
    .faqwrap.maintancealerts .accordion .item .item-header h2 button .faqcloseicons {
        top: 45px;
    }
    .faqwrap.maintancealerts .mysubmittedrequested {
        padding: 30px 20px;
    }
    .maintenance-month-list .maintenancediv-wrap {
        padding: 10px;
    }
    .maintenancepoint-list {
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width:460px) {
    .maintaincardimg p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 150px;
    }
}