/* Add New Address Screen CSS Start here */

.common_input_warp.coutrydropdwon .form-group div {
    width: 100%;
}

.radio_btn_wrap {
    display: flex!important;
}

.radio_btn_wrap p {
    display: inline !important;
    margin-right: 25px !important;
}

.radio_btn_wrap [type="radio"]:checked,
.radio_btn_wrap [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.radio_btn_wrap [type="radio"]:checked+label,
.radio_btn_wrap [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    line-height: 40px;
    display: flex;
    color: #808080;
    font-weight: normal;
    align-items: center;
    margin-bottom: 0px;
}

.radio_btn_wrap [type="radio"]:checked+label:before,
.radio_btn_wrap [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 24px;
    height: 24px;
    border: 1px solid #1c3e7d;
    border-radius: 100%;
    background: #fff;
}

.radio_btn_wrap [type="radio"]:not(:checked)+label:before {
    border: 1px solid #1c3e7d;
}

.radio_btn_wrap [type="radio"]:checked+label:after,
.radio_btn_wrap [type="radio"]:not(:checked)+label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: #1c3e7d;
    position: absolute;
    top: 15px;
    left: 5px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.radio_btn_wrap [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.radio_btn_wrap [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.saveaddress label {
    font-size: 18px;
    margin-bottom: 16px;
}

.saveaddress .radio_btn_wrap {
    display: block;
}

.saveaddress .error {
    margin-top: -20px;
    margin-bottom: 20px;
}

.addaddresswrap .common_input_warp .form-group {
    margin-bottom: 35px;
}

.addaddresswrap .common_btn {
    width: 407px;
    font-size: 16px;
    font-family: "FiraSans-Bold";
    margin: 0 auto;
    display: table;
}

.addaddresswrap {
    margin-top: 20px;
}

.common_input_warp.coutrydropdwon .form-group {
    display: flex;
}

.common_input_warp.coutrydropdwon .form-group select {
    width: 75px;
    border-right: 0;
    padding-right: 20px;
    border-radius: 5px 0px 0px 5px;
    background-position: calc(100% - 6px) calc(1em + 2px), calc(100% - 21px) calc(1em + 2px), 100% 0;
    background-size: 16px 18px, 16px 5px, 2.5em 2.5em;
}

.common_input_warp.coutrydropdwon .form-group .form-control {
    border-radius: 0px 5px 5px 0px;
}


/* Add New Address Screen CSS End here */

@media only screen and (max-width: 991px) {
    .addaddresswrap .common_btn {
        width: 100%;
    }
    .radio_btn_wrap p {
        display: inline !important;
        margin-right: 15px !important;
    }
    .radio_btn_wrap [type="radio"]:checked+label,
    .radio_btn_wrap [type="radio"]:not(:checked)+label {
        padding-left: 30px;
        line-height: 46px;
    }
    .saveaddress label {
        font-size: 16px;
    }
    .explorecont h2 {
        margin-bottom: 30px;
    }
}