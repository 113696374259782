/* ============= */

.explorepage-wrap {
    background-color: #fff!important;
}

.explorepage-wrap .container {
    max-width: 100% !important;
    width: 100%;
    padding: 0 80px !important;
}

.aboutsimg-div.explorebg-image img {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    width: 80% !important;
    height: auto !important;
    text-align: center;
}

.explorepage-wrap .explorecont p {
    font-size: 17px!important;
    color: #6C6C6C!important;
    line-height: 25px!important;
    margin-bottom: 10px!important;
}

.exploretype-wrap .container {
    padding: 0px 60px!important;
}

.exploretype-wrap .mar100 {
    margin-bottom: 100px;
}

.explore-footer-content {
    max-width: 50%;
    margin: 0px auto 50px auto;
    display: flex;
    flex-wrap: wrap;
}

.explore-footer-content .explore-logo {
    width: 300px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 0px 0px 12px;
}

.explore-footer-content .explore-logo img {
    width: 200px;
}

.exploretype-wrap {
    margin-top: 15px;
}

.explore-footer-content .explore-descri {
    position: relative;
    width: calc(100% - 300px);
    background-color: #193870;
    color: #fff;
    padding: 50px 50px 25px 50px;
    border-radius: 0px 12px 12px 0px;
}

.explore-descri .explore-btn.explorefootr-btn a {
    position: absolute;
    bottom: -20px;
    background-color: #E1F0FF;
    font-size: 13px;
    color: #193870!important;
    cursor: pointer;
}


/* ============= */

.explorecont.exploredv {
    padding-top: 10px;
    padding-bottom: 40px !important;
}

.explorecont.exploredv p {
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 30px;
}

.explorecont-heading h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    color: #1E3E7B;
    margin: 30px 0px 10px 0px;
}

.eplorericoncard .eplorericon p {
    font-size: 26px;
    color: #193870;
    margin: 10px 0px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif!important;
}

.eplorericoncard {
    margin-bottom: 30px;
    height: 100%;
    text-align: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9E1E7CC;
    border-radius: 9px;
    position: relative;
    padding: 25px 20px;
}

.explore-btn {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    /* justify-content: center; */
}

.explore-btn a {
    font-size: 18px;
    font-weight: 500;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    color: #FFFCFC;
    background-color: #1E3E7B;
    border-radius: 4px;
    padding: 12px 20px;
}

.eplorericon {
    text-align: center;
}

.svgwrap {
    position: absolute;
    right: 10px;
    bottom: -70px;
}

.svgwrap img {
    width: 140px;
    height: 140px;
    border-radius: 12px;
}

.explore-list-box {
    width: 100%;
    margin: auto;
}

.explore-elementor .eplorericoncard p {
    font-size: 24px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.3px;
    color: #1e3e7b;
    text-align: left;
}

.explore-list-box ul {
    margin: 30px 0px 0px 0px;
    padding: 0px;
}

.explore-elementor {
    /* padding-bottom: 15px; */
    /* border-bottom: 1px solid #000; */
    margin-bottom: 50px;
}

.explore-list-box ul li {
    list-style-type: none;
    text-align: left;
    margin-left: 0px;
}

.explore-list-box ul li a {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif!important;
    color: #6C6C6C;
    display: inline-block;
}

.eplorericon .svgwrap {
    margin: 0 auto;
    /* background: #f4f7fa;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  width: 188px;
  height: 177px; */
}

.explorewrap .svgwrap img {
    position: relative;
    right: auto;
    top: auto;
    width: 80%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: table;
    margin: auto;
}


/* .eplorericon .svgwrap img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    height: 100px;
    width: 100px;
    bottom: 0;
} */

.eplorericon p {
    letter-spacing: 1.89px;
    color: #193870;
    margin-top: 15px;
    font-family: "FiraSans-Bold";
}

.cardoption a {
    font-size: 16px;
    line-height: 34px;
}

.explore-footer-content p {
    font-size: 23px;
    font-weight: bold;
    line-height: 35px;
    text-align: left;
    color: #fff;
    max-width: 100%;
}

.explorepage-wrap .imageswrap {
    text-align: right;
}

@media only screen and (max-width:1600px) {
    .explore-elementor .eplorericoncard p {
        font-size: 20px;
    }
    .explore-list-box ul li a {
        font-size: 14px;
    }
    .explore-elementor .container {
        padding: 0px 50px!important;
    }
    .explorepage-wrap .container {
        padding: 0 40px !important;
    }
}

@media (max-width:1440px) {
    .explorepage-wrap .explorecont p {
        font-size: 16px!important;
    }
}

@media only screen and (max-width:1380px) {
    .explorepage-wrap .explorecont p {
        font-size: 15px!important;
    }
    .explore-elementor .eplorericoncard p {
        font-size: 18px;
    }
    .explore-footer-content p {
        font-size: 21px;
    }
    .explorecont-heading h3 {
        font-size: 28px;
    }
    .svgwrap img {
        width: 120px;
        height: 120px;
    }
}

@media only screen and (max-width:1199px) {
    .explore-footer-content p {
        font-size: 18px;
    }
    .explore-footer-content .explore-descri {}
    .explore-list-box ul {
        margin: 10px 0px 0px 0px;
        padding: 0px;
    }
    .explore-elementor .eplorericoncard p {
        font-size: 16px;
    }
    .imageswrap {
        text-align: center;
        margin-bottom: 50px;
    }
    .aboutsimg-div.explorebg-image img {
        width: 70% !important;
    }
    .svgwrap {
        bottom: -60px;
    }
    .svgwrap img {
        width: 90px;
        height: 90px;
    }
    .explore-footer-content {
        max-width: 100%;
        padding: 0px 50px;
    }
    .explore-footer-content {
        max-width: 80%;
    }
    .explorewrap.termscondition .explorecont {
        padding: 15px 0 20px 0!important;
    }
    .explore-footer-content .explore-descri {
        padding: 40px 40px 20px 40px;
        width: calc(100% - 250px);
    }
    .explore-footer-content .explore-logo {
        width: 250px;
    }
    .explore-footer-content .explore-descri span {
        font-size: 15px;
    }
    .explore-footer-content .explore-logo img {
        width: 170px;
    }
}

@media (max-width:1080px) {
    .explore-descri .explore-btn.explorefootr-btn a {
        font-size: 12px;
    }
    .explorecont-heading h3 {
        font-size: 24px;
    }
    .explorepage-wrap .explorecont p {
        font-size: 15px!important;
    }
}

@media (max-width:991px) {
    .explore-footer-content .explore-logo img {
        width: 150px;
    }
    .explore-footer-content {
        max-width: 100%;
        justify-content: center;
        padding: 0px 30px;
    }
    .svgwrap {
        bottom: -40px;
    }
    .explorecont-heading h3 {
        font-size: 20px;
    }
    .explore-elementor .container {
        padding: 0px 30px!important;
    }
    .explore-elementor .eplorericoncard p {
        font-size: 16px;
    }
    .explore-list-box {
        width: 100%;
    }
    .explore-btn a {
        font-size: 16px;
    }
    .explore-footer-content p {
        font-size: 17px;
        line-height: 25px;
        max-width: 100%;
    }
    .explorepage-wrap .container {
        padding: 0 20px !important;
    }
    .exploretype-wrap .mar100 {
        margin-bottom: 80px;
    }
    .explore-footer-content .explore-descri {
        padding: 30px 30px 15px 30px;
        width: calc(100% - 220px);
    }
    .explore-footer-content .explore-logo {
        width: 220px;
    }
    .explore-elementor {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .exploretype-wrap .mar100 {
        margin-bottom: 70px;
    }
    .explorepage-wrap .explorecont p {
        font-size: 14px!important;
    }
    .explorewrap.termscondition.explorepage-wrap .explorecont {
        padding: 0px 0 0px 0!important;
    }
    .explorecont-heading h3 {
        font-size: 18px;
        margin: 15px 0px 10px 0px;
    }
    .explorewrap.services-pg-wrap.termscondition {
        margin: 90px 15px 0px 15px;
    }
    .explorepage-wrap .explore-btn {
        margin-bottom: 30px!important;
    }
    .explorepage-wrap .explore-btn a {
        font-size: 14px;
    }
    .explore-elementor .container {
        padding: 0px 15px !important;
    }
    .explore-footer-content {
        padding: 0px 15px;
        display: block;
    }
    .explore-footer-content .explore-logo {
        display: none;
    }
    .eplorericoncard {
        padding: 30px 10px;
    }
    .cardoption a {
        text-align: center;
        display: block
    }
    .eplorericon p {
        text-align: center;
        font-size: 20px;
    }
    .explore-footer-content .explore-logo {
        width: 300px;
        border-radius: 12px;
        min-height: 200px;
        margin: 0 auto;
    }
    .explore-footer-content .explore-descri {
        width: 100%;
        padding: 25px 25px 25px 25px;
        border-radius: 12px;
        text-align: center;
    }
    .explore-btn {
        margin-bottom: 30px;
        align-items: center;
        justify-content: center;
    }
    .explore-footer-content p {
        font-size: 16px;
        text-align: center;
    }
    .explore-footer-content .explore-descri span {
        font-size: 14px;
    }
}

@media (max-width:460px) {
    .exploretype-wrap .mar100 {
        margin-bottom: 50px;
    }
    .explore-footer-content .explore-descri {
        padding: 20px 15px 15px 15px;
    }
}